import { ICount, IDataSourcePricing } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import { Box, FormGroup, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import CountsFiltersList from './counts-filters-list.component';
import { CountsFiltersPricingPlan } from './counts-filters-pricing-plan/counts-filters-pricing-plan.component';

const CountsFilters = () => {
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state.count);
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const [name, setName] = useState(count?.name);
  const [isNameValid, setIsNameValid] = useState(true);

  const updateCountsFieldHandler = (field: string, value: any) => {
    const newCounts = { ...count, [field]: value } as ICount;
    dispatch(setCount(newCounts));
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => updateCountsFieldHandler('name', name), 500);
    return () => clearTimeout(timeOutId);
  }, [name]);

  const updateCountsPricingPlanHandler = (planId: string) => {
    const plan = currentDataSource?.pricing?.find((p: IDataSourcePricing) => p._id === planId);
    if (!plan) return;

    const newCounts = {
      ...count,
      pricingPlanFields: plan.includedFields,
      pricingPlanId: plan._id,
      pricingPlanName: plan.name,
      pricingPlanPrice: plan.price,
    } as ICount;
    dispatch(setCount(newCounts));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <InputLabel
          htmlFor="list-name"
          sx={{
            color: 'gray.10',
            fontSize: 18,
            fontWeight: 500,
            lineHeight: '28px',
          }}
        >
          List Name
        </InputLabel>
        <TextField
          error={!isNameValid}
          helperText={
            !isNameValid
              ? 'Name is required and can contain only letters, numbers, spaces, dashes, and underscores "_".'
              : ''
          }
          id="list-name"
          onChange={(e) => {
            setName(e.target.value);
            setIsNameValid(/^[a-zA-Z0-9_-\s]*$/.test(e.target.value));
          }}
          sx={{
            '& .MuiInputBase-root': {
              '.MuiInputBase-input': {
                color: 'gray.10',
                fontSize: 16,
                fontWeight: 400,
                px: 0,
                py: 0.5,
              },

              backgroundColor: 'white',
              borderRadius: '20px',
              p: '20px',
            },
          }}
          value={name}
          variant="outlined"
        />
      </FormGroup>

      <CountsFiltersPricingPlan
        fields={currentDataSource?.fields ?? []}
        onChange={updateCountsPricingPlanHandler}
        planId={count?.pricingPlanId}
        plans={currentDataSource?.pricing ?? []}
      />

      <CountsFiltersList />
    </Box>
  );
};

export default CountsFilters;

import { useQuery } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { useGetUserDataSourcesQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UploadListDialogComponent } from './upload-list-dialog.component';

export const ListsHeaderComponent = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { data: dataSources } = useGetUserDataSourcesQuery();
  const [uploadListDialogOpen, setUploadListDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(query.get('tab') ?? 'purchased');

  useEffect(() => {
    if (query.get('tab'))
      switch (query.get('tab')) {
        case 'purchased':
          setActiveTab('purchased');
          break;
        case 'uploaded':
          setActiveTab('uploaded');
          break;
        default:
          setActiveTab('purchased');
          break;
      }
  }, [query]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Lists</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Button
            onClick={() => {
              setUploadListDialogOpen(true);
            }}
            variant="contained"
          >
            Upload List
          </Button>
          {dataSources && dataSources.length > 0 && (
            <Button
              onClick={() => {
                navigate(`${AppRoutes.DataSourcesPage}`);
              }}
              variant="contained"
            >
              Build new list
            </Button>
          )}
        </Box>

        <UploadListDialogComponent
          onClose={() => setUploadListDialogOpen(false)}
          open={uploadListDialogOpen}
        />
      </Box>

      <Tabs
        onChange={(event, v) => {
          setActiveTab(v);
          navigate(`${AppRoutes.Lists}?tab=${v}`);
        }}
        value={activeTab}
      >
        <Tab
          label="Purchased"
          sx={{ color: globalStyles.navigation.textColor }}
          value="purchased"
        />
        <Tab label="Uploaded" sx={{ color: globalStyles.navigation.textColor }} value="uploaded" />
      </Tabs>
    </Box>
  );
};

import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface DataSourceDetailsDeleteDialogProps {
  deleteDialog: boolean;
  setDeleteDialog: (value: boolean) => void;
}

const DataSourceDetailsDeleteDialog = (props: DataSourceDetailsDeleteDialogProps) => {
  const { deleteDialog, setDeleteDialog } = props;
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteDataSource = async () => {
    if (currentDataSource && currentDataSource._id) {
      dispatch(pageContentLoad(false));

      const response = await dataSourcesService.delete(currentDataSource._id);
      if (response?.status === 200) {
        setDeleteDialog(false);
        dispatch(pageContentLoad(true));
        dispatch(snackbarSuccessMessage('Data Source Deleted Successfully'));
        navigate(AppRoutes.AdminDataSourcesPage);
      }

      dispatch(pageContentLoad(true));
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Yes, Delete"
      onCancel={() => setDeleteDialog(false)}
      onClose={() => setDeleteDialog(false)}
      onOk={deleteDataSource}
      open={deleteDialog}
      title="Delete Data Source"
    >
      <Typography sx={{ fontSize: 20 }}>
        Do You Really want to delete this Data Source and all items?
      </Typography>
    </Dialog>
  );
};

export default DataSourceDetailsDeleteDialog;

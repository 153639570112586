import { IStripeSubscription } from '@a-type/dtos';
import { SubscriptionCard } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetActiveSubscriptionQuery, useGetSuscriptionsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SubscribeDialog } from './subscribe-dialog.component';

export const SubscribePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading } = useGetSuscriptionsQuery();
  const { data: activeSubscription } = useGetActiveSubscriptionQuery();
  const [subscriptions, setSubscriptions] = useState<IStripeSubscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<IStripeSubscription | null>(
    null,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (activeSubscription?.active === true && activeSubscription?.cancelAtPeriodEnd === false) {
      navigate(AppRoutes.HomePage);
    }
  }, [activeSubscription]);

  useEffect(() => {
    if (data) {
      setSubscriptions([...data]);
    }
  }, [data]);

  return (
    <PageLayout>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          px: 8,
          py: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
          }}
        >
          {!isLoading && subscriptions.length > 0 && (
            <Box
              sx={{
                background: globalStyles.mainColors.whiteColor,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                height: 'auto',
                p: 4,
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 32,
                  fontWeight: 700,
                  textAlign: 'center',
                }}
              >
                Welcome to A Type Data!
              </Typography>

              <Divider
                sx={{
                  backgroundColor: globalStyles.mainColors.whiteGrayColor,
                  mt: 1.5,
                }}
              />

              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                Select Your Subscription Plan
              </Typography>

              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 5,
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {subscriptions
                  ?.toSorted((a, b) => a.sortOrder - b.sortOrder)
                  .map((subscription) => (
                    <SubscriptionCard
                      key={subscription.id}
                      notSelectedText="Subscribe"
                      onSelect={() => {
                        setSelectedSubscription(subscription);
                        setOpen(true);
                      }}
                      subscription={subscription}
                    />
                  ))}
              </Box>
            </Box>
          )}

          {!isLoading && subscriptions.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 32,
                  fontWeight: 700,
                  textAlign: 'center',
                }}
              >
                Welcome to A Type Data!
              </Typography>

              <Divider
                sx={{
                  backgroundColor: globalStyles.mainColors.whiteGrayColor,
                  mt: 1.5,
                }}
              />

              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                No subscription plans available
              </Typography>
            </Box>
          )}

          {selectedSubscription && (
            <SubscribeDialog
              onClose={() => {
                setOpen(false);
                setSelectedSubscription(null);
              }}
              open={open}
              subscription={selectedSubscription}
            />
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};

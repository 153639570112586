import { IVersiumAppendSubType, IVersiumAppendType } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { AdminAppendDataSubTypeCard } from './admin-append-data-sub-type-card.component';

interface IAppendDataTypeCardProps {
  activeType: null | string;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;

  // drag and drop
  dragRef: (element: HTMLElement | null) => void;

  // update type
  onUpdateType: (type: IVersiumAppendType) => void;
  setActiveType: (type: null | string) => void;
  type: IVersiumAppendType;
}

export const AdminAppendDataTypeCard: React.FC<IAppendDataTypeCardProps> = ({
  activeType,
  draggableProps,
  dragHandleProps,
  dragRef,
  onUpdateType,
  setActiveType,
  type,
}: IAppendDataTypeCardProps) => {
  const handleUpdateType = (key: string, value: IVersiumAppendSubType[] | number | string) => {
    onUpdateType({ ...type, [key]: value });
  };

  const [subTypes, setSubTypes] = useState<IVersiumAppendType[]>([]);
  const [activeSubType, setActiveSubType] = useState<null | string>(null);
  const [name, setName] = useState(type.name);
  const [price, setPrice] = useState(type.price);
  const [individualPrice, setIndividualPrice] = useState(type.individualPrice ?? 0);
  const [description, setDescription] = useState(type.description);

  useEffect(() => {
    if (type.subTypes) {
      setSubTypes([...type.subTypes].sort((a, b) => a.sortOrder - b.sortOrder));
    }
  }, [type]);

  useEffect(() => {
    handleUpdateType('name', name);
  }, [name]);

  useEffect(() => {
    handleUpdateType('price', price);
  }, [price]);

  useEffect(() => {
    handleUpdateType('individualPrice', individualPrice);
  }, [individualPrice]);

  useEffect(() => {
    handleUpdateType('description', description);
  }, [description]);

  return (
    <Accordion
      ref={dragRef}
      {...draggableProps}
      expanded={activeType === type._id}
      onChange={() => setActiveType(activeType === type._id ? null : type._id)}
      sx={{
        my: 1,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <Button
            {...dragHandleProps}
            startIcon={
              <DragIndicatorIcon
                sx={{
                  color: globalStyles.mainColors.grayColor,
                  fontSize: 20,
                }}
              />
            }
            sx={{
              '.MuiButton-startIcon': {
                marginRight: 0,
              },
              borderRadius: 2,
              cursor: 'grab',
              height: 30,
              minWidth: 30,
              px: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: 15,
                mt: 0.25,
              }}
            >
              {type.sortOrder}
            </Typography>
          </Button>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {name}
          </Typography>
          {(!subTypes || subTypes.length === 0) && (
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: '400',
              }}
            >
              ({price} Credits/Record - {individualPrice} Credits/Individual)
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            pb: 2,
            pt: 3,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                error={!name}
                fullWidth
                label="Name"
                onChange={(e) => setName(e.target.value)}
                size="small"
                value={name}
                variant="outlined"
              />

              {(!subTypes || subTypes.length === 0) && (
                <>
                  <TextField
                    error={!price || price < 1}
                    fullWidth
                    label="Price"
                    onChange={(e) => setPrice(Number(e.target.value))}
                    size="small"
                    type="number"
                    value={price}
                    variant="outlined"
                  />

                  <TextField
                    error={!individualPrice || individualPrice < 1}
                    fullWidth
                    label="Individual Price"
                    onChange={(e) => setIndividualPrice(Number(e.target.value))}
                    size="small"
                    value={individualPrice}
                    variant="outlined"
                  />
                </>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                fullWidth
                label="Description"
                multiline
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                size="small"
                sx={{
                  flex: 1,
                }}
                value={description}
                variant="outlined"
              />
            </Box>
          </Box>

          {subTypes && subTypes.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Sub Types
              </Typography>

              <DragAndDrop
                items={subTypes}
                renderItem={(subType, index, p) => (
                  <AdminAppendDataSubTypeCard
                    activeSubType={activeSubType}
                    draggableProps={p.draggableProps}
                    dragHandleProps={p.dragHandleProps}
                    dragRef={p.innerRef}
                    key={subType.code}
                    onUpdateSubType={(updatedSubType) => {
                      const updatedSubTypes = subTypes.map((st) =>
                        st.code === updatedSubType.code ? updatedSubType : st,
                      );

                      handleUpdateType('subTypes', updatedSubTypes);
                    }}
                    setActiveSubType={setActiveSubType}
                    subType={subType}
                  />
                )}
                setItems={(updated) => handleUpdateType('subTypes', updated)}
              />
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

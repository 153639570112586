import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import LoadingPageComponent from './pages/loading.component';
import reportWebVitals from './report-web-vitals';
import { store } from './stores/store';
import './styles/styles.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_UI_DSN,
  enabled:
    process.env.REACT_APP_SENTRY_ENVIRONMENT !== undefined &&
    process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'development',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'development',
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
  release: `a-type@${process.env.REACT_APP_SENTRY_VERSION ?? 'development'}`,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.REACT_APP_API_HOST ?? 'none'],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <LoadingPageComponent />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useSelector } from '@a-type/ui/hooks';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box } from '@mui/material';

import CountsHeader from './components/counts-header.components';
import CountsFilters from './components/filters/counts-filters.components';
import CountsSummary from './components/summary/counts-summary.components';

const CountsComponent = () => {
  const { count } = useSelector((state) => state.count);

  return (
    (count && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${globalStyles.unActiveSideTabColor.color}`,
            pb: 2,
          }}
        >
          <CountsHeader />
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              flexBasis: '65%',
              flexGrow: 1,
              pr: 2,
              py: 3,
            }}
          >
            <CountsFilters />
          </Box>
          <Box
            sx={{
              borderLeft: `1px solid ${globalStyles.unActiveSideTabColor.color}`,
              flexBasis: '35%',
              height: 'auto',
              maxWidth: '500px',
              pl: 2,
              py: 3,
            }}
          >
            <CountsSummary />
          </Box>
        </Box>
      </Box>
    )) ||
    null
  );
};

export default CountsComponent;

import { Box, Typography } from '@mui/material';

import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';

interface ITaxAssessorsDocumentFieldProps {
  field: ITaxAssessorsDocumentField;
}

export const TaxAssessorsDocumentField = ({ field }: ITaxAssessorsDocumentFieldProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        width: '100%',
      }}
    >
      <Typography
        component="span"
        sx={{
          color: 'gray.05',
        }}
        variant="body-xs-medium"
      >
        {field.label}
      </Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
        }}
      >
        {field.icon}

        <Typography component="span" sx={{ mt: '1px' }} variant="body-sm-medium">
          {field.value}
        </Typography>
      </Box>
    </Box>
  );
};

import { DataSourceDslStatus, DataSourceUploadStrategy } from '@a-type/enums';
import { IDataSource } from '@a-type/interfaces';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, ListItemButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type DataSourceTableItemComponentTypes = {
  dataSource: IDataSource;
  index: number;
};

const DataSourceTableItemComponent = (props: DataSourceTableItemComponentTypes) => {
  const { dataSource, index } = props;

  const navigate = useNavigate();

  const {
    _id,
    fieldsTotal = 0,
    filtersTotal = 0,
    name,
    pricingTotal = 0,
    progress = 100,
    status,
    totalRecords = 0,
  } = dataSource;

  const uploadStrategy =
    dataSource.selectUploadStrategy === DataSourceUploadStrategy.MANUALLY_UPLOAD
      ? 'Manual'
      : 'SFTP';

  let statusColor = globalStyles.mainColors.grayColor;

  switch (status) {
    case DataSourceDslStatus.COMPLETED:
      statusColor = globalStyles.mainColors.emeraldReflectionColor;
      break;
    case DataSourceDslStatus.FAILED:
      statusColor = globalStyles.mainColors.carminePinkColor;
      break;
    case DataSourceDslStatus.PROCESSING:
      statusColor = globalStyles.mainColors.lerryLemonColor;
      break;
    default:
      statusColor = globalStyles.mainColors.grayColor;
  }

  return (
    <ListItemButton
      key={index}
      onClick={() => {
        navigate(`${AppRoutes.AdminDataSourcesPage}/${_id}`);
      }}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: '15%',
        }}
      >
        <Typography sx={{ fontWeight: 800 }}>{name}</Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minHeight: 63,
          pl: 1,
          top: 0,
          width: `10%`,
        }}
      >
        <Box
          sx={{
            background: globalStyles.mainColors.greenColor,
            borderRadius: 50,
            height: 25,
            pt: 0.75,
            px: 1,
            width: 'fit-content',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.whiteColor,
              fontSize: 11,
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {uploadStrategy}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minHeight: 63,
          pl: 1,
          top: 0,
          width: `10%`,
        }}
      >
        <Box
          sx={{
            background: statusColor,
            borderRadius: 50,
            height: 25,
            pt: 0.75,
            px: 1,
            width: 'fit-content',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.whiteColor,
              fontSize: 11,
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {status}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 63,
          top: 0,
          width: `15%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blueColor, fontSize: 11, textAlign: 'end' }}
        >
          {progress}%
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 63,
          top: 0,
          width: `10%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blueColor, fontSize: 11, textAlign: 'end' }}
        >
          {fieldsTotal}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 63,
          top: 0,
          width: `10%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blueColor, fontSize: 11, textAlign: 'end' }}
        >
          {filtersTotal}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 63,
          top: 0,
          width: `15%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blueColor, fontSize: 11, textAlign: 'end' }}
        >
          {totalRecords}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 63,
          pr: 2,
          top: 0,
          width: `15%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blueColor, fontSize: 11, textAlign: 'end' }}
        >
          {pricingTotal}
        </Typography>
      </Box>
    </ListItemButton>
  );
};

export default DataSourceTableItemComponent;

import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { FieldMetadataType } from '@a-type/enums';
import { IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCurrentDataSource } from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, ButtonGroup, TextField, Theme, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import DataSourceDetailsAddNewFileFieldDialog from './components/data-source-details-add-new-file-field-dialog.components';
import DataSourceDetailsFileFieldConfigurator from './components/data-source-details-file-field-configurator.component';

export const styles = {
  filterButton: {
    '&:hover': {
      backgroundColor: globalStyles.mainColors.blueColor,
      borderColor: globalStyles.mainColors.blueColor,
      color: globalStyles.mainColors.whiteColor,
    },
    borderColor: globalStyles.mainColors.headTableGreyColor,
    borderRadius: '8px',
    color: globalStyles.mainColors.headTableGreyColor,
    lineHeight: '1',
    px: '12px',
    py: '4px',
    'span:first-of-type': {
      mr: '4px',
    },
  },
};

interface DataSourceDetailsFileMappingProps {
  setMappingValid: (value: boolean) => void;
}
const DataSourceDetailsFileMapping = (props: DataSourceDetailsFileMappingProps) => {
  const dispatch = useDispatch();
  const { setMappingValid } = props;
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const { currentDataSource, filtersComponents } = useSelector((state) => state.dataSource);
  const [selectedField, setSelectedField] = useState<null | string>(null);
  const [fields, setFields] = useState<IDataSourceField[]>([]);
  const [filteredFields, setFilteredFields] = useState<IDataSourceField[]>(fields);
  const [searchText, setSearchText] = useState('');
  const [fieldsIsValid, setFieldsIsValid] = useState<{ [key: string]: boolean }>({});
  const [includedInReport, setIncludedInReport] = useState<string[]>([]);
  const [selectedMetadataTypes, setSelectedMetadataTypes] = useState<FieldMetadataType[]>([]);
  const [addNewFieldDialog, setAddNewFieldDialog] = useState(false);

  const components = filtersComponents.reduce((acc: any, item: IDataSourceFilterComponentDto) => {
    return {
      ...acc,
      [item.type]: item.name,
    };
  }, {});

  const groups = (filtersGroups || []).reduce((acc: any, item) => {
    return {
      ...acc,
      [item.code]: item.name,
    };
  }, {});

  useEffect(() => {
    if (currentDataSource?.fields) {
      setFields([...currentDataSource.fields]);
    }

    if (currentDataSource?.pricing) {
      const f = currentDataSource.pricing
        .map((p: IDataSourcePricing) => p.includedFields)
        .reduce((acc: string[], val: string[]) => [...acc, ...val], []);

      setIncludedInReport(Array.from(new Set(f)));
    }
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchText === '') {
        setFilteredFields([...fields].sort((a, b) => a.sortOrder - b.sortOrder));
      } else {
        const f = fields.filter(
          (field: IDataSourceField) =>
            field.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            field.description?.toLowerCase().includes(searchText.toLowerCase()) ||
            field.displayName?.toLowerCase().includes(searchText.toLowerCase()),
        );
        setFilteredFields(f.sort((a, b) => a.sortOrder - b.sortOrder));
      }
    }, 250);
    return () => clearTimeout(timeOutId);
  }, [searchText, fields]);

  useEffect(() => {
    if (Object.keys(fieldsIsValid).length > 0) {
      const isValid = Object.values(fieldsIsValid).every((value) => value);
      setMappingValid(isValid);
    } else {
      setMappingValid(false);
    }
  }, [fieldsIsValid]);

  useEffect(() => {
    setSelectedMetadataTypes(fields.filter((f) => f.metadataType).map((f) => f.metadataType!));
    dispatch(setCurrentDataSource({ ...currentDataSource!, fields }));
  }, [fields]);

  const updateFieldIsValidHandler = (fieldName: string, isValid: boolean) => {
    setFieldsIsValid((prev) => {
      return {
        ...prev,
        [fieldName]: isValid,
      };
    });
  };

  const updateFieldHandler = (field: IDataSourceField) => {
    const updatedFields = fields.map((f: IDataSourceField) => {
      if (f.name === field.name) {
        return field;
      }
      return f;
    });
    setFields(updatedFields);
  };

  const deleteFieldHandler = (fieldName: string) => {
    const field = fields.find((f: IDataSourceField) => f.name === fieldName);
    if (field) {
      const updatedFields = fields
        .filter((f: IDataSourceField) => f.name !== fieldName)
        .map((f: IDataSourceField) => {
          if (f.sortOrder > field.sortOrder) {
            return {
              ...f,
              sortOrder: f.sortOrder - 1,
            };
          }
          return f;
        });
      setFields(updatedFields);
    }
  };

  const addFieldHandler = (field: IDataSourceField) => {
    const updatedFields = [...fields, field];
    setSearchText('');
    setFields(updatedFields);
    setFilteredFields(updatedFields);
    setAddNewFieldDialog(false);
    setSelectedField(field.name);
  };

  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;
  return (
    <>
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={{
            color: themeStylePalette.text.primary,
            fontSize: 20,
            fontWeight: 700,
            paddingBottom: 0,
            pt: 0.5,
          }}
        >
          Select the fields you want to show in your data source
          <Typography
            component="span"
            sx={{
              display: 'block',
              fontSize: 12,
            }}
          >
            You can select the fields you want to show in your data source. You can also change the
            order of the fields and select the fields you want to be searchable.
          </Typography>
        </Typography>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ButtonGroup
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <TextField
              label="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search"
              size="small"
              sx={{
                '.MuiOutlinedInput-root': {
                  borderEndEndRadius: 0,
                  borderStartEndRadius: 0,
                },
                flexGrow: 1,
              }}
              value={searchText}
              variant="outlined"
            />
            <Button
              onClick={() => setSearchText('')}
              size="small"
              startIcon={<CancelOutlinedIcon />}
              sx={{
                ...styles.filterButton,
                borderEndStartRadius: 0,
                borderStartStartRadius: 0,
              }}
              variant="outlined"
            >
              Clear
            </Button>
          </ButtonGroup>

          <Button
            onClick={() => {
              setAddNewFieldDialog(true);
            }}
            startIcon={<AddCircleIcon />}
          >
            Add New Section
          </Button>
        </Box>

        <DragAndDrop
          getKey={(field) => field.name}
          items={filteredFields}
          renderItem={(field, index, p, s) => (
            <DataSourceDetailsFileFieldConfigurator
              components={components}
              deleteFieldHandler={deleteFieldHandler}
              draggableProps={p.draggableProps}
              dragHandleProps={p.dragHandleProps}
              dragRef={p.innerRef}
              field={field}
              groups={groups}
              includedInReport={includedInReport}
              isDragDisabled={searchText !== ''}
              isDragging={s.isDragging}
              key={field.name}
              selectedField={selectedField}
              selectedMetadataTypes={selectedMetadataTypes}
              setSelectedField={setSelectedField}
              updateFieldHandler={updateFieldHandler}
              updateFieldIsValidHandler={updateFieldIsValidHandler}
            />
          )}
          setItems={(updatedFields) => {
            setFields([...updatedFields]);
            setFilteredFields([...updatedFields]);
          }}
        />
      </Box>

      <DataSourceDetailsAddNewFileFieldDialog
        addFieldHandler={addFieldHandler}
        setShow={setAddNewFieldDialog}
        show={addNewFieldDialog}
      />
    </>
  );
};

export default DataSourceDetailsFileMapping;

import {
  IDataSourceField,
  IDataSourceFieldOption,
  IFilterModel,
  IFilterStringValue,
} from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import SelectedValues from './selected-values.component';
import SelectedValuesControl from './selected-values-control.component';

export interface OptionSelectFilterProps {
  field: IDataSourceField;
}

export interface LookupOption {
  label: string;
  state?: string;
  value: string;
}

const OptionSelectFilter = (props: OptionSelectFilterProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<IFilterStringValue[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<IDataSourceFieldOption[]>([]);

  // Initialize filter
  useEffect(() => {
    if (!count?.filters) return;

    if (!count.filters[field.name]) {
      const filter = {
        _id: field.name,
        isFreeText: field.isFreeText,
        mode: 'add',
        name: field.displayName,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType,
        units: field.units,
        values: [],
      } as IFilterModel;
      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name]: filter } }));
    } else {
      setSelectedOptions(count.filters[field.name].values as IDataSourceFieldOption[]);
    }
  }, [count]);

  useEffect(() => {
    if (!field) return;
    setFilteredOptions(field.options || []);
  }, [field]);

  useEffect(() => {
    setFilteredOptions(
      (field.options || []).filter(
        (option: IDataSourceFieldOption) =>
          !selectedOptions.some((x: IFilterStringValue) => x.label === option.label),
      ),
    );
  }, [selectedOptions]);

  const handleSelectOption = (option: IDataSourceFieldOption) => {
    if (!count?.filters) return;

    if (selectedOptions.some((x: IFilterStringValue) => x.label === option.label)) return;
    const values = [
      ...selectedOptions,
      { from: option.from, label: option.label, to: option.to, value: option.value },
    ];
    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values,
          },
        },
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 240,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: 1,
          pt: 1.5,
          px: 1.5,
        }}
      >
        <List
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            p: 0,
            position: 'relative',
            width: '100%',
          }}
        >
          {filteredOptions.length > 0 &&
            filteredOptions.map((option) => (
              <ListItem
                key={`option-${option.value}${option.from}${option.to}`}
                sx={{
                  ':hover': {
                    backgroundColor: globalStyles.mainColors.distantWindChimeColor,
                  },
                  p: 0,
                }}
              >
                <ListItemButton onClick={() => handleSelectOption(option)} sx={{ p: 0 }}>
                  <ListItemText
                    sx={{
                      color: globalStyles.mainColors.blackColor,
                      my: '2px',
                      px: '12px',
                      py: '2px',
                    }}
                  >
                    <Typography component="span">{option.label}</Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>

      <Box
        sx={{
          borderLeft: '1px solid #e0e0e0',
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: 1,
          pt: 1.5,
          px: 1.5,
        }}
      >
        <SelectedValuesControl field={field} />
        <SelectedValues field={field} />
      </Box>
    </Box>
  );
};

export default OptionSelectFilter;

import { CardInfoTypes } from '@a-type/ui/types';
import { Box } from '@mui/material';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { StripeForm } from './stripe-form.component';

interface AddPaymentMethodProps {
  onCancel: () => void;
  onSuccess: (id: string) => void;
}

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = stripeApiKey ? loadStripe(stripeApiKey) : null;

export const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({
  onCancel,
  onSuccess,
}: AddPaymentMethodProps) => {
  const [cardNumber, setCardNumber] = useState<CardInfoTypes>({
    brand: 'unknown',
    complete: false,
    elementType: 'cardNumber',
    empty: true,
    error: false,
  });
  const [cardExpiry, setCardExpiry] = useState<CardInfoTypes>({
    complete: false,
    elementType: 'cardExpiry',
    empty: true,
    error: false,
  });
  const [cardCvc, setCardCvc] = useState<CardInfoTypes>({
    complete: false,
    elementType: 'cardCvc',
    empty: true,
    error: false,
  });
  const [inputsData, setInputsData] = useState<CardInfoTypes[] | object>({
    [cardCvc.elementType]: {
      ...cardCvc,
      onChange: setCardCvc,
    },
    [cardExpiry.elementType]: {
      ...cardExpiry,
      onChange: setCardExpiry,
    },
    [cardNumber.elementType]: {
      ...cardNumber,
      onChange: setCardNumber,
    },
  });

  useEffect(() => {
    setInputsData({
      [cardCvc.elementType]: {
        ...cardCvc,
        onChange: setCardCvc,
      },
      [cardExpiry.elementType]: {
        ...cardExpiry,
        onChange: setCardExpiry,
      },
      [cardNumber.elementType]: {
        ...cardNumber,
        onChange: setCardNumber,
      },
    });
  }, [cardNumber, cardExpiry, cardCvc]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <StripeForm
              elements={elements}
              inputsData={inputsData}
              onCancel={onCancel}
              onSuccess={onSuccess}
              stripe={stripe}
            />
          )}
        </ElementsConsumer>
      </Elements>
    </Box>
  );
};

import { AppRoutes } from '@a-type/ui/router/app-routes';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CountsListHeaderComponent = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', pb: 2 }}>
      <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Counts</Typography>
      <Button
        onClick={() => {
          navigate(`${AppRoutes.DataSourcesPage}`);
        }}
        sx={{
          minWidth: '160px',
          py: 1.25,
        }}
        variant="contained"
      >
        Build new list
      </Button>
    </Box>
  );
};

export default CountsListHeaderComponent;

import { useGetBucketDocumentMapQuery } from '@a-type/ui/stores/apis';
import { LocationOn } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

interface ITaxAssessorsDocumentStreetViewProps {
  documentId: string;
}

/**
 * Generates a static street view of the property
 */
export const TaxAssessorsDocumentStreetView: FC<ITaxAssessorsDocumentStreetViewProps> = ({
  documentId,
}: ITaxAssessorsDocumentStreetViewProps) => {
  const { data: image, isFetching } = useGetBucketDocumentMapQuery(documentId);

  return image ? (
    <Box
      sx={{
        alignItems: 'center',
        backgroundImage: `url(${image.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid',
        borderColor: 'gray.04',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '330px',
        minWidth: '400px',
        position: 'relative',
      }}
    >
      {!image.isStreetView && !isFetching && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'blue.05',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'column',
            height: 40,
            justifyContent: 'center',
            width: 40,
          }}
        >
          <LocationOn
            sx={{
              color: 'gray.00',
              fontSize: 24,
            }}
          />
        </Box>
      )}

      {isFetching && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'gray.02',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            opacity: 0.65,
            position: 'absolute',
            width: '100%',
          }}
        >
          <CircularProgress
            size={48}
            sx={{
              color: 'blue.05',
            }}
            thickness={2}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        alignItems: 'center',
        borderRadius: '24px',
        display: 'flex',
        height: '330px',
        justifyContent: 'center',
        width: '400px',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'gray.04',
          borderRadius: '24px',
          height: '330px',
          width: '400px',
        }}
      />
    </Box>
  );
};

import { IUserInfo } from '@a-type/dtos';
import Counts from '@a-type/ui/assets/icons/counts.svg';
import Credits from '@a-type/ui/assets/icons/credits.svg';
import Lists from '@a-type/ui/assets/icons/lists.svg';
import { InsightCard, SubscriptionDetails, SubscriptionStatus } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetUserStatisticsQuery, useGetUserSubscribtionQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Icon, Typography } from '@mui/material';
import { FC, useEffect } from 'react';

interface IUserStatisticsProps {
  user: IUserInfo;
}

export const UserStatistics: FC<IUserStatisticsProps> = ({ user }: IUserStatisticsProps) => {
  const dispatch = useDispatch();
  const { data: statistics, isLoading: statisticsLoading } = useGetUserStatisticsQuery(user._id, {
    skip: !user._id,
  });
  const { data: subscription, isLoading: subscriptionLoading } = useGetUserSubscribtionQuery(
    user._id,
    {
      skip: !user._id,
    },
  );

  useEffect(() => {
    dispatch(pageContentLoad(!statisticsLoading && !subscriptionLoading));
  }, [statisticsLoading, subscriptionLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {statistics && (
        <Box
          sx={{
            display: 'grid',
            gap: 4,
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          }}
        >
          <InsightCard
            border
            icon={
              <Icon
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  height: '28px',
                  width: '28px',
                }}
              >
                <img alt="Counts" src={Counts} />
              </Icon>
            }
            insight={statistics.counts.toLocaleString()}
            key="counts"
            title="counts"
          />

          <InsightCard
            border
            icon={
              <Icon
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  height: '28px',
                  width: '28px',
                }}
              >
                <img alt="Lists" src={Lists} />
              </Icon>
            }
            insight={statistics.lists.toLocaleString()}
            key="lists"
            title="lists"
          />

          <InsightCard
            border
            icon={
              <Icon
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  height: '28px',
                  width: '28px',
                }}
              >
                <img alt="Credits" src={Credits} />
              </Icon>
            }
            insight={Number(statistics.credits).toLocaleString()}
            key="available-credits"
            title="available credits"
          />
        </Box>
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          mt: 2,
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          Subscription Plan
        </Typography>

        {subscription && <SubscriptionStatus subscription={subscription} />}
      </Box>

      {subscription && <SubscriptionDetails subscription={subscription} />}
    </Box>
  );
};

import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { DataSourceFieldDataType } from '@a-type/enums';
import { IDataSourceField } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { useSelector } from '@a-type/ui/hooks';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import DataTypes from '../../utils/data-types.utils';

export interface DataSourceDetailsAddNewFileFieldDialogProps {
  addFieldHandler: (field: IDataSourceField) => void;
  setShow: (value: boolean) => void;
  show: boolean;
}

const dataTypes = Object.values(DataSourceFieldDataType).filter(
  (item: DataSourceFieldDataType) =>
    item !== DataSourceFieldDataType.ARRAY && item !== DataSourceFieldDataType.GEO_POINT,
);

const DataSourceDetailsAddNewFileFieldDialog = (
  props: DataSourceDetailsAddNewFileFieldDialogProps,
) => {
  const { currentDataSource, filtersComponents } = useSelector((state) => state.dataSource);
  const { addFieldHandler, setShow, show } = props;
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [fields, setFields] = useState<string[]>([]);
  const [isValidName, setIsValidName] = useState<boolean>(false);

  useEffect(() => {
    if (currentDataSource) {
      const f = currentDataSource.fields.map((field: IDataSourceField) => field.name);
      setFields(f);
    }
  }, [currentDataSource]);

  function nameChangeHandler(value: string) {
    setName(value);

    if (!value || fields.includes(value)) {
      setIsValidName(false);
    } else {
      setIsValidName(true);
    }
  }

  const addField = () => {
    if (!currentDataSource) return;

    const field = {
      componentType:
        filtersComponents.find((item: IDataSourceFilterComponentDto) =>
          item.dataTypes?.includes(type as DataSourceFieldDataType),
        )?.type ?? '',
      dataType: type,
      description: '',
      displayName: DataTypes.toDisplayNameString(name),
      filterGroup: '',
      isFilter: false,
      isGroupedBy: false,
      name,
      prefetchAllValues: false,
      price: 0,
      sortOrder:
        Math.max(...currentDataSource.fields.map((x: IDataSourceField) => +x.sortOrder)) + 1,
      units: '',
    } as IDataSourceField;

    addFieldHandler(field);
    setName('');
    setType('');
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Add Field"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={addField}
      open={show}
      title="Enter the name of the new field and select the type"
    >
      <Typography
        component="span"
        sx={{ color: globalStyles.mainColors.houseStarkGrey, fontSize: 14 }}
      >
        Please enter the name of the new field and select the type of the field. Name should be the
        same as the field name in the CSV file. You can&apos;t change the name of the field later.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: 2,
          width: '100%',
        }}
      >
        <TextField
          error={!!name && !isValidName}
          label="Field Name"
          onChange={(e) => {
            nameChangeHandler(e.target.value);
          }}
          placeholder="Field Name"
          sx={{
            background: globalStyles.mainColors.whiteColor,
          }}
          value={name}
          variant="outlined"
        />

        <FormControl>
          <InputLabel id="separators-label">Select type</InputLabel>
          <Select
            error={!type}
            label="Select type"
            onChange={(e: any) => setType(e.target.value)}
            sx={{
              backgroundColor: globalStyles.mainColors.whiteColor,
            }}
            value={type}
            variant="outlined"
          >
            {dataTypes.map((output: DataSourceFieldDataType) => {
              return (
                <MenuItem key={output} value={output}>
                  {output}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Dialog>
  );
};

export default DataSourceDetailsAddNewFileFieldDialog;

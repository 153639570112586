import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Dialog as MuiDialog, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode } from 'react';

export interface IDialogProps {
  /**
   * Text for the cancel button
   * @type string
   */
  cancelText?: string;
  /**
   * Dialog content
   * @type ReactNode
   * @required
   */
  children: ReactNode;
  /**
   * Disable the cancel button
   * @type boolean
   * @default false
   */
  disableCancel?: boolean;
  /**
   * Disable the ok button
   * @type boolean
   * @default false
   */
  disableOk?: boolean;
  /**
   * Dialog footer content
   * @type ReactNode
   */
  footer?: ReactNode;
  /**
   * Full screen dialog
   * @type boolean
   * @default false
   */
  fullScreen?: boolean;
  /**
   * Text for the ok button
   * @type string
   * @default 'Ok'
   */
  okText?: string;
  /**
   * Cancel button click handler
   * @type () => void
   */
  onCancel?: () => void;
  /**
   * Close dialog handler
   * @type () => void
   * @required
   */
  onClose: () => void;
  /**
   * Ok button click handler
   * @type () => void
   */
  onOk?: () => void;
  /**
   * Open dialog state
   * @type boolean
   * @required
   */
  open: boolean;
  /**
   * Show close button
   * @type boolean
   * @default true
   */
  showCloseButton?: boolean;
  /**
   * Dialog width size
   * sm - 440px
   * md - 600px
   * lg - 750px
   * @type 'lg' | 'md' | 'sm'
   * @default 'md'
   */
  size?: 'lg' | 'md' | 'sm';
  /**
   * Dialog title
   * @type string
   * @required
   */
  title: string;
}

const Transition = React.forwardRef(function Transition(
  props: {
    children: React.ReactElement<any, any>;
  } & TransitionProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Dialog component
 */
export const Dialog = ({
  cancelText,
  children,
  disableCancel = false,
  disableOk = false,
  footer,
  fullScreen = false,
  okText = 'Ok',
  onCancel,
  onClose,
  onOk,
  open,
  showCloseButton = true,
  size = 'md',
  title,
}: IDialogProps) => {
  const getWidth = () => {
    switch (size) {
      case 'lg':
        return 750;
      case 'sm':
        return 440;
      case 'md':
      default:
        return 600;
    }
  };

  return (
    <MuiDialog
      fullScreen={fullScreen}
      fullWidth
      keepMounted
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: fullScreen ? 0 : '32px',
        },
        '& .MuiDialog-paperWidthSm': {
          maxWidth: getWidth(),
          width: getWidth(),
        },
      }}
      TransitionComponent={Transition}
    >
      {showCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: 'black',
            position: 'absolute',
            right: 24,
            top: 28,
          }}
        >
          <Close />
        </IconButton>
      )}

      <Typography sx={{ color: 'gray.10', lineHeight: '36px', p: 4 }} variant="headline-4">
        {title}
      </Typography>

      <Typography
        component="div"
        sx={{
          color: 'gray.10',
          lineHeight: '28px',
          px: 4,
          wordWrap: 'break-word',
        }}
        variant="body-lg-regular"
      >
        {children}
      </Typography>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 3,
          justifyContent: 'space-between',
          mt: 2,
          p: 4,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {footer}
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {cancelText ? (
            <Button
              disabled={disableCancel}
              onClick={() => {
                if (onCancel) onCancel();
                onClose();
              }}
              size="medium"
              sx={{ minWidth: 132 }}
              variant="outlined"
            >
              {cancelText}
            </Button>
          ) : null}

          {onOk && okText ? (
            <Button
              disabled={disableOk}
              onClick={() => {
                if (onOk) onOk();
              }}
              size="medium"
              sx={{ minWidth: 132 }}
              variant="contained"
            >
              {okText}
            </Button>
          ) : null}
        </Box>
      </Box>
    </MuiDialog>
  );
};

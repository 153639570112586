import { TargetType } from '@a-type/enums';
import { IFieldMetadata, IList } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetDocumentsQuery,
  useGetListQuery,
  useUpdateListMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ListSettings } from '../components';

export const EditListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listId } = useParams<{ listId: string }>();

  // if no list id, redirect to lists page
  if (!listId) navigate(AppRoutes.Lists);
  const { data: listData, isLoading } = useGetListQuery(listId!);
  const { data: listExamples } = useGetDocumentsQuery({
    id: listId!,
    limit: 5,
    page: 0,
  });
  const [list, setList] = useState<IList>();
  const [examples, setExamples] = useState<
    Record<
      string,
      {
        data: string;
        key: string;
      }[]
    >
  >({});
  const [updateList, { isLoading: isUpdatingList }] = useUpdateListMutation();

  // show loading spinner while fetching list
  useEffect(() => {
    dispatch(pageContentLoad(!(isLoading || isUpdatingList)));
  }, [isLoading, isUpdatingList]);

  // update list state
  useEffect(() => {
    if (listData) setList(listData);
  }, [listData]);

  // update examples state
  useEffect(() => {
    if (listExamples && list) {
      setExamples(
        Object.entries(list.fieldMetadata!).reduce<
          Record<
            string,
            {
              data: string;
              key: string;
            }[]
          >
        >((acc, [key]) => {
          acc[key] = listExamples.items.map((item) => ({
            data: item[key],
            key: `${key}-${item._id}`,
          }));
          return acc;
        }, {}),
      );
    }
  }, [listExamples, list]);

  const updateFieldValueHandler = (key: string, field: string, value: any) => {
    setList({
      ...list,
      fieldMetadata: {
        ...(list?.fieldMetadata || {}),
        [field]: {
          ...((list?.fieldMetadata && list.fieldMetadata[field]) || {}),
          [key]: value,
        } as IFieldMetadata,
      },
    } as IList);
  };

  const handleUpdateList = () => {
    if (!list) return;

    updateList({
      _id: list._id!,
      fieldMetadata: list.fieldMetadata,
      name: list.name!,
      targetType: list.targetType || TargetType.CUSTOMER,
    });

    navigate(`${AppRoutes.Lists}/${list._id}`);
  };

  return (
    <PageLayout container wide>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
          paddingX: '32px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton onClick={() => navigate(`${AppRoutes.Lists}/${list?._id}`)}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              Edit List
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                navigate(`${AppRoutes.Lists}/${list?._id}`);
              }}
              sx={{
                width: 160,
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateList}
              sx={{
                width: 160,
              }}
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            // all elements in box same size
            '& > *': {
              flexBasis: '0',
              flexGrow: 1,
              flexShrink: 1,
            },
            display: 'flex',
            gap: 4,
          }}
        >
          <FormControl
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <FormLabel
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              List Name
            </FormLabel>
            <TextField
              onChange={(e) => {
                setList({ ...list, name: e.target.value || '' } as IList);
              }}
              value={list?.name || ''}
            />
          </FormControl>

          <FormControl
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <FormLabel
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              Type of the Data
            </FormLabel>
            <Select
              onChange={(e) => {
                setList({ ...list, targetType: e.target.value as TargetType } as IList);
              }}
              value={list?.targetType || TargetType.CUSTOMER}
            >
              <MenuItem key={TargetType.CUSTOMER} value={TargetType.CUSTOMER}>
                Resedential
              </MenuItem>
              <MenuItem key={TargetType.BUSINESS} value={TargetType.BUSINESS}>
                Business
              </MenuItem>
            </Select>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              # of Rows
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                color: globalStyles.mainColors.sootyColor,
                display: 'flex',
                flexGrow: 1,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              {list?.totalRecords?.toLocaleString()}
            </Typography>
          </Box>
        </Box>

        {list && (
          <ListSettings
            examples={examples}
            list={list}
            updateFieldValueHandler={updateFieldValueHandler}
          />
        )}
      </Box>
    </PageLayout>
  );
};

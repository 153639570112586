import { IStripeSubscription } from '@a-type/dtos';
import { ActionButton } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarIcon from '@mui/icons-material/Star';
import { Box, Button, Card, Chip, Typography } from '@mui/material';
import { useState } from 'react';

import { ActivateSubscriptionDialog } from './activate-subscription-dialog.component';
import { AddSubscriptionDialog } from './add-subscription-dialog.component';
import { DeactivateSubscriptionDialog } from './deactivate-subscription-dialog.component';
import { SetDefaultSubscriptionDialog } from './set-default-subscription-dialog.component';

interface PlanSubscriptionsProps {
  defaultSubscriptionId?: string;
  productId: string;
  subscriptions: IStripeSubscription[];
}

export const PlanSubscriptions: React.FC<PlanSubscriptionsProps> = ({
  defaultSubscriptionId,
  productId,
  subscriptions,
}: PlanSubscriptionsProps) => {
  const [showCreateSubscription, setShowCreateSubscription] = useState(false);
  const [showSetDefaultSubscription, setShowSetDefaultSubscription] = useState(false);
  const [showActivateSubscription, setShowActivateSubscription] = useState(false);
  const [showDeactivateSubscription, setShowDeactivateSubscription] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<IStripeSubscription | null>(
    null,
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            Subscription pricing
          </Typography>
          <Button
            onClick={() => setShowCreateSubscription(true)}
            size="small"
            startIcon={<AddCircleIcon />}
          >
            Add subscription pricing
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {subscriptions.map((subscription) => (
            <Card
              key={subscription.id}
              sx={{
                alignItems: 'stretch',
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {subscription.name}
                </Typography>
                <Typography
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 14,
                  }}
                >
                  Price: ${subscription.price.toFixed(2)}
                </Typography>
                <Typography
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 14,
                  }}
                >
                  Credits: {subscription.credits.toLocaleString()}
                </Typography>
              </Box>

              <Box
                sx={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3.25,
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  {subscription.id === defaultSubscriptionId && (
                    <Chip
                      color="primary"
                      label="Default"
                      size="small"
                      sx={{ color: globalStyles.mainColors.whiteColor, fontWeight: 500 }}
                    />
                  )}

                  {subscription.active && (
                    <Chip
                      color="success"
                      label="Active"
                      size="small"
                      sx={{ color: globalStyles.mainColors.whiteColor, fontWeight: 500 }}
                    />
                  )}
                  {!subscription.active && (
                    <Chip
                      color="error"
                      label="Inactive"
                      size="small"
                      sx={{ color: globalStyles.mainColors.whiteColor, fontWeight: 500 }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                  }}
                >
                  {subscription.id !== defaultSubscriptionId && subscription.active && (
                    <ActionButton
                      icon={<StarIcon />}
                      label="Set as Default"
                      onClick={() => {
                        setSelectedSubscription(subscription);
                        setShowSetDefaultSubscription(true);
                      }}
                    />
                  )}

                  {subscription.id !== defaultSubscriptionId && !subscription.active && (
                    <ActionButton
                      icon={<PlayArrowIcon />}
                      label="Activate"
                      onClick={() => {
                        setSelectedSubscription(subscription);
                        setShowActivateSubscription(true);
                      }}
                    />
                  )}

                  {subscription.id !== defaultSubscriptionId && subscription.active && (
                    <ActionButton
                      icon={<PauseIcon />}
                      label="Deactivate"
                      onClick={() => {
                        setSelectedSubscription(subscription);
                        setShowDeactivateSubscription(true);
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Card>
          ))}
        </Box>
      </Box>

      {showCreateSubscription && (
        <AddSubscriptionDialog
          onClose={() => setShowCreateSubscription(false)}
          open={showCreateSubscription}
          productId={productId}
        />
      )}

      {selectedSubscription && (
        <SetDefaultSubscriptionDialog
          onClose={() => setShowSetDefaultSubscription(false)}
          open={showSetDefaultSubscription}
          subscription={selectedSubscription}
        />
      )}

      {selectedSubscription && (
        <ActivateSubscriptionDialog
          onClose={() => setShowActivateSubscription(false)}
          open={showActivateSubscription}
          subscription={selectedSubscription}
        />
      )}

      {selectedSubscription && (
        <DeactivateSubscriptionDialog
          onClose={() => setShowDeactivateSubscription(false)}
          open={showDeactivateSubscription}
          subscription={selectedSubscription}
        />
      )}
    </>
  );
};

import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarInfoMessage,
} from '@a-type/ui/stores/actions';
import { useCreateCardMutation, useGetCardsQuery } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box, Button } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { CustomStripeElement } from './custom-stripe-element.component';
import { styles } from './stripe-form.styles';

interface StripeFormProps {
  elements: null | StripeElements;
  inputsData: any;
  onCancel: () => void;
  onSuccess: (id: string) => void;
  stripe: null | Stripe;
}

export const StripeForm: React.FC<StripeFormProps> = ({
  elements,
  inputsData,
  onCancel,
  onSuccess,
  stripe,
}: StripeFormProps) => {
  const dispatch = useDispatch();
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [createCard, { isLoading: createCardLoading }] = useCreateCardMutation();
  const { isLoading: cardsLoading, refetch } = useGetCardsQuery();

  useEffect(() => {
    dispatch(pageContentLoad(!stripeLoading && !createCardLoading && !cardsLoading));
  }, [stripeLoading, createCardLoading, cardsLoading]);

  const onChangeHandler = (data: any) => {
    inputsData[data.elementType].onChange({
      ...inputsData[data.elementType],
      brand: data.brand,
      complete: data.complete,
      empty: data.empty,
      error: data.error,
    });
  };

  const STRIPE_ELEMENTS_IDS = {
    CARD_CVC: 'cardCvc',
    CARD_EXPIRY: 'cardExpiry',
    CARD_NUMBER: 'cardNumber',
  };

  const onSaveCardHandler = async () => {
    if (!stripe || !elements) {
      dispatch(snackbarErrorMessage('Stripe is not loaded'));
      return;
    }

    const cardNumberElement = elements.getElement('cardNumber');
    if (!cardNumberElement) {
      dispatch(snackbarErrorMessage('Card number is not valid'));
      return;
    }

    setStripeLoading(true);
    const payment = await stripe.createPaymentMethod({
      card: cardNumberElement,
      type: 'card',
    });

    if (payment.error) {
      dispatch(
        snackbarErrorMessage(payment.error.message ?? 'Error while creating payment method'),
      );
      setStripeLoading(false);
      return;
    }

    dispatch(snackbarInfoMessage('Preparing your card...'));

    const result = await createCard(payment.paymentMethod.id);
    if (result.error) {
      setStripeLoading(false);
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while creating card'));
      return;
    }

    // wait 1sec to let the card be added to the list
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    setStripeLoading(false);

    // Refetch cards list
    await refetch();

    dispatch(snackbarInfoMessage('Card successfully added.'));
    dispatch(pageContentLoad(true));
    onSuccess(payment.paymentMethod.id);
  };

  const isSaveButtonDisabled = () => {
    return Object.values(inputsData).some(
      (item: any) => item.error || item.empty || !item.complete,
    );
  };

  return (
    <>
      <Box sx={styles}>
        <CustomStripeElement
          brand={inputsData[STRIPE_ELEMENTS_IDS.CARD_NUMBER].brand}
          className="card-number"
          classNameWrapper="card-number-wrapper"
          complete={inputsData[STRIPE_ELEMENTS_IDS.CARD_NUMBER].complete}
          component={CardNumberElement}
          empty={inputsData[STRIPE_ELEMENTS_IDS.CARD_NUMBER].empty}
          error={inputsData[STRIPE_ELEMENTS_IDS.CARD_NUMBER].error}
          id={STRIPE_ELEMENTS_IDS.CARD_NUMBER}
          onChange={onChangeHandler}
        />
        <CustomStripeElement
          brand={inputsData[STRIPE_ELEMENTS_IDS.CARD_EXPIRY].brand}
          className="card-exp"
          classNameWrapper="card-exp-wrapper"
          complete={inputsData[STRIPE_ELEMENTS_IDS.CARD_EXPIRY].complete}
          component={CardExpiryElement}
          empty={inputsData[STRIPE_ELEMENTS_IDS.CARD_EXPIRY].empty}
          error={inputsData[STRIPE_ELEMENTS_IDS.CARD_EXPIRY].error}
          id={STRIPE_ELEMENTS_IDS.CARD_EXPIRY}
          onChange={onChangeHandler}
        />
        <CustomStripeElement
          brand={inputsData[STRIPE_ELEMENTS_IDS.CARD_CVC].brand}
          className="card-cvc"
          classNameWrapper="card-cvc-wrapper"
          complete={inputsData[STRIPE_ELEMENTS_IDS.CARD_CVC].complete}
          component={CardCvcElement}
          empty={inputsData[STRIPE_ELEMENTS_IDS.CARD_CVC].empty}
          error={inputsData[STRIPE_ELEMENTS_IDS.CARD_CVC].error}
          id={STRIPE_ELEMENTS_IDS.CARD_CVC}
          onChange={onChangeHandler}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={onCancel} size="small" variant="text">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={isSaveButtonDisabled()}
          onClick={onSaveCardHandler}
          size="small"
          variant="text"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

import { FieldMetadataType } from '@a-type/enums';
import { IFieldMetadata, IList } from '@a-type/interfaces';
import { UseIgnoreSwitch } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MdClear from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

const metadataTypes = Object.values(FieldMetadataType);

export interface ListSettingsProps {
  examples: Record<
    string,
    {
      data: string;
      key: string;
    }[]
  >;
  ignoreFields?: { [key: string]: boolean };
  list: IList;
  setIgnoreFields?: (ignoreFields: { [key: string]: boolean }) => void;
  updateFieldValueHandler: (key: string, field: string, value: any) => void;
}

export const ListSettings = ({
  examples,
  ignoreFields = {},
  list,
  setIgnoreFields = () => {},
  updateFieldValueHandler,
}: ListSettingsProps) => {
  const [selectedMetadataTypes, setSelectedMetadataTypes] = useState<FieldMetadataType[]>([]);
  const [selectedAllIgnore, setSelectedAllIgnore] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (Object.values(ignoreFields).every((ignore) => ignore)) {
      setSelectedAllIgnore(true);
      return;
    }

    if (Object.values(ignoreFields).every((ignore) => !ignore)) {
      setSelectedAllIgnore(false);
      return;
    }

    setSelectedAllIgnore(undefined);
  }, [ignoreFields]);

  useEffect(() => {
    if (!list?.fieldMetadata) return;

    setSelectedMetadataTypes(
      Object.values(list.fieldMetadata).reduce<FieldMetadataType[]>((acc, field) => {
        if (field.metadataType) {
          acc.push(field.metadataType);
        }
        return acc;
      }, []),
    );
  }, [list]);

  const clearIcon = (field: IFieldMetadata) => (
    <IconButton
      onClick={() => {
        updateFieldValueHandler('metadataType', field.name, undefined);
      }}
      size="small"
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
        marginRight: '8px',
        padding: 0,
      }}
    >
      <MdClear fontSize="small" />
    </IconButton>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box key="__metadata" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography sx={{ fontSize: 17, fontWeight: 700 }}>
          What Type of Information Your Columns Have?
        </Typography>
        <Box
          sx={{
            backgroundColor: globalStyles.mainColors.hawkesBlueColor,
            borderColor: globalStyles.mainColors.hawkesBlueSecondColor,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            px: 3,
            py: 2,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.headTableGreyColor,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            First off - let’s define what makes a unique record.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            {metadataTypes.map((type) => (
              <Chip
                key={type}
                label={
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedMetadataTypes.includes(type)
                          ? globalStyles.mainColors.blackColor
                          : globalStyles.mainColors.siriyGrayColor,
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {type}
                    </Typography>

                    {selectedMetadataTypes.includes(type) && (
                      <CheckCircleIcon
                        sx={{
                          color: globalStyles.mainColors.blueColor,
                          fontSize: 17,
                        }}
                      />
                    )}
                  </Box>
                }
                sx={{
                  backgroundColor: globalStyles.mainColors.whiteColor,
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <Box key="__header" sx={{ alignItems: 'center', display: 'flex', gap: 2, pb: 1 }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            flex: 1,
            fontSize: 12,
            fontWeight: 700,
            marginRight: '120px',
            maxWidth: '280px',
            textTransform: 'uppercase',
            width: '100%',
          }}
        >
          First Row
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            flex: 1,
            fontSize: 12,
            fontWeight: 700,
            marginRight: '120px',
            maxWidth: '280px',
            textTransform: 'uppercase',
            width: '100%',
          }}
        >
          How should we map this
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 12,
            fontWeight: 700,
            textTransform: 'uppercase',
            width: '25%',
          }}
        >
          Sample data
        </Typography>
        {Object.keys(ignoreFields).length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '15%',
            }}
          >
            <UseIgnoreSwitch
              checked={selectedAllIgnore}
              ignoreLabel="Ignore All"
              onChange={(value) => {
                setIgnoreFields(
                  Object.keys(ignoreFields).reduce<{ [key: string]: boolean }>((acc, key) => {
                    acc[key] = value;
                    return acc;
                  }, {}),
                );
              }}
              useLabel="Use All"
            />
          </Box>
        )}
      </Box>

      {list.fieldMetadata &&
        Object.values(list.fieldMetadata)
          .filter((field) => field.hideInReport !== true)
          .map((field) => (
            <Box
              key={field.name}
              sx={{
                alignItems: 'center',
                borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
                display: 'flex',
                gap: 2,
                pb: 2,
              }}
            >
              <TextField
                onChange={(e) => {
                  updateFieldValueHandler('displayName', field.name, e.target.value);
                }}
                size="small"
                sx={{
                  flex: 1,
                  marginRight: '120px',
                  maxWidth: '280px',
                  width: '100%',
                }}
                value={field.displayName}
                variant="outlined"
              />
              <Select
                IconComponent={
                  field.metadataType !== undefined ? () => clearIcon(field) : undefined
                }
                onChange={(e) => {
                  updateFieldValueHandler(
                    'metadataType',
                    field.name,
                    e.target.value === '' ? undefined : e.target.value,
                  );
                }}
                size="small"
                sx={{
                  flex: 1,
                  marginRight: '120px',
                  maxWidth: '280px',
                  width: '100%',
                }}
                value={field.metadataType ?? ''}
                variant="outlined"
              >
                {metadataTypes.map((x: FieldMetadataType) => {
                  return (
                    <MenuItem
                      disabled={selectedMetadataTypes.includes(x) && x !== field.metadataType}
                      key={x}
                      value={x}
                    >
                      {x}
                    </MenuItem>
                  );
                })}
              </Select>
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  width: '25%',
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 14,
                    maxWidth: '250px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {examples[field.name] && examples[field.name][0]?.data
                    ? examples[field.name][0].data
                    : 'No data'}
                </Typography>

                {examples[field.name] && examples[field.name].some((example) => example.data) && (
                  <Tooltip
                    placement="top"
                    title={
                      <Box
                        sx={{
                          size: '12px',
                        }}
                      >
                        <Typography sx={{ fontSize: '12px', fontWeight: 700 }}>
                          Data sample:
                        </Typography>

                        {examples[field.name]
                          .filter((example) => example.data)
                          .map((example) => (
                            <Typography
                              key={example.key}
                              sx={{ fontSize: '12px', lineHeight: '20px' }}
                            >
                              {example.data}
                            </Typography>
                          ))}
                      </Box>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: globalStyles.mainColors.siriyGrayColor,
                        fontSize: 20,
                        marginLeft: 1,
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
              {ignoreFields[field.name] !== undefined && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '15%',
                  }}
                >
                  <UseIgnoreSwitch
                    checked={ignoreFields[field.name]}
                    onChange={(value) => {
                      setIgnoreFields({ ...ignoreFields, [field.name]: value });
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}
    </Box>
  );
};

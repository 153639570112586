import { Box } from '@mui/material';

interface PageLayoutProps {
  children: React.ReactNode;
  container?: boolean;
  wide?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  container = false,
  wide = false,
}: PageLayoutProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        overflow: 'auto',
        px: 8,
        py: 4,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: 'auto',
          maxWidth: wide ? 1920 : 1384,
          width: '100%',
        }}
      >
        {container ? (
          <Box
            sx={{
              background: 'white',
              borderRadius: '24px',
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              height: 'auto',
              minHeight: '100%',
              p: 3,
              width: '100%',
            }}
          >
            {children}
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

import { IVersiumAppendType } from '@a-type/interfaces';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';

export interface IAppendDataTypeWithSubTypesProps {
  alreadyBought: boolean;
  alreadySelectedSubTypes: string[];
  disabledSubTypes?: string[];
  isIndividualPrice?: boolean;
  onSelected: (typeId: string, selected: boolean) => void;
  onSelectedSubType: (subTypeId: string) => void;
  selected: boolean;
  selectedSubType: string;
  type: IVersiumAppendType;
}

export const AppendDataTypeWithSubTypes = ({
  alreadyBought,
  alreadySelectedSubTypes,
  disabledSubTypes = [],
  isIndividualPrice = false,
  onSelected,
  onSelectedSubType,
  selected,
  selectedSubType,
  type,
}: IAppendDataTypeWithSubTypesProps) => {
  const disableType =
    alreadyBought ||
    type.subTypes?.every(
      (subType) =>
        disabledSubTypes.includes(subType.code) || alreadySelectedSubTypes.includes(subType.code),
    );

  return (
    <Box
      key={type._id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={selected ?? false} size="small" />}
        disabled={disableType}
        key={type._id}
        label={
          <Box
            sx={{
              alignItems: 'baseline',
              display: 'flex',
              gap: 1,
              mt: 0.25,
            }}
          >
            <Typography
              sx={{
                color: disableType ? 'gray.06' : 'gray.10',
                fontSize: 16,
                fontWeight: '500',
              }}
            >
              {type.name}
            </Typography>

            {disableType && (
              <Tooltip
                placement="top"
                title="You already have this data in your list. You can't buy it again."
              >
                <CheckCircle
                  fontSize="small"
                  sx={{
                    alignSelf: 'flex-start',
                    color: 'green.07',
                    mt: 0.25,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        }
        onChange={(e, checked) => onSelected(type._id, checked)}
        value={selected}
      />

      {selected && type.subTypes && type.subTypes.length > 0 && (
        <RadioGroup
          onChange={(e) => onSelectedSubType(e.target.value)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pl: 4,
          }}
          value={selectedSubType ?? ''}
        >
          {type.subTypes.map((subType) => {
            const subTypeAlreadyBought = alreadySelectedSubTypes.includes(subType.code);
            const disableSubType = disabledSubTypes.includes(subType.code) || subTypeAlreadyBought;

            return (
              <FormControlLabel
                control={<Radio size="small" />}
                disabled={disableSubType}
                key={subType._id}
                label={
                  <Box
                    sx={{
                      alignItems: 'baseline',
                      display: 'flex',
                      gap: 1,
                      mt: 0.25,
                    }}
                  >
                    <Typography
                      sx={{
                        color: disableSubType ? 'gray.06' : 'gray.10',
                        component: 'span',
                        fontSize: '16spx',
                        fontWeight: '500',
                      }}
                    >
                      {subType.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: disableSubType ? 'gray.06' : 'gray.10',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      (
                      <Typography
                        component="span"
                        sx={{
                          color: disableSubType ? 'gray.06' : 'green.07',
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        {isIndividualPrice ? subType.individualPrice : subType.price} Credits&nbsp;
                      </Typography>
                      / Match)
                    </Typography>

                    {subType.description && (
                      <Tooltip placement="top" title={subType.description}>
                        <InfoOutlined
                          fontSize="small"
                          sx={{
                            alignSelf: 'flex-start',
                            color: disableSubType ? 'gray.06' : 'gray.10',
                            mt: 0.25,
                          }}
                        />
                      </Tooltip>
                    )}

                    {subTypeAlreadyBought && (
                      <Tooltip
                        placement="top"
                        title="You already have this data in your list. You can't buy it again."
                      >
                        <CheckCircle
                          fontSize="small"
                          sx={{
                            alignSelf: 'flex-start',
                            color: 'green.07',
                            mt: 0.25,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                }
                value={subType._id}
              />
            );
          })}
        </RadioGroup>
      )}
    </Box>
  );
};

import { IDocument, IList } from '@a-type/interfaces';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppendDataStatus } from '../../enums/append-data-status.enum';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from '../tax-assessors-document-info/tax-assessors-document-field.component';

const getDemographicPropertyInfo = (document: IDocument, field: string, metadata: any) => {
  const value = getPropertyInfo(document, field, metadata);

  if (value) {
    if (value.value === 'Yes')
      value.icon = <CheckCircle fontSize="small" sx={{ color: 'green.07' }} />;
    else if (value.value === 'No')
      value.icon = <Cancel fontSize="small" sx={{ color: 'red.06' }} />;
  }

  return value;
};

const DEMOGRAPHIC_FIELDS = [
  TaxAssessorsDocumentFields.DemographicsDOB,
  TaxAssessorsDocumentFields.DemographicsAgeRange,
  TaxAssessorsDocumentFields.DemographicsGender,
  TaxAssessorsDocumentFields.DemographicsEthnicGroup,
  TaxAssessorsDocumentFields.DemographicsReligion,
  TaxAssessorsDocumentFields.DemographicsEducationLevel,
  TaxAssessorsDocumentFields.DemographicsOccupation,
  TaxAssessorsDocumentFields.DemographicsLanguage,
  TaxAssessorsDocumentFields.DemographicsMaritalStatus,
  TaxAssessorsDocumentFields.DemographicsWorkingWomaninHousehold,
  TaxAssessorsDocumentFields.DemographicsSeniorinHousehold,
  TaxAssessorsDocumentFields.DemographicsSingleParent,
  TaxAssessorsDocumentFields.DemographicsPresenceofChildren,
  TaxAssessorsDocumentFields.DemographicsNumberofChildren,
  TaxAssessorsDocumentFields.DemographicsYoungAdultinHousehold,
  TaxAssessorsDocumentFields.DemographicsSmallOfficeorHomeOffice,
  TaxAssessorsDocumentFields.DemographicsOnlinePurchasingIndicator,
  TaxAssessorsDocumentFields.DemographicsOnlineEducation,
];

const FINANCIAL_FIELDS = [
  TaxAssessorsDocumentFields.DemographicsHomeOwnorRent,
  TaxAssessorsDocumentFields.DemographicsHouseholdIncome,
  TaxAssessorsDocumentFields.DemographicsEstimatedNetWorth,
  TaxAssessorsDocumentFields.DemographicsHomeYearBuilt,
  TaxAssessorsDocumentFields.DemographicsHomePurchaseDate,
  TaxAssessorsDocumentFields.DemographicsHomePurchasePrice,
  TaxAssessorsDocumentFields.DemographicsDwellingType,
  TaxAssessorsDocumentFields.DemographicsHomeValue,
  TaxAssessorsDocumentFields.DemographicsLengthofResidence,
  TaxAssessorsDocumentFields.DemographicsCreditCardHolderBank,
  TaxAssessorsDocumentFields.DemographicsNumberofCreditLines,
  TaxAssessorsDocumentFields.DemographicsUpscaleCardHolder,
  TaxAssessorsDocumentFields.DemographicsCreditRating,
  TaxAssessorsDocumentFields.DemographicsMortgagePurchaseAmount,
  TaxAssessorsDocumentFields.DemographicsMortgagePurchaseLoanType,
  TaxAssessorsDocumentFields.DemographicsMortgagePurchaseInterestRateType,
  TaxAssessorsDocumentFields.DemographicsMortgagePurchaseDate,
  TaxAssessorsDocumentFields.Demographics2ndMostRecentMortgageAmount,
  TaxAssessorsDocumentFields.Demographics2ndMostRecentMortgageLoanType,
  TaxAssessorsDocumentFields.Demographics2ndMostRecentMortgageInterestRateType,
  TaxAssessorsDocumentFields.Demographics2ndMostRecentMortgageDate,
  TaxAssessorsDocumentFields.DemographicsLoantoValue,
  TaxAssessorsDocumentFields.DemographicsRefinanceDate,
  TaxAssessorsDocumentFields.DemographicsRefinanceAmount,
  TaxAssessorsDocumentFields.DemographicsRefinanceLoanType,
  TaxAssessorsDocumentFields.DemographicsRefinanceRateType,
  TaxAssessorsDocumentFields.DemographicsHomePool,
  TaxAssessorsDocumentFields.DemographicsAutoYear,
  TaxAssessorsDocumentFields.DemographicsAutoMake,
  TaxAssessorsDocumentFields.DemographicsAutoModel,
];

const LIFESTYLE_FIELDS = [
  TaxAssessorsDocumentFields.DemographicsMagazines,
  TaxAssessorsDocumentFields.DemographicsReading,
  TaxAssessorsDocumentFields.DemographicsCurrentAffairsandPolitics,
  TaxAssessorsDocumentFields.DemographicsMailOrderBuyer,
  TaxAssessorsDocumentFields.DemographicsDietingandWeightLoss,
  TaxAssessorsDocumentFields.DemographicsTravel,
  TaxAssessorsDocumentFields.DemographicsMusic,
  TaxAssessorsDocumentFields.DemographicsConsumerElectronics,
  TaxAssessorsDocumentFields.DemographicsArts,
  TaxAssessorsDocumentFields.DemographicsAntiques,
  TaxAssessorsDocumentFields.DemographicsHomeImprovement,
  TaxAssessorsDocumentFields.DemographicsGardening,
  TaxAssessorsDocumentFields.DemographicsCooking,
  TaxAssessorsDocumentFields.DemographicsExercise,
  TaxAssessorsDocumentFields.DemographicsSports,
  TaxAssessorsDocumentFields.DemographicsOutdoors,
  TaxAssessorsDocumentFields.DemographicsWomensApparel,
  TaxAssessorsDocumentFields.DemographicsMensApparel,
  TaxAssessorsDocumentFields.DemographicsPets,
  TaxAssessorsDocumentFields.DemographicsInvesting,
  TaxAssessorsDocumentFields.DemographicsHealthandBeauty,
  TaxAssessorsDocumentFields.DemographicsDecoratingandFurnishing,
];

const POLITICAL_FIELDS = [
  TaxAssessorsDocumentFields.DemographicsPartyAffiliation,
  TaxAssessorsDocumentFields.DemographicsDonorEnvironmental,
  TaxAssessorsDocumentFields.DemographicsDonorAnimalWelfare,
  TaxAssessorsDocumentFields.DemographicsDonorArtsandCulture,
  TaxAssessorsDocumentFields.DemographicsDonorChildrensCauses,
  TaxAssessorsDocumentFields.DemographicsDonorEnvironmentalorWildlife,
  TaxAssessorsDocumentFields.DemographicsDonorHealth,
  TaxAssessorsDocumentFields.DemographicsDonorInternationalAid,
  TaxAssessorsDocumentFields.DemographicsDonorPolitical,
  TaxAssessorsDocumentFields.DemographicsDonorConservativePolitics,
  TaxAssessorsDocumentFields.DemographicsDonorLiberalPolitics,
  TaxAssessorsDocumentFields.DemographicsDonorReligious,
  TaxAssessorsDocumentFields.DemographicsDonorVeterans,
  TaxAssessorsDocumentFields.DemographicsDonorUnspecified,
  TaxAssessorsDocumentFields.DemographicsDonorCommunity,
];

interface ITaxAssessorsDocumentDemographicTabProps {
  appendStatus: AppendDataStatus;
  document: IDocument;
  list: IList;
  setAppendType: (type: 'contact' | 'demographic') => void;
  setShowAppendData: (show: boolean) => void;
}

export const TaxAssessorsDocumentDemographicTab = ({
  appendStatus,
  document,
  list,
  setAppendType,
  setShowAppendData,
}: ITaxAssessorsDocumentDemographicTabProps) => {
  const [demographicProps, setDemographicProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [financialProps, setFinancialProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [lifestyleProps, setLifestyleProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [politicalProps, setPoliticalProps] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setDemographicProps(
      DEMOGRAPHIC_FIELDS.map((field) =>
        getDemographicPropertyInfo(document, field, metadata),
      ).filter((field) => field !== undefined),
    );

    setFinancialProps(
      FINANCIAL_FIELDS.map((field) => getDemographicPropertyInfo(document, field, metadata)).filter(
        (field) => field !== undefined,
      ),
    );

    setLifestyleProps(
      LIFESTYLE_FIELDS.map((field) => getDemographicPropertyInfo(document, field, metadata)).filter(
        (field) => field !== undefined,
      ),
    );

    setPoliticalProps(
      POLITICAL_FIELDS.map((field) => getDemographicPropertyInfo(document, field, metadata)).filter(
        (field) => field !== undefined,
      ),
    );
  }, [document, list]);

  return (
    <>
      {appendStatus === AppendDataStatus.NOT_STARTED && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <Typography variant="body-md-medium">
            There is no demographic information to display.
          </Typography>

          <Typography
            sx={{
              color: 'gray.06',
            }}
            variant="body-md-regular"
          >
            You can append demographic information to this document.
          </Typography>
          <Button
            onClick={() => {
              setAppendType('demographic');
              setShowAppendData(true);
            }}
            sx={{
              mt: 2,
            }}
            variant="contained"
          >
            Append Data
          </Button>
        </Box>
      )}

      {appendStatus === AppendDataStatus.NO_DATA && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <Typography variant="body-md-medium">There is no data you are looking for</Typography>
        </Box>
      )}

      {(appendStatus === AppendDataStatus.APPENDED ||
        appendStatus === AppendDataStatus.COMPLETED) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 3,
          }}
        >
          <Typography variant="body-md-medium">Demographic Insights</Typography>

          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'gray.04',
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              mb: 2,
              pb: 3,
            }}
          >
            {demographicProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>

          <Typography variant="body-md-medium">Financial, Household and Auto Insights</Typography>

          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'gray.04',
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              mb: 2,
              pb: 3,
            }}
          >
            {financialProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>

          <Typography variant="body-md-medium">Lifestyle Insights</Typography>

          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'gray.04',
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              mb: 2,
              pb: 3,
            }}
          >
            {lifestyleProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>

          <Typography variant="body-md-medium">Political and Donor Insights</Typography>

          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
            }}
          >
            {politicalProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad, setCurrentDataSource, setDataSources } from '@a-type/ui/stores/actions';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DATA_SOURCE_TABS,
  DATA_SOURCE_TABS_ENUM,
  DataSourceTypes,
} from './utils/data-source-details-props';

interface DataSourceDetailsButtonsProps {
  activeStep: number;
  currentStep: DataSourceTypes;
  isBasicInfoValid: boolean;
  isMappingValid: boolean;
  isPricingValid: boolean;
  setActiveStep: (value: number) => void;
  setStepsHistory: (value: DataSourceTypes[]) => void;
  stepsHistory: DataSourceTypes[];
}

const DataSourceDetailsButtons = (props: DataSourceDetailsButtonsProps) => {
  const {
    activeStep,
    currentStep,
    isBasicInfoValid,
    isMappingValid,
    isPricingValid,
    setActiveStep,
    setStepsHistory,
    stepsHistory,
  } = props;
  const { currentDataSource, uploadedCsvFile } = useSelector((state) => state.dataSource);
  const [isExist, setIsExist] = useState(false);
  const [nextStep, setNextStep] = useState<DataSourceTypes | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsExist(currentDataSource?._id !== '0');
  }, [currentDataSource]);

  useEffect(() => {
    if (DATA_SOURCE_TABS.length - 1 === activeStep) {
      setNextStep(null);
    } else {
      setNextStep(DATA_SOURCE_TABS[activeStep + 1]);
    }
  }, [activeStep]);

  const refreshAllDataSources = async () => {
    const response = await dataSourcesService.getAll();
    if (response?.status === 200) {
      dispatch(setDataSources([...response.data]));
    }

    dispatch(pageContentLoad(true));
  };

  const goToNextStep = async () => {
    if (!currentDataSource) return;

    if (nextStep) {
      setActiveStep(activeStep + 1);
      setStepsHistory([...stepsHistory, nextStep]);
    } else {
      dispatch(pageContentLoad(false));
      const newDataSource = currentDataSource._id === '0';

      const response = newDataSource
        ? await dataSourcesService.createFile(currentDataSource, uploadedCsvFile)
        : await dataSourcesService.updateFile(
            currentDataSource,
            uploadedCsvFile,
            currentDataSource.updateStrategy,
          );

      if (response?.status === 200) {
        dispatch(setCurrentDataSource({ ...response.data }));

        if (newDataSource) {
          navigate(`${AppRoutes.AdminDataSourcesPage}/${response.data._id}`);
        }
      }

      await refreshAllDataSources();
    }
  };

  const update = async () => {
    if (!currentDataSource) return;

    dispatch(pageContentLoad(false));

    switch (currentStep.keyName) {
      case DATA_SOURCE_TABS_ENUM.BASIC_DETAILS: {
        const responseUpdate = currentDataSource.isApi
          ? await dataSourcesService.update(currentDataSource)
          : await dataSourcesService.updateFile(
              currentDataSource,
              uploadedCsvFile,
              currentDataSource.updateStrategy,
            );

        if (responseUpdate?.status === 200) {
          dispatch(setCurrentDataSource({ ...responseUpdate.data }));
        }
        break;
      }
      case DATA_SOURCE_TABS_ENUM.MAPPING: {
        const responseUpdateMapping = await dataSourcesService.updateMapping(currentDataSource);
        if (responseUpdateMapping?.status === 200) {
          dispatch(setCurrentDataSource({ ...responseUpdateMapping.data }));
        }
        break;
      }
      case DATA_SOURCE_TABS_ENUM.PRICING: {
        const responseUpdatePricing = await dataSourcesService.updatePricing(currentDataSource);
        if (responseUpdatePricing?.status === 200) {
          dispatch(setCurrentDataSource({ ...responseUpdatePricing.data }));
        }
        break;
      }
      default:
        break;
    }

    await refreshAllDataSources();
  };

  const isNextValid = () => {
    if (currentDataSource?._id === '0') {
      return !(
        (stepsHistory.some((step) => step.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS) &&
          !isBasicInfoValid) ||
        (stepsHistory.some((step) => step.keyName === DATA_SOURCE_TABS_ENUM.MAPPING) &&
          !isMappingValid) ||
        (stepsHistory.some((step) => step.keyName === DATA_SOURCE_TABS_ENUM.PRICING) &&
          !isPricingValid)
      );
    }
    return (
      (currentStep.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS && isBasicInfoValid) ||
      (currentStep.keyName === DATA_SOURCE_TABS_ENUM.MAPPING && isMappingValid)
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'right',
        width: '100%',
      }}
    >
      {isExist ? (
        <Button
          disabled={
            (currentStep.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS && !isBasicInfoValid) ||
            (currentStep.keyName === DATA_SOURCE_TABS_ENUM.MAPPING && !isMappingValid) ||
            (currentStep.keyName === DATA_SOURCE_TABS_ENUM.PRICING && !isPricingValid)
          }
          onClick={async () => {
            await update();
          }}
          sx={{
            width: 250,
          }}
          variant="contained"
        >
          Update
        </Button>
      ) : null}

      {nextStep || !isExist ? (
        <Button
          disabled={!isNextValid()}
          onClick={() => {
            goToNextStep();
          }}
          sx={{
            width: 250,
          }}
          variant="contained"
        >
          {nextStep ? `Continue to ${nextStep.title}` : 'Save'}
        </Button>
      ) : null}
    </Box>
  );
};

export default DataSourceDetailsButtons;

import { FilterMode } from '@a-type/enums';
import { IDataSourceField, IFilterStringValue } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export interface SelectedValuesProps {
  field: IDataSourceField;
}

const SelectedValues = (props: SelectedValuesProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.ADD);
  const [selectedOptions, setSelectedOptions] = useState<IFilterStringValue[]>([]);

  useEffect(() => {
    if (!count?.filters?.[field.name]) return;

    setSelectedOptions(count.filters[field.name].values as IFilterStringValue[]);
    setFilterMode(count.filters[field.name].mode);
  }, [count]);

  const handleRemoveOption = (option: IFilterStringValue) => {
    if (!count?.filters) return;

    const values = count.filters[field.name].values.filter((value) => value.label !== option.label);

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values,
          },
        },
      }),
    );
  };

  return (
    <List
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        p: 0,
        position: 'relative',
        width: '100%',
      }}
    >
      {selectedOptions.length > 0 &&
        selectedOptions.map((option) => (
          <ListItem
            key={option.label}
            sx={{
              ':hover': {
                '.remove-filter': {
                  display: 'flex',
                },
                backgroundColor: globalStyles.mainColors.distantWindChimeColor,
                borderRadius: '8px',
              },
              pr: 1,
              py: 0,
            }}
          >
            {filterMode === 'add' && (
              <CheckCircleOutlineIcon
                sx={{
                  color: globalStyles.mainColors.blackColor,
                  fontSize: '18px',
                }}
              />
            )}
            {filterMode === 'omit' && (
              <NotInterestedIcon
                sx={{
                  color: globalStyles.mainColors.blackColor,
                  fontSize: '18px',
                  rotate: '90deg',
                }}
              />
            )}
            <ListItemText
              sx={{
                color: globalStyles.mainColors.blackColor,
                my: '2px',
                px: '12px',
                py: '2px',
              }}
            >
              <Typography component="span">{option.label}</Typography>
            </ListItemText>
            <IconButton
              className="remove-filter"
              onClick={() => handleRemoveOption(option)}
              sx={{ display: 'none', m: 0, p: 0.5 }}
            >
              <CancelIcon
                sx={{
                  color: globalStyles.mainColors.grey74Color,
                  fontSize: '18px',
                }}
              />
            </IconButton>
          </ListItem>
        ))}
    </List>
  );
};

export default SelectedValues;

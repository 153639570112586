import { ITopUpCredits, IUserInfo } from '@a-type/dtos';
import { Dialog, FormInput } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useTopUpCreditMutation } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box, FormGroup, InputLabel } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface TopUpBalanceProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const TopUpBalance: FC<TopUpBalanceProps> = ({ setShow, show, user }: TopUpBalanceProps) => {
  const dispatch = useDispatch();
  const [topUpBalance, { isLoading }] = useTopUpCreditMutation();

  const { control, watch } = useForm<ITopUpCredits>({
    defaultValues: {
      credits: 0,
      description: '',
    },
  });

  const formData = watch();

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  const handleTopUpBalance = async () => {
    const result = await topUpBalance({
      data: {
        ...formData,
        credits: Number(formData.credits),
      },
      id: user._id,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('User balance topped up successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while topping up balance'));
    }

    setShow(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Confirm"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleTopUpBalance}
      open={show}
      title={`Top Up Balance for ${user.fullName}`}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <FormGroup>
          <InputLabel htmlFor="description">Transaction Name</InputLabel>
          <FormInput
            control={control}
            maxLength={100}
            name="description"
            placeholder="Transaction Name (Optional)"
          />
        </FormGroup>

        <FormGroup>
          <InputLabel htmlFor="credits">Credits Amount</InputLabel>
          <FormInput
            control={control}
            max={1000000}
            min={0}
            name="credits"
            required
            step={1}
            type="number"
          />
        </FormGroup>
      </Box>
    </Dialog>
  );
};

import { IFieldMetadata } from '@a-type/interfaces';
import { ActionButton, GridPagination } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetBucketDocumentsQuery, useGetBucketQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridRowId } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ListDocumentsEmptyComponent from '../lists/components/list-documents-empty.component';
import { ListRowsAndColumnsBuilder } from '../lists/utils/list-rows-and-columns-builder.utils';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;
const FIELDS_TO_DISPLAY = [
  'PartyOwner1NameFull',
  'PropertyAddressFull',
  'PropertyAddressCity',
  'PropertyAddressState',
  'PropertyAddressZIP',
  'ContactOwnerMailingCounty',
  'PropertyUseGroup',
  'YearBuilt',
  'BedroomsCount',
  'BathCount',
  'AreaGross',
  'LotSize',
  'PropertyAddressCRRT',
];

export const BucketPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [builder, setBuilder] = useState<ListRowsAndColumnsBuilder | undefined>();
  const [documents, setDocuments] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [selected, setSelected] = useState<GridRowId[]>([]);
  const { data: bucket, isLoading: isBucketLoading } = useGetBucketQuery();
  const { data: bucketDocuments, isLoading: isBucketDocumentsLoading } = useGetBucketDocumentsQuery(
    {
      limit: pagination.limit,
      page: pagination.page,
    },
    {
      skip: !bucket,
    },
  );

  useEffect(() => {
    dispatch(pageContentLoad(!isBucketLoading && !isBucketDocumentsLoading));
  }, [isBucketLoading, isBucketDocumentsLoading]);

  useEffect(() => {
    if (!bucket?.fieldMetadata) return;

    const fields = FIELDS_TO_DISPLAY.reduce(
      (acc, field, index) => {
        if (bucket.fieldMetadata![field]) {
          acc[field] = {
            ...bucket.fieldMetadata![field],
            sortOrder: index,
          };
        }

        return acc;
      },
      {} as {
        [key: string]: IFieldMetadata;
      },
    );

    const b = new ListRowsAndColumnsBuilder(fields);
    setBuilder(b);
    setColumns(b.buildColumns());
  }, [bucket]);

  useEffect(() => {
    if (!bucketDocuments) return;

    setDocuments(bucketDocuments.items);
    setCount(bucketDocuments.count);
    setPages(bucketDocuments.pages);
  }, [bucketDocuments]);

  useEffect(() => {
    if (!builder) return;
    if (!documents?.length) return;

    setRows(builder.buildRows(documents));
  }, [documents]);

  const onChangePagination = (page: number, limit: number) => {
    if (page < 0) {
      return;
    }

    if (pagination.page === page && pagination.limit === limit) return;

    setPagination({
      limit: limit || DEFAULT_LIMIT,
      page: page || DEFAULT_PAGE,
    });
  };

  const paginator = () => {
    return (
      <GridPagination
        count={count}
        limit={pagination.limit}
        onChangePagination={(page, limit) => {
          setPagination({ limit, page });
        }}
        page={pagination.page}
        pages={pages}
      />
    );
  };

  const downloadCSVData = async () => {
    if (!bucket) return;

    dispatch(pageContentLoad(false));
    const name = `Bucket_${new Date().toLocaleDateString()}`;
    await listService.exportFromBucket(
      name,
      selected.length ? selected.map((x) => x.toString()) : undefined,
    );
    dispatch(pageContentLoad(true));
  };

  return (
    <PageLayout container wide>
      <Box
        sx={{
          borderBottom: `1px solid`,
          borderColor: 'gray.02',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'space-between',
          pb: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'baseline',
            display: 'flex',
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 700,
            }}
          >
            Bucket
          </Typography>

          {selected.length > 0 && (
            <Typography
              component="span"
              sx={{
                color: 'blue.05',
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1,
              }}
            >
              {selected.length.toLocaleString()} selected
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          <ActionButton
            icon={<FileDownloadOutlined />}
            label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
            onClick={downloadCSVData}
          />
        </Box>
      </Box>

      {!rows.length ? (
        <ListDocumentsEmptyComponent />
      ) : (
        <Box sx={{ mt: 0.5 }}>
          <DataGrid
            checkboxSelection
            columns={columns}
            disableRowSelectionOnClick
            hideFooterPagination={pages === 0}
            onPaginationModelChange={(model: GridPaginationModel) => {
              onChangePagination(pagination.page, model.pageSize);
            }}
            onRowClick={(row) => {
              if (bucket?.isTaxAssessor === true) {
                navigate(`${AppRoutes.Bucket}/tax-assessor/${row.id}`);
              }
            }}
            onRowSelectionModelChange={(params) => {
              setSelected([...params]);
            }}
            rowHeight={40}
            rows={rows}
            slots={{
              pagination: paginator,
            }}
            sx={{
              height: 'calc(100vh - 260px)',
            }}
          />
        </Box>
      )}
    </PageLayout>
  );
};

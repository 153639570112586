import { ListTaskStatus } from '@a-type/enums';
import { useDispatch, useQuery, useRefreshUntil } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetDocumentsQuery, useGetListQuery } from '@a-type/ui/stores/apis';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GridPagination } from '../../components';
import ListDocumentsEmptyComponent from './components/list-documents-empty.component';
import ListDocumentsHeaderComponent from './components/list-documents-header.component';
import { ListDocumentsOnlineAudienceComponent } from './components/list-documents-online-audience.component';
import { ListRowsAndColumnsBuilder } from './utils/list-rows-and-columns-builder.utils';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;

const ListDocumentsComponent = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listId } = useParams<{ listId: string }>();
  const [builder, setBuilder] = useState<ListRowsAndColumnsBuilder | undefined>();
  const [documents, setDocuments] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [buyingDate, setBuyingDate] = useState<string>('all');
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [activeTab, setActiveTab] = useState(query.get('tab') ?? 'data');
  const {
    data: list,
    isLoading: isListLoading,
    isUninitialized: isListUninitialized,
    refetch: refetchList,
  } = useGetListQuery(listId!, {
    skip: !listId,
  });
  const {
    data: documentsData,
    isFetching: isDocumentsLoading,
    refetch: refetchDocuments,
  } = useGetDocumentsQuery(
    {
      buyingDate: buyingDate === 'all' ? undefined : buyingDate,
      id: listId!,
      limit: pagination.limit,
      page: pagination.page,
    },
    {
      skip: !builder && !list,
    },
  );

  useRefreshUntil(
    () => {
      if (!list) return;

      if (!isListUninitialized) refetchList();
    },
    () => {
      return !(
        list?.tasks?.some(
          (task) =>
            task.status === ListTaskStatus.IN_PROGRESS || task.status === ListTaskStatus.PENDING,
        ) ?? false
      );
    },
    [list],
  );

  useEffect(() => {
    dispatch(pageContentLoad(!isListLoading && !isDocumentsLoading));
  }, [isListLoading, isDocumentsLoading]);

  useEffect(() => {
    if (query.get('tab')) {
      switch (query.get('tab')) {
        case 'data':
          setActiveTab('data');
          break;
        case 'online-audience':
          setActiveTab('online-audience');
          break;
        default:
          setActiveTab('data');
          break;
      }
    }
  }, [query]);

  useEffect(() => {
    if (!list?.fieldMetadata) return;
    const b = new ListRowsAndColumnsBuilder(list.fieldMetadata);
    setBuilder(b);
    setColumns(b.buildColumns());

    if (list?.tasks?.every((task) => task.status === ListTaskStatus.DONE) ?? true)
      refetchDocuments();
  }, [list]);

  useEffect(() => {
    if (!documentsData) return;

    setDocuments(documentsData.items);
    setCount(documentsData.count);
    setPages(documentsData.pages);
  }, [documentsData]);

  useEffect(() => {
    if (!builder) return;
    if (!documents?.length) return;

    setRows(builder.buildRows(documents));
  }, [documents]);

  const onChangePagination = (page: number, limit: number) => {
    if (page < 0) {
      return;
    }

    if (pagination.page === page && pagination.limit === limit) return;

    setPagination({
      limit: limit || DEFAULT_LIMIT,
      page: page || DEFAULT_PAGE,
    });
  };

  const paginator = () => {
    return (
      <GridPagination
        count={count}
        limit={pagination.limit}
        onChangePagination={(page, limit) => {
          setPagination({ limit, page });
        }}
        page={pagination.page}
        pages={pages}
      />
    );
  };

  return (
    <>
      {list && (
        <ListDocumentsHeaderComponent
          buyingDate={buyingDate}
          list={list}
          setBuyingDate={setBuyingDate}
          totalCount={count}
        />
      )}

      {activeTab === 'online-audience' && list && (
        <ListDocumentsOnlineAudienceComponent list={list} />
      )}

      {activeTab === 'data' &&
        (!rows?.length ? (
          <ListDocumentsEmptyComponent />
        ) : (
          <Box sx={{ mt: 0.5 }}>
            <DataGrid
              columns={columns}
              disableRowSelectionOnClick
              hideFooterPagination={pages === 0}
              onPaginationModelChange={(model: GridPaginationModel) => {
                onChangePagination(pagination.page, model.pageSize);
              }}
              onRowClick={(row) => {
                if (list?.isTaxAssessor === true) {
                  navigate(`${AppRoutes.Lists}/${list._id}/tax-assessor/${row.id}`);
                }
              }}
              rowHeight={40}
              rows={rows}
              slots={{
                pagination: paginator,
              }}
              sx={{
                height: 'calc(100vh - 320px)',
              }}
            />
          </Box>
        ))}
    </>
  );
};

export default ListDocumentsComponent;

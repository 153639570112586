import { FieldsHelper } from '@a-type/helpers';
import { IDocument, IList } from '@a-type/interfaces';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from './tax-assessors-document-field.component';

interface ITaxAssessorsDocumentValueProps {
  document: IDocument;
  list: IList;
}

const TaxAssessorsValueFields = [
  TaxAssessorsDocumentFields.AssessorLastSaleAmount,
  TaxAssessorsDocumentFields.PreviousAssessedValue,
  TaxAssessorsDocumentFields.TaxMarketValueTotal,
  TaxAssessorsDocumentFields.TaxAssessedValueTotal,
  TaxAssessorsDocumentFields.AssessorPriorSaleAmount,
];

export const TaxAssessorsDocumentValue: FC<ITaxAssessorsDocumentValueProps> = ({
  document,
  list,
}: ITaxAssessorsDocumentValueProps) => {
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setProperties(
      TaxAssessorsValueFields.map((field) => getPropertyInfo(document, field, metadata)).filter(
        (field) => field !== undefined,
      ),
    );
  }, [document, list]);

  const delta = useMemo(() => {
    const assessedValue = FieldsHelper.accessValue(
      TaxAssessorsDocumentFields.AssessorLastSaleAmount,
      document.data,
    );
    const priorAssessedValue = FieldsHelper.accessValue(
      TaxAssessorsDocumentFields.PreviousAssessedValue,
      document.data,
    );

    if (!assessedValue || !priorAssessedValue) return undefined;

    const value = +assessedValue - +priorAssessedValue;
    if (value === 0) return undefined;

    if (value > 0) return `+$${value.toLocaleString()}`;

    return `-$${Math.abs(value).toLocaleString()}`;
  }, [document]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.04',
        borderRadius: '16px',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 2,
        minWidth: 300,
        p: 2,
      }}
    >
      <Typography
        sx={{
          alignItems: 'baseline',
          display: 'flex',
        }}
        variant="body-lg-bold"
      >
        Value &nbsp;
        {delta && (
          <Typography color="blue.06" variant="body-sm-bold">
            {delta}
          </Typography>
        )}
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        }}
      >
        {properties.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>
    </Box>
  );
};

import { IStripeActiveSubscription } from '@a-type/dtos';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Tooltip } from '@mui/material';

interface ISubscriptionStatusProps {
  subscription: IStripeActiveSubscription;
}

export const SubscriptionStatus: React.FC<ISubscriptionStatusProps> = ({
  subscription,
}: ISubscriptionStatusProps) => {
  return (
    <>
      {subscription && subscription.status === 'active' && (
        <>
          <Chip
            label="Active"
            size="small"
            sx={{
              backgroundColor: 'green.07',
              color: 'white',
              fontSize: 13,
              fontWeight: 500,
            }}
          />

          {subscription.cancelAtPeriodEnd && (
            <Chip
              label={`Will Cancel on ${new Date(subscription.currentPeriodEnd).toLocaleDateString()}`}
              size="small"
              sx={{
                background: 'orange.05',
                color: 'white',
                fontSize: 13,
                fontWeight: 500,
              }}
            />
          )}
        </>
      )}

      {subscription && subscription.status === 'cancelled' && (
        <Chip
          label="Cancelled"
          size="small"
          sx={{
            background: 'red.05',
            color: 'white',
            fontSize: 13,
            fontWeight: 500,
          }}
        />
      )}

      {subscription && subscription.status === 'past_due' && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <Chip
            label={subscription.pendingRetry ? 'Pending Retry' : 'Payment Failed'}
            size="small"
            sx={{
              backgroundColor: subscription.pendingRetry ? 'orange.05' : 'red.05',
              color: 'white',
              fontSize: 13,
              fontWeight: 500,
            }}
          />

          {subscription.error && (
            <Tooltip placement="top" title={subscription.error}>
              <InfoOutlined
                fontSize="small"
                sx={{
                  color: 'gray.05',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};

import { ITransaction } from '@a-type/interfaces';

import ActivityTableHeaderComponent from './activity/activity-table-header.component';
import ActivityTableItemComponent from './activity/activity-table-item';

export interface DashboardUserTransactionProps {
  transactions: ITransaction[];
}

const DashboardUserTransaction = (props: DashboardUserTransactionProps) => {
  const { transactions } = props;

  return (
    <>
      <ActivityTableHeaderComponent />
      {transactions.map((transaction: ITransaction) => {
        return <ActivityTableItemComponent key={transaction._id} transaction={transaction} />;
      })}
    </>
  );
};

export default DashboardUserTransaction;

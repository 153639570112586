import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetActiveSubscriptionQuery,
  useGetUserBalanceQuery,
  useGetUserDataSourcesQuery,
  useGetUserTransactionsQuery,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardDatabaseCard from './dashboard-database-card.component';
import DashboardInsight from './dashboard-insight.component';
import DashboardUserTransaction from './dashboard-user-transaction.component';

const DashboardComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: transactions = {
      items: [],
    },
  } = useGetUserTransactionsQuery({
    limit: 5,
    page: 0,
  });
  const { isLogin } = useSelector((state) => state.auth);
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const { data: dataSources = [], isLoading: isDataSourcesLoading } = useGetUserDataSourcesQuery();
  const { data: subscription, isLoading: isSubscriptionLoading } = useGetActiveSubscriptionQuery(
    undefined,
    {
      skip: !isLogin,
    },
  );

  useEffect(() => {
    dispatch(pageContentLoad(!isDataSourcesLoading));
  }, [isDataSourcesLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      {!isSubscriptionLoading &&
        (!subscription || subscription.cancelAtPeriodEnd) &&
        creditsBalance > 0 && (
          <Alert
            action={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'center',
                  pr: 3,
                }}
              >
                <Button
                  onClick={() => {
                    navigate(`${AppRoutes.SubscribePage}`);
                  }}
                  size="small"
                  variant="contained"
                >
                  Select Plan
                </Button>
              </Box>
            }
            severity="warning"
          >
            <AlertTitle>No Active Subscription</AlertTitle>
            You need to have an active subscription to buy more credits. Please subscribe to a plan
            to continue using the platform.
          </Alert>
        )}

      <DashboardInsight />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: '24px',
              fontWeight: '900',
              m: 0,
            }}
          >
            Databases
          </Typography>

          {dataSources.length > 8 && (
            <Button
              color="info"
              endIcon={<ChevronRightSharpIcon />}
              onClick={() => {
                navigate(`${AppRoutes.DataSourcesPage}`);
              }}
              size="small"
              sx={{
                textTransform: 'uppercase',
              }}
            >
              View All Databases
            </Button>
          )}
        </Box>

        {dataSources.length > 0 && (
          <Box
            sx={{
              display: 'grid',
              gap: 4,
              gridTemplateColumns: 'repeat(auto-fill, minmax(287px, 1fr))',
            }}
          >
            {dataSources.length > 0 && // Show 8 data sources
              dataSources
                .slice(0, 8)
                .map((dataSource) => (
                  <DashboardDatabaseCard dataSource={dataSource} key={dataSource._id} />
                ))}
          </Box>
        )}

        {dataSources.length === 0 && !isDataSourcesLoading && (
          <Box
            sx={{
              alignItems: 'center',
              background: globalStyles.mainColors.whiteColor,
              borderRadius: 2,
              display: 'flex',
              height: '400px',
              justifyContent: 'center',
              px: 4,
              py: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: '400',
              }}
            >
              You don&apos;t have access to any databases yet. Please contact administator to get
              access.
            </Typography>
          </Box>
        )}
      </Box>

      {transactions && transactions.items.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '24px',
                fontWeight: '900',
                m: 0,
              }}
            >
              Recent Activity
            </Typography>

            <Button
              color="info"
              endIcon={<ChevronRightSharpIcon />}
              onClick={() => {
                navigate(`${AppRoutes.Activity}`);
              }}
              size="small"
              sx={{
                textTransform: 'uppercase',
              }}
            >
              View All Activity
            </Button>
          </Box>

          <Box
            sx={{
              background: globalStyles.mainColors.whiteColor,
              borderRadius: 2,
              height: 'auto',
              px: 4,
              py: 1,
            }}
          >
            <DashboardUserTransaction transactions={transactions.items} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardComponent;

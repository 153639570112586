import { FieldsHelper } from '@a-type/helpers';
import { IDocument, IFieldMetadata } from '@a-type/interfaces';

export const getPropertyInfo = (
  document: IDocument,
  field: string,
  metadata: {
    [key: string]: IFieldMetadata;
  },
  icon?: JSX.Element,
) => {
  if (!metadata[field]) return undefined;

  const value = FieldsHelper.accessValue(field, document.data);
  return {
    icon,
    key: field,
    label: metadata[field]?.displayName ?? field,
    value: value ? FieldsHelper.getValue(value, metadata[field], false, true) : '-',
  };
};

import { IDocument, IList } from '@a-type/interfaces';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from '../tax-assessors-document-info/tax-assessors-document-field.component';

interface ITaxAssessorsDocumentTaxTabProps {
  document: IDocument;
  list: IList;
}

export const TaxAssessorsDocumentTaxTab = ({
  document,
  list,
}: ITaxAssessorsDocumentTaxTabProps) => {
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setProperties(
      [
        TaxAssessorsDocumentFields.ParcelNumberRaw,
        TaxAssessorsDocumentFields.SitusCounty,
        TaxAssessorsDocumentFields.PartyOwner1NameFull,
        TaxAssessorsDocumentFields.ContactOwnerMailAddressFull,
        TaxAssessorsDocumentFields.TaxYearAssessed,
        TaxAssessorsDocumentFields.TaxBilledAmount,
        TaxAssessorsDocumentFields.TaxAssessedValueTotal,
        TaxAssessorsDocumentFields.TaxMarketValueLand,
        TaxAssessorsDocumentFields.TaxMarketValueImprovements,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );
  }, [document, list]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        pt: 3,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        }}
      >
        {properties.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>
    </Box>
  );
};

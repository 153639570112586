import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { ICombinedSearch, ICombinedSearchFilter, IDataSource } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useComponentsQuery,
  useGetDataSourcesQuery,
  useGetFilterGroupsQuery,
  useUpdateCombinedSearchFiltersMutation,
} from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { CancelOutlined } from '@mui/icons-material';
import { Box, Button, ButtonGroup, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { styles } from '../../counts/components/filters/inputs/selected-values-control.component';
import { CombinedSearchSteps } from './combined-search-steps.enum';
import { CombinedSearchAddFilter } from './components/combined-search-add-filter.coomponent';
import { CombinedSearchFilter } from './components/combined-search-filter.component';

interface CombinedSearchFiltersProps {
  combinedSearch: ICombinedSearch;
  setActiveStep: (step: string) => void;
}

export const CombinedSearchFilters: React.FC<CombinedSearchFiltersProps> = ({
  combinedSearch,
  setActiveStep,
}: CombinedSearchFiltersProps) => {
  const dispatch = useDispatch();
  const { data: filtersComponents } = useComponentsQuery();
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const { data: dataSourcesData, isFetching: isFetchingDataSources } = useGetDataSourcesQuery();
  const [updateFilters, { isLoading: isUpdatingFilters }] =
    useUpdateCombinedSearchFiltersMutation();
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);
  const [dataSourcesMap, setDataSourcesMap] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<ICombinedSearchFilter[]>([]);
  const [filteredFilters, setFilteredFilters] = useState<ICombinedSearchFilter[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<null | string>(null);

  const components = (filtersComponents || []).reduce(
    (acc: any, item: IDataSourceFilterComponentDto) => {
      return {
        ...acc,
        [item.type]: item.name,
      };
    },
    {},
  );

  const groups = (filtersGroups || []).reduce((acc: any, item) => {
    return {
      ...acc,
      [item.code]: item.name,
    };
  }, {});

  useEffect(() => {
    dispatch(pageContentLoad(!isFetchingDataSources && !isUpdatingFilters));
  }, [isFetchingDataSources, isUpdatingFilters]);

  useEffect(() => {
    if (dataSourcesData && combinedSearch) {
      setDataSources([
        // primary data source
        ...dataSourcesData
          .filter((ds) => ds._id === combinedSearch.primaryDataSourceId)
          .filter(Boolean),
        // secondary data sources
        ...combinedSearch.secondaryDataSources
          .map((sds) => dataSourcesData.find((ds) => ds._id === sds.dataSourceId))
          .filter((ds) => ds)
          .map((ds) => ds as IDataSource),
      ]);
    }
  }, [dataSourcesData, combinedSearch]);

  useEffect(() => {
    setFilters([...combinedSearch.filters]);
  }, [combinedSearch]);

  useEffect(() => {
    const map: { [key: string]: string } = {};
    dataSources.forEach((ds) => {
      map[ds._id] = ds.name;
    });

    setDataSourcesMap(map);
  }, [dataSources]);

  useEffect(() => {
    const filteredFields = filters.filter((field) => {
      if (searchText) {
        return field.name.toLowerCase().includes(searchText.toLowerCase());
      }
      return true;
    });

    setFilteredFilters(filteredFields);
  }, [filters, searchText]);

  const handleAddFilter = (field: ICombinedSearchFilter) => {
    setFilters([...filters, field]);
    setSelectedFilter(field._id);
  };

  const handleDeleteFilter = (id: string) => {
    setFilters(filters.filter((filter) => filter._id !== id));
  };

  const handleUpdateFilter = (id: string, key: string, value: any) => {
    const newFilters = filters.map((filter) => {
      if (filter._id === id) {
        return {
          ...filter,
          [key]: value,
        };
      }
      return filter;
    });

    setFilters(newFilters);
  };

  const handleUpdate = async () => {
    const result = await updateFilters({
      data: {
        filters,
      },
      type: combinedSearch.type,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('Combined search updated successfully'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(getError(result.error) ?? 'Error while updating combined search'),
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <ButtonGroup
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '400px',
            width: '100%',
          }}
        >
          <TextField
            label="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search"
            size="small"
            sx={{
              '.MuiOutlinedInput-root': {
                borderEndEndRadius: 0,
                borderStartEndRadius: 0,
              },
              flexGrow: 1,
            }}
            value={searchText}
            variant="outlined"
          />
          <Button
            onClick={() => setSearchText('')}
            size="small"
            startIcon={<CancelOutlined />}
            sx={{
              ...styles.filterButton,
              borderEndStartRadius: 0,
              borderStartStartRadius: 0,
            }}
            variant="outlined"
          >
            Clear
          </Button>
        </ButtonGroup>

        <CombinedSearchAddFilter
          dataSources={dataSources}
          filters={filters}
          onAddFilter={handleAddFilter}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <DragAndDrop
          getKey={(filter) => filter._id}
          items={filteredFilters}
          renderItem={(filter, index, p, s) => (
            <CombinedSearchFilter
              components={components}
              dataSources={dataSourcesMap}
              draggableProps={p.draggableProps}
              dragHandleProps={p.dragHandleProps}
              dragRef={p.innerRef}
              filter={filter}
              groups={groups}
              isDragDisabled={searchText !== ''}
              isDragging={s.isDragging}
              key={filter._id}
              onFilterChange={(key, value) => handleUpdateFilter(filter._id, key, value)}
              onFilterDelete={() => handleDeleteFilter(filter._id)}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          )}
          setItems={(updatedFilters) => {
            setFilters([...updatedFilters]);
            setFilteredFilters([...updatedFilters]);
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button color="primary" onClick={handleUpdate} sx={{ minWidth: 120 }} variant="contained">
          Update
        </Button>
        <Button
          color="primary"
          onClick={() => setActiveStep(CombinedSearchSteps.Base)}
          variant="outlined"
        >
          Back to Basic Information
        </Button>
        <Button
          color="primary"
          onClick={() => setActiveStep(CombinedSearchSteps.Status)}
          variant="outlined"
        >
          Continue to Status
        </Button>
      </Box>
    </Box>
  );
};

import { DataSourceFieldDataType, TargetType } from '@a-type/enums';
import { IFieldMetadata, IList } from '@a-type/interfaces';
import { useSelector } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ListSettings } from '../components';
import { UploadListConfirmationDialog } from './components/upload-list-confiramtion-dialog.component';

export const UploadListPage = () => {
  const navigate = useNavigate();
  const { uploadedCsvFile, uploadedCsvFileData } = useSelector((state) => state.list);
  const [list, setList] = useState<IList>();
  const [isFirstRowHeader, setIsFirstRowHeader] = useState(true);
  const [ignoreFields, setIgnoreFields] = useState<{ [key: string]: boolean }>({});
  const [canUpload, setCanUpload] = useState(false);
  const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);

  // check if all required fields are filled
  useEffect(() => {
    if (
      list &&
      list.name &&
      list.fieldMetadata &&
      Object.keys(list.fieldMetadata).length > 0 &&
      !Object.values(ignoreFields).every((ignore) => ignore)
    ) {
      setCanUpload(true);
    } else {
      setCanUpload(false);
    }
  }, [list, ignoreFields]);

  // updating field value in list
  const updateFieldValueHandler = (key: string, field: string, value: any) => {
    setList({
      ...list,
      fieldMetadata: {
        ...(list?.fieldMetadata || {}),
        [field]: {
          ...((list?.fieldMetadata && list.fieldMetadata[field]) || {}),
          [key]: value,
        } as IFieldMetadata,
      },
    } as IList);
  };

  // initial check for uploaded file
  useEffect(() => {
    // if no file uploaded, redirect to lists page
    if (!uploadedCsvFile || !uploadedCsvFileData) {
      navigate(`${AppRoutes.Lists}`);
      return;
    }

    // create list from uploaded file
    if (!list) {
      setList({
        avilableRecords: 0,
        fieldMetadata: uploadedCsvFileData.headers.reduce<{
          [key: string]: IFieldMetadata;
        }>((acc, header, index) => {
          acc[header] = {
            dataType: DataSourceFieldDataType.STRING,
            displayName: header,
            name: header,
            sortOrder: index + 1,
          } as IFieldMetadata;
          return acc;
        }, {}),
        filters: {},
        name: uploadedCsvFile.name.replace('.csv', ''),
        pricingPlanPrice: 0,
        targetType: TargetType.CUSTOMER,
        totalPrice: 0,
        totalRecords: uploadedCsvFileData.numberOfRow,
      } as IList);

      setIgnoreFields(
        uploadedCsvFileData.headers.reduce<{ [key: string]: boolean }>((acc, header) => {
          acc[header] = false;
          return acc;
        }, {}),
      );
    }
  });

  return (
    <PageLayout container wide>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton onClick={() => navigate(`${AppRoutes.Lists}`)}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              Upload List
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                navigate(`${AppRoutes.Lists}`);
              }}
              sx={{
                width: 160,
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={!canUpload}
              onClick={() => setShowUploadConfirmation(true)}
              sx={{
                width: 160,
              }}
              variant="contained"
            >
              Upload Data
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 4,
          }}
        >
          <FormControl
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <FormLabel
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              List Name
            </FormLabel>
            <TextField
              error={!list?.name}
              onChange={(e) => {
                setList({ ...list, name: e.target.value || '' } as IList);
              }}
              value={list?.name || ''}
            />
          </FormControl>

          <FormControl
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <FormLabel
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              Type of the Data
            </FormLabel>
            <Select
              onChange={(e) => {
                setList({ ...list, targetType: e.target.value as TargetType } as IList);
              }}
              value={list?.targetType || TargetType.CUSTOMER}
            >
              <MenuItem key={TargetType.CUSTOMER} value={TargetType.CUSTOMER}>
                Resedential
              </MenuItem>
              <MenuItem key={TargetType.BUSINESS} value={TargetType.BUSINESS}>
                Business
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <FormLabel
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
                textWrap: 'nowrap',
              }}
            >
              Is First row Header?
            </FormLabel>
            <Select
              onChange={(e) => {
                setIsFirstRowHeader(e.target.value as boolean);
              }}
              value={isFirstRowHeader}
            >
              <MenuItem key="true" value="true">
                Yes
              </MenuItem>
              <MenuItem key="false" value="false">
                No
              </MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              # of Rows
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                color: globalStyles.mainColors.sootyColor,
                display: 'flex',
                flexGrow: 1,
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              {list?._id ? (
                list.totalRecords?.toLocaleString()
              ) : (
                <Tooltip
                  placement="top"
                  title="We will display the number of rows after the file is uploaded"
                >
                  <HelpOutlineIcon />
                </Tooltip>
              )}
            </Typography>
          </Box>
        </Box>

        {list && uploadedCsvFileData && (
          <ListSettings
            examples={uploadedCsvFileData.examples}
            ignoreFields={ignoreFields}
            list={list}
            setIgnoreFields={setIgnoreFields}
            updateFieldValueHandler={updateFieldValueHandler}
          />
        )}

        {canUpload && showUploadConfirmation && (
          <UploadListConfirmationDialog
            ignoreFields={ignoreFields}
            isFirstRowHeader={isFirstRowHeader}
            list={list}
            setShow={setShowUploadConfirmation}
            show={showUploadConfirmation}
            uploadedCsvFile={uploadedCsvFile}
          />
        )}
      </Box>
    </PageLayout>
  );
};

import { FieldsHelper } from '@a-type/helpers';
import { IDocument, IFieldMetadata, IList } from '@a-type/interfaces';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from '../tax-assessors-document-info/tax-assessors-document-field.component';

interface ITaxAssessorsDocumentPropertyTabProps {
  document: IDocument;
  list: IList;
}

const getRecorderProp = (
  recorder: any,
  metadata: { [key: string]: IFieldMetadata },
  field: string,
) => {
  if (!metadata[field]) return undefined;

  const key = field.replace('recorders.', '');
  const value = FieldsHelper.accessValue(key, recorder);
  return {
    key: field,
    label: metadata[field]?.displayName ?? key,
    value: value ? FieldsHelper.getValue(value, metadata[field], false, true) : '-',
  };
};

export const TaxAssessorsDocumentPropertyTab = ({
  document,
  list,
}: ITaxAssessorsDocumentPropertyTabProps) => {
  const [ownershipProps, setOwnershipProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [propertyProps, setPropertyProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [buildingProps, setBuildingProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [landProps, setLandProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [assessmentProps, setAssessmentProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [lastArmsLengthSaleProps, setLastArmsLengthSaleProps] = useState<
    ITaxAssessorsDocumentField[]
  >([]);

  const [showRecording, setShowRecording] = useState(false);
  const [recordingProps, setRecordingProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [buyerSellerProps, setBuyerSellerProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [mortgageProps, setMortgageProps] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setOwnershipProps(
      [
        TaxAssessorsDocumentFields.PartyOwner1NameFull,
        TaxAssessorsDocumentFields.ContactOwnerMailAddressFull,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setPropertyProps(
      [
        TaxAssessorsDocumentFields.RoomsCount,
        TaxAssessorsDocumentFields.BedroomsCount,
        TaxAssessorsDocumentFields.BathCount,
        TaxAssessorsDocumentFields.BathPartialCount,
        TaxAssessorsDocumentFields.HVACCoolingDetail,
        TaxAssessorsDocumentFields.HVACHeatingDetail,
        TaxAssessorsDocumentFields.Construction,
        TaxAssessorsDocumentFields.Exterior1Code,
        TaxAssessorsDocumentFields.RoofMaterial,
        TaxAssessorsDocumentFields.BuildingsCount,
        TaxAssessorsDocumentFields.StoriesCount,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setBuildingProps(
      [
        TaxAssessorsDocumentFields.AreaBuilding,
        TaxAssessorsDocumentFields.Area1stFloor,
        TaxAssessorsDocumentFields.Area2ndFloor,
        TaxAssessorsDocumentFields.AreaGross,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setLandProps(
      [
        TaxAssessorsDocumentFields.ParcelNumberRaw,
        TaxAssessorsDocumentFields.PropertyUseGroup,
        TaxAssessorsDocumentFields.ZonedCodeLocal,
        TaxAssessorsDocumentFields.CensusTract,
        TaxAssessorsDocumentFields.AreaLotWidth,
        TaxAssessorsDocumentFields.AreaLotAcres,
        TaxAssessorsDocumentFields.AreaLotSF,
        TaxAssessorsDocumentFields.CongressionalDistrictHouse,
        TaxAssessorsDocumentFields.LegalSubdivision,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setAssessmentProps(
      [
        TaxAssessorsDocumentFields.TaxYearAssessed,
        TaxAssessorsDocumentFields.TaxAssessedValueTotal,
        TaxAssessorsDocumentFields.TaxAssessedValueLand,
        TaxAssessorsDocumentFields.TaxAssessedValueImprovements,
        TaxAssessorsDocumentFields.TaxAssessedImprovementsPerc,
        TaxAssessorsDocumentFields.TaxMarketValueYear,
        TaxAssessorsDocumentFields.TaxMarketValueTotal,
        TaxAssessorsDocumentFields.TaxMarketValueLand,
        TaxAssessorsDocumentFields.TaxMarketValueImprovements,
        TaxAssessorsDocumentFields.TaxMarketImprovementsPerc,
        TaxAssessorsDocumentFields.TaxBilledAmount,
        TaxAssessorsDocumentFields.AssessorLastSaleDate,
        TaxAssessorsDocumentFields.AssessorLastSaleAmount,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setLastArmsLengthSaleProps(
      [
        TaxAssessorsDocumentFields.AssessorLastSaleDate,
        TaxAssessorsDocumentFields.AssessorLastSaleAmount,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    const recorders = (document.data.recorders ?? [])
      .map((record: any) => ({
        date: new Date(record.RecordingDate),
        recorder: record,
      }))
      .sort((a: any, b: any) => b.date.getTime() - a.date.getTime());

    setShowRecording(recorders.length > 0);
    if (recorders.length > 0) {
      const { recorder } = recorders[0];

      setRecordingProps(
        [
          TaxAssessorsDocumentFields.RecordersRecordingDate,
          TaxAssessorsDocumentFields.RecordersDocumentTypeCode,
          TaxAssessorsDocumentFields.RecordersDocumentNumberFormatted,
        ]
          .map((field) => getRecorderProp(recorder, metadata, field))
          .filter((field) => field !== undefined),
      );

      setBuyerSellerProps(
        [
          TaxAssessorsDocumentFields.RecordersGrantee1NameFull,
          TaxAssessorsDocumentFields.RecordersGranteeMailAddressFull,
          TaxAssessorsDocumentFields.RecordersGrantor1NameFull,
        ]
          .map((field) => getRecorderProp(recorder, metadata, field))
          .filter((field) => field !== undefined),
      );

      setMortgageProps(
        [
          TaxAssessorsDocumentFields.RecordersMortgage1Amount,
          TaxAssessorsDocumentFields.RecordersMortgage1LenderNameFullStandardized,
          TaxAssessorsDocumentFields.RecordersMortgage1InterestRate,
          TaxAssessorsDocumentFields.RecordersMortgage1Term,
          TaxAssessorsDocumentFields.RecordersMortgage1TermDate,
        ]
          .map((field) => getRecorderProp(recorder, metadata, field))
          .filter((field) => field !== undefined),
      );
    }
  }, [document, list]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        pt: 3,
      }}
    >
      <Typography variant="body-md-medium">Ownership Information</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {ownershipProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Property Characteristics</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {propertyProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Building Size</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {buildingProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Land/Location Information</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {landProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Tax Assessment Information</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {assessmentProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Last Arm&apos;s Length Sale Information</Typography>

      <Box
        sx={{
          borderBottom: showRecording ? '1px solid' : 'none',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: showRecording ? 2 : 0,
          pb: showRecording ? 3 : 0,
        }}
      >
        {lastArmsLengthSaleProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      {showRecording && (
        <>
          <Typography variant="body-md-medium">Recording Information</Typography>

          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'gray.04',
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              mb: 2,
              pb: 3,
            }}
          >
            {recordingProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>

          <Typography variant="body-md-medium">Buyer/Seller Information</Typography>

          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'gray.04',
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              mb: 2,
              pb: 3,
            }}
          >
            {buyerSellerProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>

          <Typography variant="body-md-medium">Mortgage Information</Typography>

          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
            }}
          >
            {mortgageProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

import { IVersiumAppend, IVersiumAppendType } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetAppendDataByIdQuery, useUpdateAppendDataMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminAppendDataTypeCard } from './components';

export const AdminAppendDataDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAppendDataByIdQuery(id!);
  const [updateAppendData, { isLoading: isUpdateAppendDataLoading }] =
    useUpdateAppendDataMutation();
  const [appendData, setAppendData] = useState<IVersiumAppend | null>(null);
  const [types, setTypes] = useState<IVersiumAppendType[]>([]);
  const [activeType, setActiveType] = useState<null | string>(null);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading && !isUpdateAppendDataLoading));
  }, [isLoading, isUpdateAppendDataLoading]);

  useEffect(() => {
    if (data) {
      setAppendData(data);
    }
  }, [data]);

  useEffect(() => {
    if (appendData) {
      setTypes([...appendData.types].sort((a, b) => a.sortOrder - b.sortOrder));
    }
  }, [appendData]);

  useEffect(() => {
    if (appendData) {
      if (!appendData.name) {
        setIsValid(false);
        return;
      }

      setIsValid(
        appendData.types.every((type) => {
          if (!type.name) return false;

          if (type.subTypes && type.subTypes.length > 0) {
            return type.subTypes.every(
              (subType) => !!subType.name && !!subType.price && subType.price > 0,
            );
          }

          return !!type.price && type.price > 0;
        }),
      );
    }
  }, [appendData]);

  const handleUpdateAppendData = (key: string, value: IVersiumAppendType[] | number | string) => {
    if (!appendData) return;
    setAppendData((prev) => ({ ...prev, [key]: value }) as IVersiumAppend);
  };

  const handleUpdateAppendDataSubmit = () => {
    if (!appendData || !isValid) return;

    updateAppendData(appendData);
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton onClick={() => navigate(AppRoutes.AdminAppendDataPage)}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {appendData?.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              disabled={!isValid}
              onClick={handleUpdateAppendDataSubmit}
              size="small"
              variant="contained"
            >
              Update
            </Button>
          </Box>
        </Box>

        {appendData && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <TextField
                  error={!appendData.name}
                  fullWidth
                  label="Name"
                  onChange={(e) => handleUpdateAppendData('name', e.target.value)}
                  value={appendData.name}
                  variant="outlined"
                />

                {appendData.types.length === 1 &&
                  (!appendData.types[0].subTypes || appendData.types[0].subTypes.length === 0) && (
                    <>
                      <TextField
                        error={!appendData.types[0].price || appendData.types[0].price < 1}
                        fullWidth
                        label="Price"
                        onChange={(e) =>
                          handleUpdateAppendData('types', [
                            { ...appendData.types[0], price: Number(e.target.value) },
                          ])
                        }
                        type="number"
                        value={appendData.types[0].price}
                        variant="outlined"
                      />

                      <TextField
                        error={
                          !appendData.types[0].individualPrice ||
                          appendData.types[0].individualPrice < 1
                        }
                        fullWidth
                        label="Individual Price"
                        onChange={(e) =>
                          handleUpdateAppendData('types', [
                            { ...appendData.types[0], individualPrice: Number(e.target.value) },
                          ])
                        }
                        type="number"
                        value={appendData.types[0].individualPrice}
                        variant="outlined"
                      />
                    </>
                  )}
              </Box>
              <TextField
                fullWidth
                label="Description"
                multiline
                onChange={(e) => handleUpdateAppendData('description', e.target.value)}
                rows={3}
                sx={{
                  flex: 1,
                }}
                value={appendData.description}
                variant="outlined"
              />
            </Box>

            {(appendData.types.length > 1 ||
              (appendData.types.length === 1 &&
                appendData.types[0].subTypes &&
                appendData.types[0].subTypes.length > 0)) && (
              <DragAndDrop
                items={types}
                renderItem={(type, index, p) => (
                  <AdminAppendDataTypeCard
                    activeType={activeType}
                    draggableProps={p.draggableProps}
                    dragHandleProps={p.dragHandleProps}
                    dragRef={p.innerRef}
                    key={type.code}
                    onUpdateType={(updatedType) => {
                      const updatedTypes = appendData.types.map((t) =>
                        t.code === updatedType.code ? updatedType : t,
                      );
                      setAppendData((prev) => ({ ...prev, types: updatedTypes }) as IVersiumAppend);
                    }}
                    setActiveType={setActiveType}
                    type={type}
                  />
                )}
                setItems={(updatedTypes) =>
                  setAppendData((prev) => ({ ...prev, types: updatedTypes }) as IVersiumAppend)
                }
              />
            )}
          </>
        )}
      </Box>
    </AdminPageLayout>
  );
};

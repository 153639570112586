import DataSourceDetailsApiBasicStep from './components/api-components/data-source-details-api-basic-step.components';
import DataSourceDetailsApiMapping from './components/api-components/data-source-details-api-mapping-step.components';
import DataSourceDetailsPricingStep from './components/data-source-details-pricing-step.components';
import {
  DATA_SOURCE_TABS_ENUM,
  DataSourceDetailsProps,
} from './components/utils/data-source-details-props';

const ApiDataSourceDetailsComponent = (props: DataSourceDetailsProps) => {
  const { currentStep, setBasicInfoValid, setMappingValid, setPricingValid } = props;

  return (
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS && (
      <DataSourceDetailsApiBasicStep setBasicInfoValid={setBasicInfoValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.MAPPING && (
      <DataSourceDetailsApiMapping setMappingValid={setMappingValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.PRICING && (
      <DataSourceDetailsPricingStep setIsPricingValid={setPricingValid} />
    )) ||
    null
  );
};

export default ApiDataSourceDetailsComponent;

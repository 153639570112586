import { ColorConfig } from '../config/config-color';

const globalStyles = {
  activeSideTabTextColor: {
    color: ColorConfig.GLUON_GRAY,
    headerBlack: ColorConfig.SOOTY_COLOR,
  },
  listWrapper: {
    '&::before': {
      content: '"• "',
      display: 'inline',
      height: 60,
      paddingLeft: 0,
    },
    fontSize: 14,
  },
  mainColors: {
    aliceBlueColor: ColorConfig.ALICE_BLUE,
    backgroundEditCardColor: ColorConfig.BACKGROUND_EDIT_CARD,
    blackColor: ColorConfig.BLACK,
    blackPanterColor: ColorConfig.BLACK_PANTER,
    bleachedWhiteColor: ColorConfig.BLEACHED_WHITE,
    bloomingAsterColor: ColorConfig.BlOOMING_ASTER,
    blueColor: ColorConfig.BLUE,
    blueGrayColor: ColorConfig.BLUISH_GRAY,
    bowenBlueColor: ColorConfig.BOWEN_BLUE,
    carminePinkColor: ColorConfig.CARMINE_PINK,
    childOfLightColor: ColorConfig.CHILD_OF_LIGHT,
    childOfLightSecondColor: ColorConfig.CHILD_OF_LIGHT_SECOND,
    darkSpellColor: ColorConfig.DARK_SPELL,
    darkSummoningColor: ColorConfig.DARK_SUMMONING,
    distantWindChimeColor: ColorConfig.DISTANT_WIND_CHIME,
    eerieBlackColor: ColorConfig.EERIE_BLACK,
    emeraldReflectionColor: ColorConfig.EMERALD_REFLECTION,
    errigalWhiteColor: ColorConfig.ERRIGAL_WHITE,
    gainsboroColor: ColorConfig.GAINSBORO,
    glazedGraniteColor: ColorConfig.GLAZED_GRANITE,
    glenFallsColor: ColorConfig.GLEN_FALLS,
    grayColor: ColorConfig.GREY,
    greenColor: ColorConfig.GREEN,
    grey74Color: ColorConfig.GRAY_SEVEN_FOUR,
    hawkesBlueColor: ColorConfig.HAWKES_BLUE,
    hawkesBlueSecondColor: ColorConfig.HAWKES_BLUE_SECOND,
    headTableGreyColor: ColorConfig.HEAD_TABLE_GREY,
    houseStarkGrey: ColorConfig.HOUSE_STARK_GREY,
    insigniaWhiteColor: ColorConfig.INSIGNIA_WHITE,
    italianGrapeColor: ColorConfig.ITALIAN_GRAPE,
    lavenderSparkleColor: ColorConfig.LAVENDER_SPARKLE,
    lerryLemonColor: ColorConfig.LEERY_LEMON,
    lightBlue: ColorConfig.LIGHT_BLUE,
    lightBlueGrayColor: ColorConfig.LIGHT_BLUE_GRAY,
    lightFog: ColorConfig.LIGHT_FOG,
    lightGrayColor: ColorConfig.LIGHT_GRAY,
    lightGreenColor: ColorConfig.LIGHT_GREEN,
    lightRed: ColorConfig.LIGHT_RED,
    lightUnaviableRangeGray: ColorConfig.LIGHT_UNAVAILABLE_RANGE_GRAY,
    lusciousPurpleColor: ColorConfig.LUSCIOUS_PURPLE,
    middleGreen: ColorConfig.MIDDLE_GREEN,
    midnightDreamColor: ColorConfig.MIDNIGHT_DREAM,
    moonLilyColor: ColorConfig.MOON_LILY,
    moreThanAWeek: ColorConfig.MORE_THAN_A_WEEK,
    neroColor: ColorConfig.NERO,
    niceBlueColor: ColorConfig.NICE_BLUE,
    paisley: ColorConfig.PAISLEY,
    palladiumColor: ColorConfig.PALLADIUM,
    powderWhiteColor: ColorConfig.POWDER_WHITE_COLOR,
    pulmShadeColor: ColorConfig.PULM_SHADE,
    rareBlueColor: ColorConfig.RARE_BLUE,
    redColor: ColorConfig.RED,
    shadowMountainColor: ColorConfig.SHADOW_MOUNTAIN,
    silverColor: ColorConfig.SILVER,
    siriyGrayColor: ColorConfig.SERIY_GREY,
    sliderRailColor: ColorConfig.SLIDER_RAIL_COLOR,
    solitudeColor: ColorConfig.SOLITUDE,
    sootyColor: ColorConfig.SOOTY_COLOR,
    stainlessStellColor: ColorConfig.STAINLESS_STELL,
    stainlessStellSecondColor: ColorConfig.STAINLESS_STELL_SECOND,
    tranquilPondColor: ColorConfig.TRANQUIL_POND,
    veryLightGrayColor: ColorConfig.VERY_LIGHT_GRAY,
    violetExtractColor: ColorConfig.VIOLET_EXTRACT,
    warningColor: ColorConfig.WARNING,
    whiteColor: ColorConfig.WHITE,
    whiteGrayColor: ColorConfig.WHITE_GRAY,
    whiteSmokeColor: ColorConfig.WHITE_SMOKE,
    yellowColor: ColorConfig.YELLOW,
  },
  navigation: {
    arrowIcon: ColorConfig.PENCIL_POINT,
    blueColor: ColorConfig.BLUE,
    currencyText: ColorConfig.CARRIER_PIGEON,
    redColor: ColorConfig.BRIQUETTE,
    textColor: ColorConfig.UNEXPLAINED,
  },
  rejectButtonsColor: {
    backgroundColor: ColorConfig.TRANQUIL_POND,
  },
  switcher: {
    lightGray: ColorConfig.GRAY_OF_DARKNESS,
    yellow: ColorConfig.DELI_YELLOW,
  },
  unActiveSideTabColor: {
    color: ColorConfig.STAINLESS_STELL_SECOND,
  },
};

export default globalStyles;

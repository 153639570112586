import { TargetType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import { ActionButton, ImageUpload, Switch } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useUpdateAppendDataActiveMutation,
  useUpdateAppendDataImageMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PencilIcon from '@mui/icons-material/Edit';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IAppendDataCardProps {
  data: IVersiumAppend;

  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  // drag and drop
  dragRef: (element: HTMLElement | null) => void;
}

export const AdminAppendDataCard: React.FC<IAppendDataCardProps> = ({
  data,
  draggableProps,
  dragHandleProps,
  dragRef,
}: IAppendDataCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateAppendDataImage, { isLoading: isUpdateAppendDataImageLoading }] =
    useUpdateAppendDataImageMutation();
  const [updateAppendDataActive, { isLoading: isUpdateAppendDataActiveLoading }] =
    useUpdateAppendDataActiveMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isUpdateAppendDataImageLoading && !isUpdateAppendDataActiveLoading));
  }, [isUpdateAppendDataImageLoading || isUpdateAppendDataActiveLoading]);

  return (
    <Box
      ref={dragRef}
      {...draggableProps}
      key={data._id}
      sx={{
        '&:hover': {
          '.description_hint': {
            display: 'block',
          },

          border: `0.5px solid ${globalStyles.mainColors.blueColor}`,
        },
        backgroundColor: globalStyles.mainColors.whiteColor,
        border: `0.5px solid ${globalStyles.mainColors.silverColor}`,
        borderRadius: 2,
        boxShadow:
          'rgb(0 0 0 / 6%) 0px 3px 6px -1px, rgb(0 0 0 / 6%) 0px 0px 0px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        m: 1,

        p: 1,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${data.imageUrl})`,
          backgroundSize: 'cover',
          border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          borderRadius: 2,
          flexShrink: 0,
          height: 210,
          position: 'relative',
          width: 280,
        }}
      >
        <ImageUpload
          height={210}
          key={`${data.code}-${data._id}`}
          name={`${data.code}-${data._id}`}
          sx={{
            bottom: 4,
            position: 'absolute',
            right: 4,
          }}
          updateImage={(url: string) =>
            updateAppendDataImage({
              id: data._id!,
              image: url,
            })
          }
          width={280}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: 800,
              }}
            >
              {data.name}
            </Typography>
            <Chip
              color={data.targetType === TargetType.CUSTOMER ? 'warning' : 'secondary'}
              label={data.targetType === TargetType.CUSTOMER ? 'Residential' : 'Business'}
              size="small"
            />
          </Box>

          <Switch
            checked={data.isActive}
            label={data.isActive ? 'Active' : 'Inactive'}
            onChange={() => {
              updateAppendDataActive({ id: data._id!, isActive: !data.isActive });
            }}
          />
        </Box>
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
          }}
        >
          {data.description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 1,
        }}
      >
        <Button
          {...dragHandleProps}
          startIcon={
            <DragIndicatorIcon
              sx={{
                color: globalStyles.mainColors.grayColor,
                fontSize: 20,
              }}
            />
          }
          sx={{
            '.MuiButton-startIcon': {
              marginRight: 0,
            },
            borderRadius: 2,
            cursor: 'grab',
            height: 30,
            minWidth: 30,
            px: 1,
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: 15,
              mt: 0.25,
            }}
          >
            {data.sortOrder}
          </Typography>
        </Button>
        <ActionButton
          icon={<PencilIcon />}
          onClick={() => {
            navigate(`${AppRoutes.AdminAppendDataPage}/${data._id}`);
          }}
        />
      </Box>
    </Box>
  );
};

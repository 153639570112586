import { Box, Typography } from '@mui/material';

import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';

interface ITaxAssessorsDocumentFieldWithIconProps {
  field: ITaxAssessorsDocumentField;
  showLabel?: boolean;
}

export const TaxAssessorsDocumentFieldWithIcon = ({
  field,
  showLabel = true,
}: ITaxAssessorsDocumentFieldWithIconProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 1,
      }}
    >
      {field.icon}

      <Typography component="span" sx={{ mt: '1px' }} variant="body-sm-medium">
        {showLabel && `${field.label}: `}
        {field.value}
      </Typography>
    </Box>
  );
};

export enum TaxAssessorsDocumentFields {
  Area1stFloor = 'Area1stFloor',
  Area2ndFloor = 'Area2ndFloor',
  AreaBuilding = 'AreaBuilding',
  AreaGross = 'AreaGross',
  AreaLotAcres = 'AreaLotAcres',
  AreaLotSF = 'AreaLotSF',
  AreaLotWidth = 'AreaLotWidth',
  AssessorLastSaleAmount = 'AssessorLastSaleAmount',
  AssessorLastSaleDate = 'AssessorLastSaleDate',
  AssessorPriorSaleAmount = 'AssessorPriorSaleAmount',
  ATTOMID = '[ATTOM ID]',
  BathCount = 'BathCount',
  BathPartialCount = 'BathPartialCount',
  BedroomsCount = 'BedroomsCount',
  BuildingsCount = 'BuildingsCount',
  CensusTract = 'CensusTract',
  CongressionalDistrictHouse = 'CongressionalDistrictHouse',
  Construction = 'Construction',
  ContactAltEmail1 = 'contact_append.AltEmail1',
  ContactAltEmail2 = 'contact_append.AltEmail2',
  ContactAltEmail3 = 'contact_append.AltEmail3',
  ContactAltEmail4 = 'contact_append.AltEmail4',
  ContactAltEmail5 = 'contact_append.AltEmail5',
  ContactAltLaneType5 = 'contact_append.AltLaneType5',
  ContactAltLineType1 = 'contact_append.AltLineType1',
  ContactAltLineType2 = 'contact_append.AltLineType2',
  ContactAltLineType3 = 'contact_append.AltLineType3',
  ContactAltLineType4 = 'contact_append.AltLineType4',
  ContactAltPhone1 = 'contact_append.AltPhone1',
  ContactAltPhone2 = 'contact_append.AltPhone2',
  ContactAltPhone3 = 'contact_append.AltPhone3',
  ContactAltPhone4 = 'contact_append.AltPhone4',
  ContactAltPhone5 = 'contact_append.AltPhone5',
  ContactCity = 'contact_append.City',
  ContactCountry = 'contact_append.Country',
  ContactEmailAddress = 'contact_append.EmailAddress',
  ContactFirstName = 'contact_append.FirstName',
  ContactLastName = 'contact_append.LastName',
  ContactLineType = 'contact_append.LineType',
  ContactMobilePhone = 'contact_append.MobilePhone',
  ContactOwnerMailAddressFull = 'ContactOwnerMailAddressFull',
  ContactPhone = 'contact_append.Phone',
  ContactPostalAddress = 'contact_append.PostalAddress',
  ContactState = 'contact_append.State',
  ContactZip = 'contact_append.Zip',
  ContactZip4 = 'contact_append.Zip+4',
  Demographics2ndMortgageMostRecentInterestRate = 'demographic_append.2ndMortgageMostRecentInterestRate',
  Demographics2ndMortgageMostRecentLender = 'demographic_append.2ndMortgageMostRecentLender',
  Demographics2ndMortgageMostRecentLenderName = 'demographic_append.2ndMortgageMostRecentLenderName',
  Demographics2ndMortgagePurchaseAmount = 'demographic_append.2ndMortgagePurchaseAmount',
  Demographics2ndMortgagePurchaseInterestRateType = 'demographic_append.2ndMortgagePurchaseInterestRateType',
  Demographics2ndMortgagePurchaseLoanType = 'demographic_append.2ndMortgagePurchaseLoanType',
  Demographics2ndMostRecentMortgageAmount = 'demographic_append.2ndMostRecentMortgageAmount',
  Demographics2ndMostRecentMortgageDate = 'demographic_append.2ndMostRecentMortgageDate',
  Demographics2ndMostRecentMortgageInterestRateType = 'demographic_append.2ndMostRecentMortgageInterestRateType',
  Demographics2ndMostRecentMortgageLoanType = 'demographic_append.2ndMostRecentMortgageLoanType',
  DemographicsAddressID = 'demographic_append.AddressID',
  DemographicsAfricanAmericanProfessional = 'demographic_append.AfricanAmericanProfessional',
  DemographicsAgeRange = 'demographic_append.AgeRange',
  DemographicsAntiques = 'demographic_append.Antiques',
  DemographicsApparelChildrenInfantsToddlers = 'demographic_append.ApparelChildrenInfantsToddlers',
  DemographicsApparelChildrenLearningActivityToys = 'demographic_append.ApparelChildrenLearningActivityToys',
  DemographicsApparelChildrens = 'demographic_append.ApparelChildrens',
  DemographicsApparelMensBigandTall = 'demographic_append.ApparelMensBigandTall',
  DemographicsApparelMensYoung = 'demographic_append.ApparelMensYoung',
  DemographicsApparelWomensPetite = 'demographic_append.ApparelWomensPetite',
  DemographicsApparelWomensPlusSize = 'demographic_append.ApparelWomensPlusSize',
  DemographicsApparelWomensYoung = 'demographic_append.ApparelWomensYoung',
  DemographicsArt = 'demographic_append.Art',
  DemographicsArts = 'demographic_append.Arts',
  DemographicsAssimilation = 'demographic_append.Assimilation',
  DemographicsAudioBooks = 'demographic_append.AudioBooks',
  DemographicsAutoMake = 'demographic_append.AutoMake',
  DemographicsAutoModel = 'demographic_append.AutoModel',
  DemographicsAutomotiveBuff = 'demographic_append.AutomotiveBuff',
  DemographicsAutoPartsandAccessories = 'demographic_append.AutoPartsandAccessories',
  DemographicsAutoTrim = 'demographic_append.AutoTrim',
  DemographicsAutoYear = 'demographic_append.AutoYear',
  DemographicsBankCardPresentInHousehold = 'demographic_append.BankCardPresentInHousehold',
  DemographicsBeautyCosmetics = 'demographic_append.BeautyCosmetics',
  DemographicsBookBuyer = 'demographic_append.BookBuyer',
  DemographicsBooks = 'demographic_append.Books',
  DemographicsBooksMagazines = 'demographic_append.BooksMagazines',
  DemographicsBusinessOwner = 'demographic_append.BusinessOwner',
  DemographicsCardHolderAmericanExpressGoldPremium = 'demographic_append.CardHolderAmericanExpressGoldPremium',
  DemographicsCardHolderAmericanExpressRegular = 'demographic_append.CardHolderAmericanExpressRegular',
  DemographicsCardHolderDiscoverGoldPremium = 'demographic_append.CardHolderDiscoverGoldPremium',
  DemographicsCardHolderDiscoverRegular = 'demographic_append.CardHolderDiscoverRegular',
  DemographicsCardHolderGasDeptRetail = 'demographic_append.CardHolderGasDeptRetail',
  DemographicsCardHolderGasolineRetailGoldPremium = 'demographic_append.CardHolderGasolineRetailGoldPremium',
  DemographicsCardHolderMastercardGoldPremium = 'demographic_append.CardHolderMastercardGoldPremium',
  DemographicsCardHolderMastercardRegular = 'demographic_append.CardHolderMastercardRegular',
  DemographicsCardHolderPremium = 'demographic_append.CardHolderPremium',
  DemographicsCardHolderTravelEntertainment = 'demographic_append.CardHolderTravelEntertainment',
  DemographicsCardHolderUnknownType = 'demographic_append.CardHolderUnknownType',
  DemographicsCardHolderVisaGoldPremium = 'demographic_append.CardHolderVisaGoldPremium',
  DemographicsCardHolderVisaRegular = 'demographic_append.CardHolderVisaRegular',
  DemographicsCareer = 'demographic_append.Career',
  DemographicsCareerImprovement = 'demographic_append.CareerImprovement',
  DemographicsCatOwner = 'demographic_append.CatOwner',
  DemographicsCensusBlock = 'demographic_append.CensusBlock',
  DemographicsCensusMedianHomeValue = 'demographic_append.CensusMedianHomeValue',
  DemographicsCensusMedianHouseholdIncome = 'demographic_append.CensusMedianHouseholdIncome',
  DemographicsCensusTract = 'demographic_append.CensusTract',
  DemographicsCharitableDonations = 'demographic_append.CharitableDonations',
  DemographicsChildrenInterests = 'demographic_append.ChildrenInterests',
  DemographicsChildrensProductsGeneral = 'demographic_append.ChildrensProductsGeneral',
  DemographicsChildrensProductsGeneralBabyCare = 'demographic_append.ChildrensProductsGeneralBabyCare',
  DemographicsChildrensProductsGeneralBackToSchool = 'demographic_append.ChildrensProductsGeneralBackToSchool',
  DemographicsChristianFamilies = 'demographic_append.ChristianFamilies',
  DemographicsCollectiblesAntiques = 'demographic_append.CollectiblesAntiques',
  DemographicsCollectiblesAntiquesGrouping = 'demographic_append.CollectiblesAntiquesGrouping',
  DemographicsCollectiblesArts = 'demographic_append.CollectiblesArts',
  DemographicsCollectiblesCoins = 'demographic_append.CollectiblesCoins',
  DemographicsCollectiblesGeneral = 'demographic_append.CollectiblesGeneral',
  DemographicsCollectiblesMilitary = 'demographic_append.CollectiblesMilitary',
  DemographicsCollectiblesSportsMemorabilia = 'demographic_append.CollectiblesSportsMemorabilia',
  DemographicsCollectiblesStamps = 'demographic_append.CollectiblesStamps',
  DemographicsCollectorAvid = 'demographic_append.CollectorAvid',
  DemographicsComputerGames = 'demographic_append.ComputerGames',
  DemographicsComputerOwner = 'demographic_append.ComputerOwner',
  DemographicsComputers = 'demographic_append.Computers',
  DemographicsCongressionalDistrict = 'demographic_append.CongressionalDistrict',
  DemographicsConsumerElectronics = 'demographic_append.ConsumerElectronics',
  DemographicsCooking = 'demographic_append.Cooking',
  DemographicsCookingEnthusiast = 'demographic_append.CookingEnthusiast',
  DemographicsCounty = 'demographic_append.County',
  DemographicsCRAIncomeClassification = 'demographic_append.CRAIncomeClassification',
  DemographicsCreditCardHolderBank = 'demographic_append.CreditCardHolderBank',
  DemographicsCreditCardNewIssue = 'demographic_append.CreditCardNewIssue',
  DemographicsCreditCardUser = 'demographic_append.CreditCardUser',
  DemographicsCreditRating = 'demographic_append.CreditRating',
  DemographicsCRRT = 'demographic_append.CRRT',
  DemographicsCurrentAffairsandPolitics = 'demographic_append.CurrentAffairsandPolitics',
  DemographicsDecoratingandFurnishing = 'demographic_append.DecoratingandFurnishing',
  DemographicsDietingandWeightLoss = 'demographic_append.DietingandWeightLoss',
  DemographicsDOB = 'demographic_append.DOB',
  DemographicsDogOwner = 'demographic_append.DogOwner',
  DemographicsDoItYourselfer = 'demographic_append.DoItYourselfer',
  DemographicsDoItYourselferAutoWork = 'demographic_append.DoItYourselferAutoWork',
  DemographicsDoItYourselferAviation = 'demographic_append.DoItYourselferAviation',
  DemographicsDoItYourselferCrafts = 'demographic_append.DoItYourselferCrafts',
  DemographicsDoItYourselferGardening = 'demographic_append.DoItYourselferGardening',
  DemographicsDoItYourselferHomeandGarden = 'demographic_append.DoItYourselferHomeandGarden',
  DemographicsDoItYourselferHousePlants = 'demographic_append.DoItYourselferHousePlants',
  DemographicsDoItYourselferSewingKnitting = 'demographic_append.DoItYourselferSewingKnitting',
  DemographicsDoItYourselferWoodworking = 'demographic_append.DoItYourselferWoodworking',
  DemographicsDonatesByMail = 'demographic_append.DonatesByMail',
  DemographicsDonatesToEnvironmentalCauses = 'demographic_append.DonatesToEnvironmentalCauses',
  DemographicsDonorAnimalWelfare = 'demographic_append.DonorAnimalWelfare',
  DemographicsDonorArtsandCulture = 'demographic_append.DonorArtsandCulture',
  DemographicsDonorChildrensCauses = 'demographic_append.DonorChildrensCauses',
  DemographicsDonorCommunity = 'demographic_append.DonorCommunity',
  DemographicsDonorConservativePolitics = 'demographic_append.DonorConservativePolitics',
  DemographicsDonorEnvironmental = 'demographic_append.DonorEnvironmental',
  DemographicsDonorEnvironmentalorWildlife = 'demographic_append.DonorEnvironmentalorWildlife',
  DemographicsDonorHealth = 'demographic_append.DonorHealth',
  DemographicsDonorInternationalAid = 'demographic_append.DonorInternationalAid',
  DemographicsDonorLiberalPolitics = 'demographic_append.DonorLiberalPolitics',
  DemographicsDonorPolitical = 'demographic_append.DonorPolitical',
  DemographicsDonorReligious = 'demographic_append.DonorReligious',
  DemographicsDonorUnspecified = 'demographic_append.DonorUnspecified',
  DemographicsDonorVeterans = 'demographic_append.DonorVeterans',
  DemographicsDPC = 'demographic_append.DPC',
  DemographicsDVDsVideos = 'demographic_append.DVDsVideos',
  DemographicsDwellingType = 'demographic_append.DwellingType',
  DemographicsEducationLevel = 'demographic_append.EducationLevel',
  DemographicsElectronicsandComputersGrouping = 'demographic_append.ElectronicsandComputersGrouping',
  DemographicsEquestrian = 'demographic_append.Equestrian',
  DemographicsEstimatedNetWorth = 'demographic_append.EstimatedNetWorth',
  DemographicsEthnicGroup = 'demographic_append.EthnicGroup',
  DemographicsEthnicity = 'demographic_append.Ethnicity',
  DemographicsExercise = 'demographic_append.Exercise',
  DemographicsExerciseAerobic = 'demographic_append.ExerciseAerobic',
  DemographicsExerciseEnthusiast = 'demographic_append.ExerciseEnthusiast',
  DemographicsExerciseRunningorJogging = 'demographic_append.ExerciseRunningorJogging',
  DemographicsExerciseWalking = 'demographic_append.ExerciseWalking',
  DemographicsFemaleMerchandiseBuyer = 'demographic_append.FemaleMerchandiseBuyer',
  DemographicsFemalesAge02inHousehold = 'demographic_append.FemalesAge0-2inHousehold',
  DemographicsFemalesAge35inHousehold = 'demographic_append.FemalesAge3-5inHousehold',
  DemographicsFemalesAge75inHousehold = 'demographic_append.FemalesAge75+inHousehold',
  DemographicsFemalesAge610inHousehold = 'demographic_append.FemalesAge6-10inHousehold',
  DemographicsFemalesAge1115inHousehold = 'demographic_append.FemalesAge11-15inHousehold',
  DemographicsFemalesAge1617inHousehold = 'demographic_append.FemalesAge16-17inHousehold',
  DemographicsFemalesAge1824inHousehold = 'demographic_append.FemalesAge18-24inHousehold',
  DemographicsFemalesAge2534inHousehold = 'demographic_append.FemalesAge25-34inHousehold',
  DemographicsFemalesAge3544inHousehold = 'demographic_append.FemalesAge35-44inHousehold',
  DemographicsFemalesAge4554inHousehold = 'demographic_append.FemalesAge45-54inHousehold',
  DemographicsFemalesAge5564inHousehold = 'demographic_append.FemalesAge55-64inHousehold',
  DemographicsFemalesAge6574inHousehold = 'demographic_append.FemalesAge65-74inHousehold',
  DemographicsFinancialApplicationsAverageLoanRequestedAmount = 'demographic_append.FinancialApplicationsAverageLoanRequestedAmount',
  DemographicsFinancialApplicationsforAutocount = 'demographic_append.FinancialApplicationsforAuto:count',
  DemographicsFinancialApplicationsforAutoInsurancecount = 'demographic_append.FinancialApplicationsforAutoInsurance:count',
  DemographicsFinancialApplicationsforCCcount = 'demographic_append.FinancialApplicationsforCC:count',
  DemographicsFinancialApplicationsforLifeInsurancecount = 'demographic_append.FinancialApplicationsforLifeInsurance:count',
  DemographicsFinancialApplicationsforLoanscount = 'demographic_append.FinancialApplicationsforLoans:count',
  DemographicsFinancialApplicationsforMortgagecount = 'demographic_append.FinancialApplicationsforMortgage:count',
  DemographicsFinancialApplicationsforPaydayLoanscount = 'demographic_append.FinancialApplicationsforPaydayLoans:count',
  DemographicsFinancialApplicationsforTaxReliefServicescount = 'demographic_append.FinancialApplicationsforTaxReliefServices:count',
  DemographicsFinancialApplicationsinPast3Monthscount = 'demographic_append.FinancialApplicationsinPast3Months:count',
  DemographicsFinancialApplicationsinPast6Monthscount = 'demographic_append.FinancialApplicationsinPast6Months:count',
  DemographicsFinancialApplicationsinPast12Monthscount = 'demographic_append.FinancialApplicationsinPast12Months:count',
  DemographicsFinancialApplicationsinPast24Monthscount = 'demographic_append.FinancialApplicationsinPast24Months:count',
  DemographicsFinancialApplicationsOlderthan24Monthscount = 'demographic_append.FinancialApplicationsOlderthan24Months:count',
  DemographicsFIPSCityCode = 'demographic_append.FIPSCityCode',
  DemographicsFIPSStateCountyCode = 'demographic_append.FIPSStateCountyCode',
  DemographicsGaming = 'demographic_append.Gaming',
  DemographicsGardening = 'demographic_append.Gardening',
  DemographicsGardeningFarmingBuyer = 'demographic_append.GardeningFarmingBuyer',
  DemographicsGender = 'demographic_append.Gender',
  DemographicsGenerationsInHousehold = 'demographic_append.GenerationsInHousehold',
  DemographicsGrandchildren = 'demographic_append.Grandchildren',
  DemographicsHealthandBeauty = 'demographic_append.HealthandBeauty',
  DemographicsHealthMedical = 'demographic_append.HealthMedical',
  DemographicsHighEndAppliances = 'demographic_append.HighEndAppliances',
  DemographicsHighTechLeader = 'demographic_append.HighTechLeader',
  DemographicsHomeAirConditioning = 'demographic_append.HomeAirConditioning',
  DemographicsHomeComputingOffice = 'demographic_append.HomeComputingOffice',
  DemographicsHomeComputingOfficeGeneral = 'demographic_append.HomeComputingOfficeGeneral',
  DemographicsHomeHeatingSource = 'demographic_append.HomeHeatingSource',
  DemographicsHomeImprovement = 'demographic_append.HomeImprovement',
  DemographicsHomeMarketValue = 'demographic_append.HomeMarketValue',
  DemographicsHomeOwnorRent = 'demographic_append.HomeOwnorRent',
  DemographicsHomePool = 'demographic_append.HomePool',
  DemographicsHomePurchaseDate = 'demographic_append.HomePurchaseDate',
  DemographicsHomePurchasePrice = 'demographic_append.HomePurchasePrice',
  DemographicsHomeSalesTransaction = 'demographic_append.HomeSalesTransaction',
  DemographicsHomeSewerType = 'demographic_append.HomeSewerType',
  DemographicsHomeValue = 'demographic_append.HomeValue',
  DemographicsHomeWaterSource = 'demographic_append.HomeWaterSource',
  DemographicsHomeYearBuilt = 'demographic_append.HomeYearBuilt',
  DemographicsHouseholdID = 'demographic_append.HouseholdID',
  DemographicsHouseholdIncome = 'demographic_append.HouseholdIncome',
  DemographicsHouseholdSize = 'demographic_append.HouseholdSize',
  DemographicsIndividualID = 'demographic_append.IndividualID',
  DemographicsIndividualLevelMatch = 'demographic_append.IndividualLevelMatch',
  DemographicsInferredHouseholdRank = 'demographic_append.InferredHouseholdRank',
  DemographicsInterestinFitnessOffers = 'demographic_append.InterestinFitnessOffers',
  DemographicsInvesting = 'demographic_append.Investing',
  DemographicsInvestingEstimatedResidentialPropertiesOwned = 'demographic_append.InvestingEstimatedResidentialPropertiesOwned',
  DemographicsInvestingFinanceGroup = 'demographic_append.InvestingFinanceGroup',
  DemographicsInvestingForeign = 'demographic_append.InvestingForeign',
  DemographicsInvestingPersonal = 'demographic_append.InvestingPersonal',
  DemographicsInvestingRealEstate = 'demographic_append.InvestingRealEstate',
  DemographicsInvestingStocksBonds = 'demographic_append.InvestingStocksBonds',
  DemographicsIsCharitable = 'demographic_append.IsCharitable',
  DemographicsIsGeneralContributor = 'demographic_append.IsGeneralContributor',
  DemographicsIsMortgageMostRecentLenderNameAvailable = 'demographic_append.IsMortgageMostRecentLenderNameAvailable',
  DemographicsIsRefinanceLenderNameAvailable = 'demographic_append.IsRefinanceLenderNameAvailable',
  DemographicsJewelry = 'demographic_append.Jewelry',
  DemographicsLanguage = 'demographic_append.Language',
  DemographicsLengthofResidence = 'demographic_append.LengthofResidence',
  DemographicsLifestyleInterestsCollectibles = 'demographic_append.LifestyleInterestsCollectibles',
  DemographicsLifestylesBoardGamesandPuzzles = 'demographic_append.LifestylesBoardGamesandPuzzles',
  DemographicsLifestylesCasinoGaming = 'demographic_append.LifestylesCasinoGaming',
  DemographicsLifestylesCraftsHobbies = 'demographic_append.LifestylesCraftsHobbies',
  DemographicsLifestylesFoodandWine = 'demographic_append.LifestylesFoodandWine',
  DemographicsLifestylesFoodGrouping = 'demographic_append.LifestylesFoodGrouping',
  DemographicsLifestylesHomeImprovement = 'demographic_append.LifestylesHomeImprovement',
  DemographicsLifestylesNaturalFoods = 'demographic_append.LifestylesNaturalFoods',
  DemographicsLifestylesPhotographyVideo = 'demographic_append.LifestylesPhotographyVideo',
  DemographicsLifestylesSweepstakes = 'demographic_append.LifestylesSweepstakes',
  DemographicsLivingBroader = 'demographic_append.LivingBroader',
  DemographicsLivingCommon = 'demographic_append.LivingCommon',
  DemographicsLivingCulturalArtistic = 'demographic_append.LivingCulturalArtistic',
  DemographicsLivingDIY = 'demographic_append.LivingDIY',
  DemographicsLivingHighbrow = 'demographic_append.LivingHighbrow',
  DemographicsLivingHome = 'demographic_append.LivingHome',
  DemographicsLivingProfessional = 'demographic_append.LivingProfessional',
  DemographicsLivingSporty = 'demographic_append.LivingSporty',
  DemographicsLivingUpscale = 'demographic_append.LivingUpscale',
  DemographicsLoantoValue = 'demographic_append.LoantoValue',
  DemographicsLuggage = 'demographic_append.Luggage',
  DemographicsMagazines = 'demographic_append.Magazines',
  DemographicsMailerOrderResponder = 'demographic_append.MailerOrderResponder',
  DemographicsMailOrderBuyer = 'demographic_append.MailOrderBuyer',
  DemographicsMaleMerchandiseBuyer = 'demographic_append.MaleMerchandiseBuyer',
  DemographicsMalesAge02inHousehold = 'demographic_append.MalesAge0-2inHousehold',
  DemographicsMalesAge35inHousehold = 'demographic_append.MalesAge3-5inHousehold',
  DemographicsMalesAge75inHousehold = 'demographic_append.MalesAge75+inHousehold',
  DemographicsMalesAge610inHousehold = 'demographic_append.MalesAge6-10inHousehold',
  DemographicsMalesAge1115inHousehold = 'demographic_append.MalesAge11-15inHousehold',
  DemographicsMalesAge1617inHousehold = 'demographic_append.MalesAge16-17inHousehold',
  DemographicsMalesAge1824inHousehold = 'demographic_append.MalesAge18-24inHousehold',
  DemographicsMalesAge2534inHousehold = 'demographic_append.MalesAge25-34inHousehold',
  DemographicsMalesAge3544inHousehold = 'demographic_append.MalesAge35-44inHousehold',
  DemographicsMalesAge4554inHousehold = 'demographic_append.MalesAge45-54inHousehold',
  DemographicsMalesAge5564inHousehold = 'demographic_append.MalesAge55-64inHousehold',
  DemographicsMalesAge6574inHousehold = 'demographic_append.MalesAge65-74inHousehold',
  DemographicsMaritalStatus = 'demographic_append.MaritalStatus',
  DemographicsMembershipClubs = 'demographic_append.MembershipClubs',
  DemographicsMensApparel = 'demographic_append.MensApparel',
  DemographicsMilitaryHistory = 'demographic_append.MilitaryHistory',
  DemographicsMortgageMostRecentAmountRange = 'demographic_append.MortgageMostRecentAmountRange',
  DemographicsMortgageMostRecentInterestRate = 'demographic_append.MortgageMostRecentInterestRate',
  DemographicsMortgageMostRecentLender = 'demographic_append.MortgageMostRecentLender',
  DemographicsMortgageMostRecentLenderName = 'demographic_append.MortgageMostRecentLenderName',
  DemographicsMortgagePurchaseAmount = 'demographic_append.MortgagePurchaseAmount',
  DemographicsMortgagePurchaseDate = 'demographic_append.MortgagePurchaseDate',
  DemographicsMortgagePurchaseInterestRate = 'demographic_append.MortgagePurchaseInterestRate',
  DemographicsMortgagePurchaseInterestRateType = 'demographic_append.MortgagePurchaseInterestRateType',
  DemographicsMortgagePurchaseLender = 'demographic_append.MortgagePurchaseLender',
  DemographicsMortgagePurchaseLoanType = 'demographic_append.MortgagePurchaseLoanType',
  DemographicsMovieCollector = 'demographic_append.MovieCollector',
  DemographicsMSA = 'demographic_append.MSA',
  DemographicsMusic = 'demographic_append.Music',
  DemographicsMusicalInstruments = 'demographic_append.MusicalInstruments',
  DemographicsMusicAvidListener = 'demographic_append.MusicAvidListener',
  DemographicsMusicCollector = 'demographic_append.MusicCollector',
  DemographicsMusicHomeStereo = 'demographic_append.MusicHomeStereo',
  DemographicsMusicPlayer = 'demographic_append.MusicPlayer',
  DemographicsNewsandFinancial = 'demographic_append.NewsandFinancial',
  DemographicsNumberOfAdults = 'demographic_append.NumberOfAdults',
  DemographicsNumberofChildren = 'demographic_append.NumberofChildren',
  DemographicsNumberofCreditLines = 'demographic_append.NumberofCreditLines',
  DemographicsOccupation = 'demographic_append.Occupation',
  DemographicsOccupationDetail = 'demographic_append.OccupationDetail',
  DemographicsOnlineEducation = 'demographic_append.OnlineEducation',
  DemographicsOnlinePresenceIndicatorApparel = 'demographic_append.OnlinePresenceIndicator-Apparel',
  DemographicsOnlinePresenceIndicatorAuction = 'demographic_append.OnlinePresenceIndicator-Auction',
  DemographicsOnlinePresenceIndicatorAuto = 'demographic_append.OnlinePresenceIndicator-Auto',
  DemographicsOnlinePresenceIndicatorBlogs = 'demographic_append.OnlinePresenceIndicator-Blogs',
  DemographicsOnlinePresenceIndicatorCommerce = 'demographic_append.OnlinePresenceIndicator-Commerce',
  DemographicsOnlinePresenceIndicatorCommunication = 'demographic_append.OnlinePresenceIndicator-Communication',
  DemographicsOnlinePresenceIndicatorEducation = 'demographic_append.OnlinePresenceIndicator-Education',
  DemographicsOnlinePresenceIndicatorEntertainment = 'demographic_append.OnlinePresenceIndicator-Entertainment',
  DemographicsOnlinePresenceIndicatorFileSharing = 'demographic_append.OnlinePresenceIndicator-FileSharing',
  DemographicsOnlinePresenceIndicatorForums = 'demographic_append.OnlinePresenceIndicator-Forums',
  DemographicsOnlinePresenceIndicatorGames = 'demographic_append.OnlinePresenceIndicator-Games',
  DemographicsOnlinePresenceIndicatorRealEstate = 'demographic_append.OnlinePresenceIndicator-RealEstate',
  DemographicsOnlinePresenceIndicatorSocialActivity = 'demographic_append.OnlinePresenceIndicator-SocialActivity',
  DemographicsOnlinePurchasingIndicator = 'demographic_append.OnlinePurchasingIndicator',
  DemographicsOpportunitySeekers = 'demographic_append.OpportunitySeekers',
  DemographicsOtherPetOwner = 'demographic_append.OtherPetOwner',
  DemographicsOutdoorEnthusiast = 'demographic_append.OutdoorEnthusiast',
  DemographicsOutdoors = 'demographic_append.Outdoors',
  DemographicsOutdoorSportsLover = 'demographic_append.OutdoorSportsLover',
  DemographicsParenting = 'demographic_append.Parenting',
  DemographicsPartyAffiliation = 'demographic_append.PartyAffiliation',
  DemographicsPeopleAge02inHousehold = 'demographic_append.PeopleAge0-2inHousehold',
  DemographicsPeopleAge35inHousehold = 'demographic_append.PeopleAge3-5inHousehold',
  DemographicsPeopleAge75inHousehold = 'demographic_append.PeopleAge75+inHousehold',
  DemographicsPeopleAge610inHousehold = 'demographic_append.PeopleAge6-10inHousehold',
  DemographicsPeopleAge1115inHousehold = 'demographic_append.PeopleAge11-15inHousehold',
  DemographicsPeopleAge1617inHousehold = 'demographic_append.PeopleAge16-17inHousehold',
  DemographicsPeopleAge1824inHousehold = 'demographic_append.PeopleAge18-24inHousehold',
  DemographicsPeopleAge2534inHousehold = 'demographic_append.PeopleAge25-34inHousehold',
  DemographicsPeopleAge3544inHousehold = 'demographic_append.PeopleAge35-44inHousehold',
  DemographicsPeopleAge4554inHousehold = 'demographic_append.PeopleAge45-54inHousehold',
  DemographicsPeopleAge5564inHousehold = 'demographic_append.PeopleAge55-64inHousehold',
  DemographicsPeopleAge6574inHousehold = 'demographic_append.PeopleAge65-74inHousehold',
  DemographicsPets = 'demographic_append.Pets',
  DemographicsPhotography = 'demographic_append.Photography',
  DemographicsPresenceofChildren = 'demographic_append.PresenceofChildren',
  DemographicsRangeOfNewCredit = 'demographic_append.RangeOfNewCredit',
  DemographicsReading = 'demographic_append.Reading',
  DemographicsReadingAudioBooks = 'demographic_append.ReadingAudioBooks',
  DemographicsReadingGrouping = 'demographic_append.ReadingGrouping',
  DemographicsReadingMagazines = 'demographic_append.ReadingMagazines',
  DemographicsReadingScienceFiction = 'demographic_append.ReadingScienceFiction',
  DemographicsRefinanceAmount = 'demographic_append.RefinanceAmount',
  DemographicsRefinanceAmountRange = 'demographic_append.RefinanceAmountRange',
  DemographicsRefinanceDate = 'demographic_append.RefinanceDate',
  DemographicsRefinanceLenderName = 'demographic_append.RefinanceLenderName',
  DemographicsRefinanceLoanType = 'demographic_append.RefinanceLoanType',
  DemographicsRefinanceRateType = 'demographic_append.RefinanceRateType',
  DemographicsReligion = 'demographic_append.Religion',
  DemographicsReligiousInspirational = 'demographic_append.ReligiousInspirational',
  DemographicsReligiousMagazines = 'demographic_append.ReligiousMagazines',
  DemographicsResidentialDeliveryIndicator = 'demographic_append.ResidentialDeliveryIndicator',
  DemographicsScienceandSpace = 'demographic_append.ScienceandSpace',
  DemographicsSelfImprovement = 'demographic_append.SelfImprovement',
  DemographicsSeniorinHousehold = 'demographic_append.SeniorinHousehold',
  DemographicsSingleParent = 'demographic_append.SingleParent',
  DemographicsSmallOfficeorHomeOffice = 'demographic_append.SmallOfficeorHomeOffice',
  DemographicsSpecialFoodsBuyer = 'demographic_append.SpecialFoodsBuyer',
  DemographicsSpectatorSportsAutoorMotorcycleRacing = 'demographic_append.SpectatorSportsAutoorMotorcycleRacing',
  DemographicsSpectatorSportsBaseball = 'demographic_append.SpectatorSportsBaseball',
  DemographicsSpectatorSportsBasketball = 'demographic_append.SpectatorSportsBasketball',
  DemographicsSpectatorSportsFootball = 'demographic_append.SpectatorSportsFootball',
  DemographicsSpectatorSportsHockey = 'demographic_append.SpectatorSportsHockey',
  DemographicsSpectatorSportsSoccer = 'demographic_append.SpectatorSportsSoccer',
  DemographicsSpectatorSportsTVSports = 'demographic_append.SpectatorSportsTVSports',
  DemographicsSports = 'demographic_append.Sports',
  DemographicsSportsBoatingSailing = 'demographic_append.SportsBoatingSailing',
  DemographicsSportsCampingorHiking = 'demographic_append.SportsCampingorHiking',
  DemographicsSportsFishing = 'demographic_append.SportsFishing',
  DemographicsSportsGolf = 'demographic_append.SportsGolf',
  DemographicsSportsHunting = 'demographic_append.SportsHunting',
  DemographicsSportsMotorcycling = 'demographic_append.SportsMotorcycling',
  DemographicsSportsNascar = 'demographic_append.SportsNascar',
  DemographicsSportsScubaDiving = 'demographic_append.SportsScubaDiving',
  DemographicsSportsShooting = 'demographic_append.SportsShooting',
  DemographicsSportsSnowSkiing = 'demographic_append.SportsSnowSkiing',
  DemographicsSportsSportsandLeisure = 'demographic_append.SportsSportsandLeisure',
  DemographicsSportsTennis = 'demographic_append.SportsTennis',
  DemographicsTelecommunications = 'demographic_append.Telecommunications',
  DemographicsTheaterPerformingArt = 'demographic_append.TheaterPerformingArt',
  DemographicsTravel = 'demographic_append.Travel',
  DemographicsTravelCruiseVacations = 'demographic_append.TravelCruiseVacations',
  DemographicsTravelDomestic = 'demographic_append.TravelDomestic',
  DemographicsTraveler = 'demographic_append.Traveler',
  DemographicsTravelInternational = 'demographic_append.TravelInternational',
  DemographicsTVCable = 'demographic_append.TVCable',
  DemographicsTVSatDish = 'demographic_append.TVSatDish',
  DemographicsTVVideoMovieWatcher = 'demographic_append.TVVideoMovieWatcher',
  DemographicsUnknownGenderAge02inHousehold = 'demographic_append.UnknownGenderAge0-2inHousehold',
  DemographicsUnknownGenderAge35inHousehold = 'demographic_append.UnknownGenderAge3-5inHousehold',
  DemographicsUnknownGenderAge75inHousehold = 'demographic_append.UnknownGenderAge75+inHousehold',
  DemographicsUnknownGenderAge610inHousehold = 'demographic_append.UnknownGenderAge6-10inHousehold',
  DemographicsUnknownGenderAge1115inHousehold = 'demographic_append.UnknownGenderAge11-15inHousehold',
  DemographicsUnknownGenderAge1617inHousehold = 'demographic_append.UnknownGenderAge16-17inHousehold',
  DemographicsUnknownGenderAge1824inHousehold = 'demographic_append.UnknownGenderAge18-24inHousehold',
  DemographicsUnknownGenderAge2534inHousehold = 'demographic_append.UnknownGenderAge25-34inHousehold',
  DemographicsUnknownGenderAge3544inHousehold = 'demographic_append.UnknownGenderAge35-44inHousehold',
  DemographicsUnknownGenderAge4554inHousehold = 'demographic_append.UnknownGenderAge45-54inHousehold',
  DemographicsUnknownGenderAge5564inHousehold = 'demographic_append.UnknownGenderAge55-64inHousehold',
  DemographicsUnknownGenderAge6574inHousehold = 'demographic_append.UnknownGenderAge65-74inHousehold',
  DemographicsUpscaleCardHolder = 'demographic_append.UpscaleCardHolder',
  DemographicsValueHunter = 'demographic_append.ValueHunter',
  DemographicsVeteranInHousehold = 'demographic_append.VeteranInHousehold',
  DemographicsVideoGames = 'demographic_append.VideoGames',
  DemographicsWomensApparel = 'demographic_append.WomensApparel',
  DemographicsWorkingWomaninHousehold = 'demographic_append.WorkingWomaninHousehold',
  DemographicsYoungAdultinHousehold = 'demographic_append.YoungAdultinHousehold',
  Exterior1Code = 'Exterior1Code',
  HVACCoolingDetail = 'HVACCoolingDetail',
  HVACHeatingDetail = 'HVACHeatingDetail',
  LastOwnershipTransferDate = 'LastOwnershipTransferDate',
  LegalSubdivision = 'LegalSubdivision',
  OwnerTypeDescription1 = 'OwnerTypeDescription1',
  ParcelNumberRaw = 'ParcelNumberRaw',
  PartyOwner1NameFull = 'PartyOwner1NameFull',
  PartyOwner2NameFull = 'PartyOwner2NameFull',
  PartyOwner3NameFull = 'PartyOwner3NameFull',
  PartyOwner4NameFull = 'PartyOwner4NameFull',
  PreviousAssessedValue = 'PreviousAssessedValue',
  PropertyAddressCity = 'PropertyAddressCity',
  PropertyAddressFull = 'PropertyAddressFull',
  PropertyAddressState = 'PropertyAddressState',
  PropertyAddressZIP = 'PropertyAddressZIP',
  PropertyLatitude = 'PropertyLatitude',
  PropertyLongitude = 'PropertyLongitude',
  PropertyUseGroup = 'PropertyUseGroup',
  PropertyUseStandardized = 'PropertyUseStandardized',
  RecordersDocumentNumberFormatted = 'recorders.DocumentNumberFormatted',
  RecordersDocumentTypeCode = 'recorders.DocumentTypeCode',
  RecordersGrantee1NameFull = 'recorders.Grantee1NameFull',
  RecordersGranteeMailAddressFull = 'recorders.GranteeMailAddressFull',
  RecordersGrantor1NameFull = 'recorders.Grantor1NameFull',
  RecordersMortgage1Amount = 'recorders.Mortgage1Amount',
  RecordersMortgage1InterestRate = 'recorders.Mortgage1InterestRate',
  RecordersMortgage1LenderNameFullStandardized = 'recorders.Mortgage1LenderNameFullStandardized',
  RecordersMortgage1Term = 'recorders.Mortgage1Term',
  RecordersMortgage1TermDate = 'recorders.Mortgage1TermDate',
  RecordersRecordingDate = 'recorders.RecordingDate',
  RoofMaterial = 'RoofMaterial',
  RoomsCount = 'RoomsCount',
  SitusCounty = 'SitusCounty',
  StatusOwnerOccupiedFlag = 'StatusOwnerOccupiedFlag',
  StoriesCount = 'StoriesCount',
  TaxAssessedImprovementsPerc = 'TaxAssessedImprovementsPerc',
  TaxAssessedValueImprovements = 'TaxAssessedValueImprovements',
  TaxAssessedValueLand = 'TaxAssessedValueLand',
  TaxAssessedValueTotal = 'TaxAssessedValueTotal',
  TaxBilledAmount = 'TaxBilledAmount',
  TaxFiscalYear = 'TaxFiscalYear',
  TaxMarketImprovementsPerc = 'TaxMarketImprovementsPerc',
  TaxMarketValueImprovements = 'TaxMarketValueImprovements',
  TaxMarketValueLand = 'TaxMarketValueLand',
  TaxMarketValueTotal = 'TaxMarketValueTotal',
  TaxMarketValueYear = 'TaxMarketValueYear',
  TaxRateArea = 'TaxRateArea',
  TaxYearAssessed = 'TaxYearAssessed',
  YearBuilt = 'YearBuilt',
  ZonedCodeLocal = 'ZonedCodeLocal',
}

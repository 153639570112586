import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import {
  changeExtraCreditPopup,
  pageContentLoad,
  snackbarErrorMessage,
  snackbarInfoMessage,
} from '@a-type/ui/stores/actions';
import { useBuyCreditsMutation, useGetActiveSubscriptionQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dialog } from '../dialog/dialog.component';
import { NumberInput } from '../number-input/number-input.component';
import { PaymentMethods } from '../payment-methods/payment-methods.component';

export const BuyMoreCreditsDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state.auth);
  const { isExtraCreditPopupActive } = useSelector((state) => state.appMain);
  const { data: subscription, isLoading } = useGetActiveSubscriptionQuery(undefined, {
    skip: !isLogin,
  });
  const [buyMoreCredits, { isLoading: isBuyMoreCreditsLoading }] = useBuyCreditsMutation();
  const [canBuyMoreCredits, setCanBuyMoreCredits] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<null | string>(null);
  const [creditsToBuy, setCreditsToBuy] = useState(0);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading && !isBuyMoreCreditsLoading));
  }, [isLoading, isBuyMoreCreditsLoading]);

  useEffect(() => {
    if (subscription && subscription.status === 'active') {
      setCreditsToBuy(subscription.creditsPerPackage);
      setCanBuyMoreCredits(true);
    }
  }, [subscription]);

  const handleOnClose = () => {
    dispatch(changeExtraCreditPopup(false));
  };

  const handleOnBuyNow = async () => {
    if (!paymentMethod || !subscription || !creditsToBuy) {
      return;
    }

    const result = await buyMoreCredits({
      credits: creditsToBuy,
      packageId: subscription.packageId,
      paymentMethodId: paymentMethod,
    });

    if (result.data) {
      dispatch(snackbarInfoMessage(`You have successfully bought ${creditsToBuy} credits`));
    } else {
      dispatch(snackbarErrorMessage('An error occurred while buying credits'));
    }

    dispatch(pageContentLoad(true));
    dispatch(changeExtraCreditPopup(false));
  };

  const handleOnSubscribe = () => {
    dispatch(changeExtraCreditPopup(false));
    navigate(AppRoutes.SubscribePage);
  };

  return (
    <Dialog
      cancelText="Cancel"
      disableCancel={isAddingCard}
      disableOk={isAddingCard}
      okText={canBuyMoreCredits ? 'Buy Now' : 'Subscribe'}
      onCancel={handleOnClose}
      onClose={handleOnClose}
      onOk={canBuyMoreCredits ? handleOnBuyNow : handleOnSubscribe}
      open={isExtraCreditPopupActive}
      title="Buy More Credits"
    >
      {!canBuyMoreCredits && (
        <Box>
          You need to have an active subscription to buy more credits. Please subscribe to a plan to
          continue.
        </Box>
      )}

      {canBuyMoreCredits && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: 1,
          }}
        >
          {subscription && (
            <Box
              sx={{
                borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
                display: 'flex',
                gap: 4,
                justifyContent: 'space-between',
                pb: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.navigation.textColor,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Your Plan
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 20,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                  }}
                >
                  {subscription.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.navigation.textColor,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Credits Per Package
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 20,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                  }}
                >
                  {subscription?.creditsPerPackage?.toLocaleString() ?? 0}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.navigation.textColor,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Price / Credit
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 20,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                  }}
                >
                  ${subscription.creditPrice.toFixed(3)}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexBasis: '45%',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 17,
                  fontWeight: 700,
                }}
              >
                Credits I want to buy
              </Typography>

              <NumberInput
                min={subscription?.creditsPerPackage}
                onChange={setCreditsToBuy}
                step={subscription?.creditsPerPackage}
                value={creditsToBuy}
              />

              {subscription && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: globalStyles.mainColors.sootyColor,
                      fontSize: 17,
                      fontWeight: 700,
                    }}
                  >
                    Total Price
                  </Typography>

                  <Typography
                    component="span"
                    sx={{
                      color: globalStyles.mainColors.sootyColor,
                      fontSize: 17,
                      fontWeight: 400,
                    }}
                  >
                    {`$${(creditsToBuy * subscription.creditPrice).toFixed(2)}`}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexBasis: '55%',
                flexDirection: 'column',
              }}
            >
              <PaymentMethods
                onAddPaymentMethod={(isAdding) => setIsAddingCard(isAdding)}
                onSelectPaymentMethod={(pm) => {
                  setPaymentMethod(pm.id);
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

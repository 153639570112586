import { IStripeSubscription } from '@a-type/dtos';
import globalStyles from '@a-type/ui/styles/global.styles';
import CircleCheckIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Divider, Typography } from '@mui/material';

interface SubscriptionCardProps {
  nextPaymentDate?: Date;
  notSelectedText?: string;
  onSelect?: (subscription: IStripeSubscription) => void;
  selected?: boolean;
  selectedText?: string;
  subscription: IStripeSubscription;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  nextPaymentDate,
  notSelectedText = 'Select',
  onSelect,
  selected = false,
  selectedText = 'Selected',
  subscription,
}: SubscriptionCardProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        border: `1px solid ${globalStyles.mainColors.whiteGrayColor}`,
        borderRadius: '8px',
        boxShadow: selected ? '0px 4px 8px rgba(0, 0, 0, 0.15)' : '0px 4px 8px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flexDirection: 'column',
        height: 332,
        padding: '32px 24px',
        position: 'relative',
        width: 264,
      }}
    >
      {subscription.isMostPopular && (
        <Box
          sx={{
            backgroundColor: globalStyles.mainColors.greenColor,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            py: 0.25,
            top: 0,
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.whiteColor,
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            Most popular
          </Typography>
        </Box>
      )}

      <Typography
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 18,
          fontWeight: 700,
          lineHeight: '21px',
          mb: 2.5,
          textTransform: 'uppercase',
        }}
        variant="h5"
      >
        {subscription.name}
      </Typography>
      <Typography
        sx={{
          color: globalStyles.mainColors.greenColor,
          fontSize: 32,
          fontWeight: 700,
          lineHeight: '37.5px',
          mb: 0.5,
        }}
      >
        {subscription.credits.toLocaleString()}
      </Typography>
      <Typography
        sx={{
          color: globalStyles.navigation.textColor,
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '19px',
        }}
      >
        Credits
      </Typography>
      <Divider
        sx={{
          backgroundColor: globalStyles.mainColors.whiteGrayColor,
          my: 2,
          width: '136px',
        }}
      />

      <Typography
        sx={{
          alignItems: 'flex-end',
          color: globalStyles.mainColors.sootyColor,
          display: 'flex',
          fontSize: 20,
          fontWeight: 500,
          gap: 0.5,
          justifyContent: 'center',
          lineHeight: '21px',
          mb: 1.5,
        }}
      >
        ${subscription.price.toFixed(2)}
        <Typography
          component="span"
          sx={{
            alignItems: 'flex-end',
            color: globalStyles.navigation.textColor,
            display: 'flex',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '19px',
          }}
        >
          / month
        </Typography>
      </Typography>

      <Typography
        sx={{
          alignContent: 'flex-end',
          alignItems: 'flex-end',
          color: globalStyles.mainColors.sootyColor,
          display: 'flex',
          fontSize: 16,
          fontWeight: 500,
          gap: 0.5,
          justifyContent: 'center',
          lineHeight: '17px',
          mb: 3,
        }}
      >
        ${subscription.creditPrice.toFixed(3)}
        <Typography
          component="span"
          sx={{
            alignItems: 'flex-end',
            color: globalStyles.navigation.textColor,
            display: 'flex',
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '15.23px',
          }}
        >
          / credit
        </Typography>
      </Typography>

      <Button
        disabled={selected}
        onClick={() => onSelect && onSelect(subscription)}
        size="large"
        sx={{ width: '100%' }}
        variant="contained"
      >
        {selected ? selectedText : notSelectedText}
        {selected && <CircleCheckIcon fontSize="small" sx={{ color: 'blue.05', ml: 1 }} />}
      </Button>

      {nextPaymentDate && (
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '14px',
            mt: 1,
          }}
        >
          Next payment:&nbsp;
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '14px',
            }}
          >
            {new Date(nextPaymentDate).toLocaleDateString()}
          </Typography>
        </Typography>
      )}
    </Box>
  );
};

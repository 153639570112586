import { Components, Theme } from '@mui/material';

import { TOKEN_VALUES } from './fonts/fonts';

const style: Components<Omit<Theme, 'components'>> = {
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, & .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate':
          {
            color: theme.palette.blue['05'],
          },

        '& .MuiDataGrid-cell': {
          alignItems: 'center',
          display: 'flex',
          ...theme.typography['body-sm-medium'],
        },
        '& .MuiDataGrid-cell, & .css-okt5j6-MuiDataGrid-columnHeaders, & .css-1ygg0wd-MuiDataGrid-root .MuiDataGrid-columnSeparator':
          {
            borderColor: theme.palette.gray['00'],
          },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell--withRenderer': {
          '& div': {
            fontSize: TOKEN_VALUES.size[2],
          },
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          height: '52px',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          ...theme.typography['body-xs-medium'],
          color: theme.palette.gray['05'],
        },
        '& .MuiDataGrid-footerContainer': {
          justifyContent: 'right',
        },
        '& .MuiDataGrid-selectedRowCount': {
          display: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          fontSize: TOKEN_VALUES.size[2],
        },
        borderColor: theme.palette.gray['00'],
      }),
    },
  },
};

export default style;

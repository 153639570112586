import { PageLayout } from '@a-type/ui/layout';
import ActivityComponent from '@a-type/ui/pages/dashboard/components/activity/activity.component';

const ActivityPage = () => {
  return (
    <PageLayout container>
      <ActivityComponent />
    </PageLayout>
  );
};

export default ActivityPage;

import { ICreateStripeProduct } from '@a-type/dtos';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetProductQuery, useUpdateProductMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PlanForm } from './components/plan-form/plan-form.component';
import { PlanSubscriptions } from './components/plan-subscriptions/plan-subscriptions.component';

export const PlanPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: product, isLoading } = useGetProductQuery(id!);
  const [updateProduct, { isLoading: isUpdateProductLoading }] = useUpdateProductMutation();
  const [isValid, setIsValid] = useState(true);
  const [formValues, setFormValues] = useState<ICreateStripeProduct>({
    creditsPerPackage: 0,
    description: '',
    isMostPopular: false,
    name: '',
    pricePerCredit: 0,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isUpdateProductLoading && !isLoading));
  }, [isUpdateProductLoading, isLoading]);

  useEffect(() => {
    if (product) {
      setFormValues({
        creditsPerPackage: product.package.credits,
        description: product.description,
        isMostPopular: product.isMostPopular,
        name: product.name,
        pricePerCredit: product.package.price / product.package.credits,
      });
    }
  }, [product]);

  useEffect(() => {
    setIsValid(
      !!formValues.name && formValues.creditsPerPackage > 0 && formValues.pricePerCredit > 0,
    );
  }, [formValues]);

  const handleCreatePlan = async () => {
    if (id && isValid) {
      const data = await updateProduct({
        data: {
          creditsPerPackage: Number(formValues.creditsPerPackage),
          description: formValues.description,
          isMostPopular: formValues.isMostPopular,
          name: formValues.name,
          pricePerCredit: Number(formValues.pricePerCredit),
          sortOrder: product?.sortOrder,
        },
        id,
      });

      if (data.data) navigate(`${AppRoutes.AdminPlansPage}/${data.data._id}`);
    }
  };

  const handleFormChange = (key: keyof ICreateStripeProduct, value: boolean | number | string) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton onClick={() => navigate(AppRoutes.AdminPlansPage)}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {formValues?.name || 'New Plan'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Button disabled={!isValid} onClick={handleCreatePlan} size="small" variant="contained">
              Update
            </Button>
          </Box>
        </Box>

        <PlanForm onChange={handleFormChange} plan={formValues} />

        <PlanSubscriptions
          defaultSubscriptionId={product?.defaultSubscriptionId}
          productId={id!}
          subscriptions={product?.subscriptions || []}
        />
      </Box>
    </AdminPageLayout>
  );
};

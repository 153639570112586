import { IFieldMetadata, IList } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import ColorConfig from '@a-type/ui/config/config-color';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useUploadListMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IUploadListConfirmationDialogProps {
  ignoreFields: { [key: string]: boolean };
  isFirstRowHeader: boolean;
  list: IList | undefined;
  setShow: (show: boolean) => void;
  show: boolean;
  uploadedCsvFile: File | null;
}

export const UploadListConfirmationDialog: FC<IUploadListConfirmationDialogProps> = ({
  ignoreFields,
  isFirstRowHeader,
  list,
  setShow,
  show,
  uploadedCsvFile,
}: IUploadListConfirmationDialogProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadList, { isLoading: isUploadingList, isSuccess: isUploadSuccess }] =
    useUploadListMutation();

  // show loading spinner while fetching list
  useEffect(() => {
    dispatch(pageContentLoad(!isUploadingList));
  }, [isUploadingList]);

  // redirect to lists page after list is uploaded
  useEffect(() => {
    if (isUploadSuccess) {
      dispatch(
        snackbarSuccessMessage(
          'List uploaded successfully. It will be available in a few minutes.',
        ),
      );
      navigate(`${AppRoutes.Lists}?tab=uploaded`);
    }
  }, [isUploadSuccess]);

  // handler for uploading list
  const uploadListHandler = () => {
    if (list && uploadedCsvFile) {
      uploadList({
        file: uploadedCsvFile,
        model: {
          fieldMetadata: Object.entries(ignoreFields).reduce<{
            [key: string]: IFieldMetadata;
          }>(
            (acc, [key, ignore]) => {
              if (!ignore) {
                acc[key] = list.fieldMetadata![key];
              }
              return acc;
            },
            {} as { [key: string]: IFieldMetadata },
          ),
          hasHeader: isFirstRowHeader,
          name: list.name!,
          targetType: list.targetType,
        },
      });
    }
  };

  return (
    <Dialog
      cancelText="No"
      okText="Yes"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={uploadListHandler}
      open={show}
      title="Upload Confirmation"
    >
      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        For your security, we do not use or store any data you upload.
        <br />
        You can read our detailed privacy policies on the{' '}
        <Typography
          component="span"
          onClick={() => navigate('/terms-and-conditions')}
          sx={{
            color: ColorConfig.BLUE,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Terms & Conditions
        </Typography>{' '}
        page.
        <br />
        <br />
        Are you sure you want to continue?
      </Typography>
    </Dialog>
  );
};

import { IDataSourceField, IFilterModel, IFilterStringValue } from '@a-type/interfaces';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { pageContentLoad, setCount, snackbarErrorMessage } from '@a-type/ui/stores/actions';
import { Box, Button, TextField } from '@mui/material';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';

import SelectedValues from './selected-values.component';
import SelectedValuesControl from './selected-values-control.component';

export interface FreeTextSelectFilterProps {
  field: IDataSourceField;
}

const FreeTextSelectFilter = (props: FreeTextSelectFilterProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<IFilterStringValue[]>([]);
  const [text, setText] = useState('');

  // Initialize filter
  useEffect(() => {
    if (!count?.filters) return;

    if (!count.filters[field.name]) {
      const filter = {
        _id: field.name,
        mode: 'add',
        name: field.displayName,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType,
        units: field.units,
        values: [],
      } as IFilterModel;
      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name]: filter } }));
    } else {
      setSelectedOptions(count.filters[field.name].values as IFilterStringValue[]);
    }
  }, [count]);

  // add values
  const addValues = () => {
    if (!count?.filters) return;
    if (!text) return;

    // split text by comma, semicolon, space or new line and remove empty values and duplicates
    const values = text
      .split(/,|;|\s|\n/)
      .map((value) => value.trim())
      .filter((value) => value.length > 0);
    const set = new Set([...selectedOptions.map((option) => option.value), ...values]);

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values: Array.from(set)
              .sort((a, b) => a.localeCompare(b))
              .map((value) => ({ label: value, value })),
          },
        },
      }),
    );
    setText('');
  };

  const uploadFileHandler = (target: any) => {
    if (!count?.filters) return;

    dispatch(pageContentLoad(false));
    const acceptiveFields = ['text/csv'];
    const file: any = target?.files[0];

    const isAcceptiveFormat = acceptiveFields.some((fileType: string) => file?.type === fileType);
    if (!isAcceptiveFormat) {
      dispatch(
        snackbarErrorMessage(`Wrong file format, accept only: ${acceptiveFields.join(' ')}`),
      );
      dispatch(pageContentLoad(true));
    } else {
      Papa.parse(file, {
        complete: (results: any) => {
          // check if file is empty
          const isEmpty = !results?.data.length;
          if (isEmpty) {
            dispatch(snackbarErrorMessage(`Error message: CSV file is empty.`));
            dispatch(pageContentLoad(true));
            return;
          }

          if (!count?.filters) return;

          // check if file has more than 1 column
          const parseDataSourceColumnNames: string[] = results.data[0]?.map((x: string) =>
            x?.trim(),
          );
          if (parseDataSourceColumnNames.length > 1) {
            dispatch(
              snackbarErrorMessage(
                `Sorry, your CSV file has more than 1 column, please select a file with one column`,
              ),
            );
            dispatch(pageContentLoad(true));
            return;
          }

          // check if first row is title
          const regex = /^[a-z ]/gi;
          const isFirstRowIsTitle = results?.data[0][0]?.match(regex);
          if (isFirstRowIsTitle) {
            results?.data.shift();
          }

          const copyOfZipCodes = results?.data
            .slice()
            .filter((item: any) => item[0]?.length)
            .map((item: any) => item[0]);

          const validateZip: any[] = [];
          copyOfZipCodes.forEach((zipCode: string) => {
            // remove all quotes
            const zip = zipCode.replace(/'/g, '').replace(/"/g, '');

            // check if zip code is valid
            const re = /^\d{5}(-\d{4})?$/g;
            const isValidate = zip.match(re);

            if (isValidate) {
              validateZip.push(zip);
            }
          });

          if (!validateZip.length) {
            dispatch(snackbarErrorMessage(`Error message: Invalid CSV data format.`));
            dispatch(pageContentLoad(true));
            return;
          }

          const set = new Set([...selectedOptions.map((option) => option.value), ...validateZip]);
          dispatch(
            setCount({
              ...count,
              filters: {
                ...count.filters,
                [field.name]: {
                  ...count.filters[field.name],
                  values: Array.from(set)
                    .sort((a, b) => a.localeCompare(b))
                    .map((value) => ({ label: value, value })),
                },
              },
            }),
          );
          dispatch(pageContentLoad(true));
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 240,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: 1,
          p: 1.5,
        }}
      >
        <TextField
          multiline
          onChange={(e) => setText(e.target.value)}
          rows={6}
          sx={{ flexGrow: 1 }}
          value={text}
          variant="outlined"
        />

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
          }}
        >
          <Button
            component="label"
            data-cy="build-list-filter-upload-csv-btn"
            size="small"
            sx={{
              width: '128px',
            }}
            variant="contained"
          >
            {ButtonDictionaryNames.UPLOAD_CSV}
            <input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              data-cy="build-list-filter-upload-csv-input"
              hidden
              onChange={(e: any) => uploadFileHandler(e.target)}
              onClick={(e: any) => e.target.value}
              type="file"
            />
          </Button>

          <Button
            data-cy="build-list-filter-add-btn"
            onClick={addValues}
            size="small"
            sx={{
              width: '128px',
            }}
            variant="contained"
          >
            {ButtonDictionaryNames.ADD}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          borderLeft: '1px solid #e0e0e0',
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: 1,
          pt: 1.5,
          px: 1.5,
        }}
      >
        <SelectedValuesControl field={field} />
        <SelectedValues field={field} />
      </Box>
    </Box>
  );
};

export default FreeTextSelectFilter;

import { UserStatus } from '@a-type/enums';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import DashboardComponent from '@a-type/ui/pages/dashboard/components/dashboard.component';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetActiveSubscriptionQuery, useGetUserInfoQuery } from '@a-type/ui/stores/apis';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BlockedUserComponent from './components/blocked-user/blocked-user.compoent';
import NotLoginComponent from './components/not-login/not-login.component';

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<'blocked' | 'dashboard' | 'none' | 'notLogin'>('none');
  const { isLogin } = useSelector((state) => state.auth);
  const { data: userInfo } = useGetUserInfoQuery(undefined, { skip: !isLogin });
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    isSuccess: isSubscriptionLoaded,
  } = useGetActiveSubscriptionQuery(undefined, {
    skip: !isLogin,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isSubscriptionLoading));
  }, [isSubscriptionLoading]);

  useEffect(() => {
    if (isLogin === false) {
      setViewMode('notLogin');
      return;
    }

    if (userInfo?.status === UserStatus.BLOCKED) {
      setViewMode('blocked');
      return;
    }

    if (!isSubscriptionLoaded) {
      setViewMode('none');
      return;
    }

    if (!subscription || subscription.status !== 'active') {
      navigate(AppRoutes.SubscribePage);
      return;
    }

    setViewMode('dashboard');
  }, [isLogin, userInfo, subscription, isSubscriptionLoaded]);

  return (
    <PageLayout>
      {viewMode === 'dashboard' && <DashboardComponent />}

      {viewMode === 'blocked' && userInfo && <BlockedUserComponent fullName={userInfo.fullName} />}

      {viewMode === 'notLogin' && <NotLoginComponent />}
    </PageLayout>
  );
};

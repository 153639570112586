import { IList } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setList } from '@a-type/ui/stores/actions';
import { useGetUserBalanceQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, FormGroup, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import BuyListRecordsSliderComponent from './buy-list-form-records-slider.component';
import BuyListFormWalletComponent from './buy-list-form-wallet.component';

export interface BuyListFormProps {
  pricePerItem: number;
  recordsToBuy: number;
  setRecordsToBuy: (recordsToBuy: number) => void;
}

const BuyListFormComponent = (props: BuyListFormProps) => {
  const { pricePerItem, recordsToBuy, setRecordsToBuy } = props;
  const { list } = useSelector((state) => state.list);
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const [isNameValid, setIsNameValid] = useState(true);
  const dispatch = useDispatch();

  const [maxRecordsToBuy, setMaxRecordsToBuy] = useState<number>(0);

  useEffect(() => {
    if (!list) return;
    if (!creditsBalance) return;
    if (!pricePerItem) return;

    const max = Math.floor(creditsBalance / pricePerItem);
    setMaxRecordsToBuy(Math.min(max, list.avilableRecords!));
  }, [list, creditsBalance, pricePerItem]);

  const updateListFieldHandler = (field: string, value: any) => {
    dispatch(setList({ ...list, [field]: value } as IList));
  };

  return list ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        height: '100%',
        width: '100%',
      }}
    >
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <InputLabel
          htmlFor="list-name"
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontWeight: 'bold',
          }}
        >
          List Name
        </InputLabel>
        <TextField
          error={!isNameValid}
          helperText={
            !isNameValid
              ? 'Name is required and can contain only letters, numbers, spaces, dashes, and underscores "_".'
              : ''
          }
          id="list-name"
          onChange={(e) => {
            updateListFieldHandler('name', e.target.value);
            setIsNameValid(/^[a-zA-Z0-9_-\s]*$/.test(e.target.value));
          }}
          value={list.name}
          variant="outlined"
        />
      </FormGroup>

      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <InputLabel
          htmlFor="records-to-buy"
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontWeight: 'bold',
          }}
        >
          I want to buy
        </InputLabel>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <TextField
            error={recordsToBuy > maxRecordsToBuy || recordsToBuy < 0}
            id="records-to-buy"
            inputProps={{ max: maxRecordsToBuy, min: 0, step: 1 }}
            onChange={(e) => {
              if (e.target.value === '') {
                setRecordsToBuy(0);
                return;
              }
              const value = Number(e.target.value);

              if (value > maxRecordsToBuy) {
                setRecordsToBuy(maxRecordsToBuy);
                return;
              }

              if (value < 0) {
                setRecordsToBuy(0);
                return;
              }

              setRecordsToBuy(value);
            }}
            sx={{ width: '210px' }}
            type="number"
            value={recordsToBuy}
            variant="outlined"
          />
          <InputLabel
            htmlFor="records-to-buy"
            sx={{
              color: globalStyles.mainColors.tranquilPondColor,
              fontSize: '17px',
              fontWeight: 500,
            }}
          >
            Records for {(pricePerItem * recordsToBuy).toLocaleString()} credits
          </InputLabel>
        </Box>
      </FormGroup>

      <BuyListRecordsSliderComponent
        pricePerItem={pricePerItem}
        recordsToBuy={recordsToBuy}
        setRecordsToBuy={setRecordsToBuy}
      />

      <BuyListFormWalletComponent />
    </Box>
  ) : null;
};

export default BuyListFormComponent;

import DataSourceDetailsFileBasicStep from '@a-type/ui/pages/admin/data-source-details/components/file-components/data-source-details-file-basic-step.components';
import DataSourceDetailsFileMapping from '@a-type/ui/pages/admin/data-source-details/components/file-components/data-source-details-file-mapping-step.components';

import DataSourceDetailsPricingStep from './components/data-source-details-pricing-step.components';
import {
  DATA_SOURCE_TABS_ENUM,
  DataSourceDetailsProps,
} from './components/utils/data-source-details-props';

const FileDataSourceDetailsComponent = (props: DataSourceDetailsProps) => {
  const { currentStep, setBasicInfoValid, setMappingValid, setPricingValid } = props;

  return (
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS && (
      <DataSourceDetailsFileBasicStep setBasicInfoValid={setBasicInfoValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.MAPPING && (
      <DataSourceDetailsFileMapping setMappingValid={setMappingValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.PRICING && (
      <DataSourceDetailsPricingStep setIsPricingValid={setPricingValid} />
    )) ||
    null
  );
};

export default FileDataSourceDetailsComponent;

import { AdminPageLayout } from '@a-type/ui/layout';
import DataSourceDetailsComponent from '@a-type/ui/pages/admin/data-source-details/data-source-details.component';

const AdminDataSourceDetailsPage = () => {
  return (
    <AdminPageLayout>
      <DataSourceDetailsComponent />
    </AdminPageLayout>
  );
};

export default AdminDataSourceDetailsPage;

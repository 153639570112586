import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import DataSourceDetailsDeleteDialog from './data-source-details-delete-dialog.component';
import DataSourceDetailsTitleIndexStatus from './data-source-details-title-index-status.component';

const DataSourceDetailsTitleComponent = () => {
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const refresh = async () => {
    if (currentDataSource) {
      dispatch(pageContentLoad(false));
      await dataSourcesService.refreshDatasource(currentDataSource);
      dispatch(pageContentLoad(true));
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {!currentDataSource ? null : (
        <Box sx={{ display: 'flex', marginLeft: 0, top: 2 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 900, pl: 0 }}>
            {!currentDataSource ? '' : currentDataSource.name}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        {!currentDataSource || currentDataSource._id === '0' || currentDataSource.isApi ? null : (
          <DataSourceDetailsTitleIndexStatus />
        )}

        {!currentDataSource || currentDataSource._id === '0' || !currentDataSource.isApi ? null : (
          <Button onClick={refresh} startIcon={<RefreshIcon />}>
            Refresh
          </Button>
        )}

        {!currentDataSource || currentDataSource._id === '0' || currentDataSource.isApi ? null : (
          <Button
            onClick={() => setDeleteDialog(true)}
            startIcon={<DeleteIcon />}
            sx={{ color: globalStyles.mainColors.blackColor }}
          >
            Delete
          </Button>
        )}
      </Box>

      <DataSourceDetailsDeleteDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
      />
    </Box>
  );
};

export default DataSourceDetailsTitleComponent;

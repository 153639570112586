import { IStripePaymentMethod } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useGetActiveSubscriptionQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useState } from 'react';

import AmexCardIcon from '../../../../assets/icons/american-express.svg';
import DefaultCardIcon from '../../../../assets/icons/card-generic.svg';
import DinersCardIcon from '../../../../assets/icons/diners.svg';
import DiscoverCardIcon from '../../../../assets/icons/discover.svg';
import JCBCardIcon from '../../../../assets/icons/jcb.svg';
import MasterCardIcon from '../../../../assets/icons/master-card-icon.svg';
import UnionPayCardIcon from '../../../../assets/icons/union-pay.svg';
import VisaCardIcon from '../../../../assets/icons/visa-card-icon.svg';

interface PaymentMethodProps {
  onRemove: (paymentMethodId: string) => void;
  onSetDefault: (paymentMethodId: string) => void;
  paymentMethod: IStripePaymentMethod;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  onRemove,
  onSetDefault,
  paymentMethod,
}: PaymentMethodProps) => {
  const { data: subscription } = useGetActiveSubscriptionQuery();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<null | string>(null);

  const getBrand = (type: string) => {
    switch (type) {
      case 'amex':
        return <img alt="AmexCard" height="36px" src={AmexCardIcon} width="36px" />;
      case 'diners':
        return <img alt="DinersCard" height="36px" src={DinersCardIcon} width="36px" />;
      case 'discover':
        return <img alt="DiscoverCard" height="36px" src={DiscoverCardIcon} width="36px" />;
      case 'jcb':
        return <img alt="JCBCard" height="36px" src={JCBCardIcon} width="36px" />;
      case 'mastercard':
        return <img alt="MasterCard" height="30px" src={MasterCardIcon} width="36px" />;
      case 'unionpay':
        return <img alt="UnionPayCard" height="36px" src={UnionPayCardIcon} width="36px" />;
      case 'visa':
        return <img alt="VisaCard" height="32px" src={VisaCardIcon} width="36px" />;
      default:
        return <img alt="DefaultCard" height="36px" src={DefaultCardIcon} width="36px" />;
    }
  };

  const isExpired = (expMonth: number, expYear: number) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    return expYear < currentYear || (expYear === currentYear && expMonth < currentMonth);
  };

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
        borderTop: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
        display: 'flex',
        justifyContent: 'space-between',
        px: 2,
        py: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 5,
        }}
      >
        <Box
          sx={{
            border: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
            borderRadius: 1,
            direction: 'column',
            display: 'flex',
            px: 2,
            py: 1.5,
          }}
        >
          {getBrand(paymentMethod.brand)}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            **** **** **** {paymentMethod.last4}
          </Typography>

          <Typography
            component="span"
            sx={{
              color: isExpired(paymentMethod.expMonth, paymentMethod.expYear)
                ? globalStyles.mainColors.redColor
                : globalStyles.navigation.textColor,
              fontSize: 15,
              fontWeight: 400,
            }}
          >
            Expiry: {paymentMethod.expMonth}/{paymentMethod.expYear}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 4,
        }}
      >
        {!paymentMethod.isDefault && (
          <Button color="primary" onClick={() => onSetDefault(paymentMethod.id)} variant="text">
            Set as default
          </Button>
        )}

        {paymentMethod.isDefault && (
          <Chip
            label="Default"
            size="small"
            sx={{
              borderColor: globalStyles.mainColors.greenColor,
              color: globalStyles.mainColors.greenColor,
              fontSize: 13,
              fontWeight: 500,
            }}
            variant="outlined"
          />
        )}

        {(!paymentMethod.isDefault || !subscription || subscription.cancelAtPeriodEnd) && (
          <Button
            color="secondary"
            onClick={() => {
              setSelectedPaymentMethod(paymentMethod.id);
              setOpenDeleteDialog(true);
            }}
            variant="text"
          >
            Delete
          </Button>
        )}
      </Box>

      <Dialog
        cancelText="Cancel"
        okText="Delete"
        onClose={() => setOpenDeleteDialog(false)}
        onOk={() => {
          onRemove(selectedPaymentMethod ?? '');
          setOpenDeleteDialog(false);
        }}
        open={openDeleteDialog}
        title="Delete Payment Method"
      >
        Are you sure you want to delete this payment method?
      </Dialog>
    </Box>
  );
};

import type {} from '@mui/x-data-grid/themeAugmentation';

import { ThemeOptions, TypographyVariantsOptions } from '@mui/material';

import globalStyles from '../global.styles';
import AccordionStyles from './accordion';
import ButtonStyles from './button/button';
import { blue, gray, green, orange, red } from './colors';
import fonts from './fonts/fonts';
import FormStyles from './form';
import GridStyles from './grid';
import MenuStyles from './menu';
import TextFieldStyles from './text-field';
import TooltipStyles from './tooltip';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    breakpoints: {
      values: {
        lg: number;
        md: number;
        sm: number;
        xl: number;
        xs: number;
        xxl: number;
      };
    };
  }
}

const themesBuilder = (): ThemeOptions => ({
  breakpoints: {
    values: {
      lg: 1200, // large laptop
      md: 900, // small laptop
      sm: 600, // tablets
      xl: 1440, // large desktop
      xs: 300, // phone
      xxl: 1920, // extra large desktop
    },
  },
  components: {
    ...ButtonStyles,
    ...AccordionStyles,
    ...GridStyles,
    ...FormStyles,
    ...TooltipStyles,
    ...MenuStyles,
    ...TextFieldStyles,
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: gray['00'],
    },
  },
  palette: {
    background: {
      default: globalStyles.mainColors.lightGrayColor, // background color
      paper: gray['00'], //  card body active
    },
    blue,
    gray,
    green,
    mode: 'light',
    orange,
    primary: {
      contrastText: gray['10'], // text on the white backgrounds
      light: globalStyles.mainColors.blueGrayColor, // light stripes
      main: blue['05'], // back button color
    },
    red,
    secondary: {
      light: globalStyles.mainColors.lightGrayColor, // light stripes
      main: globalStyles.mainColors.tranquilPondColor,
    },
    text: {
      disabled: globalStyles.mainColors.moreThanAWeek,
      primary: gray['10'], // Heading text
      secondary: gray['10'], // Page name text
    },
  },
  typography: { ...fonts } as TypographyVariantsOptions,
});

export default themesBuilder;

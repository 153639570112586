import { IStripePaymentMethod } from '@a-type/dtos';
import { MenuItem, Select, Typography } from '@mui/material';

import AmexCardIcon from '../../assets/icons/american-express.svg';
import DefaultCardIcon from '../../assets/icons/card-generic.svg';
import DinersCardIcon from '../../assets/icons/diners.svg';
import DiscoverCardIcon from '../../assets/icons/discover.svg';
import JCBCardIcon from '../../assets/icons/jcb.svg';
import MasterCardIcon from '../../assets/icons/master-card-icon.svg';
import UnionPayCardIcon from '../../assets/icons/union-pay.svg';
import VisaCardIcon from '../../assets/icons/visa-card-icon.svg';

interface PaymentMethodSelectorProps {
  onSelectPaymentMethod: (paymentMethod: IStripePaymentMethod) => void;
  paymentMethods: IStripePaymentMethod[];
  selectedPaymentMethodId: string;
}

export const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({
  onSelectPaymentMethod,
  paymentMethods,
  selectedPaymentMethodId,
}: PaymentMethodSelectorProps) => {
  const getBrand = (type: string) => {
    switch (type) {
      case 'amex':
        return <img alt="AmexCard" height="32px" src={AmexCardIcon} width="32px" />;
      case 'diners':
        return <img alt="DinersCard" height="32px" src={DinersCardIcon} width="32px" />;
      case 'discover':
        return <img alt="DiscoverCard" height="32px" src={DiscoverCardIcon} width="32px" />;
      case 'jcb':
        return <img alt="JCBCard" height="32px" src={JCBCardIcon} width="32px" />;
      case 'mastercard':
        return <img alt="MasterCard" height="20px" src={MasterCardIcon} width="32px" />;
      case 'unionpay':
        return <img alt="UnionPayCard" height="32px" src={UnionPayCardIcon} width="32px" />;
      case 'visa':
        return <img alt="VisaCard" height="28px" src={VisaCardIcon} width="32px" />;
      default:
        return <img alt="DefaultCard" height="32px" src={DefaultCardIcon} width="32px" />;
    }
  };

  const isExpired = (expMonth: number, expYear: number) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    return expYear < currentYear || (expYear === currentYear && expMonth < currentMonth);
  };

  return (
    <Select
      onChange={(e) => {
        const selectedPaymentMethod = paymentMethods.find((pm) => pm.id === e.target.value);
        if (selectedPaymentMethod) {
          onSelectPaymentMethod(selectedPaymentMethod);
        }
      }}
      sx={{
        '& .MuiSelect-select': {
          alignItems: 'center',
          display: 'flex',
          gap: 1.5,
          py: 1.5,
        },
        display: 'flex',
        minWidth: 250,
      }}
      value={selectedPaymentMethodId}
    >
      {paymentMethods.map((pm) => {
        const expired = isExpired(pm.expMonth, pm.expYear);
        return (
          <MenuItem
            disabled={expired}
            key={pm.id}
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1.5,
              height: 44,
              py: 1,
            }}
            value={pm.id}
          >
            {pm.brand ? getBrand(pm.brand) : null}

            <Typography sx={{ color: 'gray.10', fontSize: '17px' }}>
              **** **** **** <strong>{pm.last4}</strong>
            </Typography>

            {expired && (
              <Typography
                sx={{
                  color: 'red.05',
                  fontSize: '14px',
                }}
              >
                Expired
              </Typography>
            )}
          </MenuItem>
        );
      })}
    </Select>
  );
};

import { ICount } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import CountsComponent from '@a-type/ui/pages/counts/counts.component';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import { dataSourcesService } from '@a-type/ui/services';
import { countsService } from '@a-type/ui/services/counts/counts.service';
import {
  pageContentLoad,
  resetCountState,
  setCount,
  setCurrentDataSource,
  setFiltersComponents,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CountsUpdatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useGetFilterGroupsQuery();

  const getDataSourceById = async (id: string) => {
    const response = await dataSourcesService.getById(id);
    if (response?.status === 200) {
      dispatch(setCurrentDataSource(response.data));
      return response.data;
    }

    dispatch(snackbarErrorMessage('Data source not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  const getCountsById = async (id: string) => {
    const response = await countsService.getById(id);
    if (response?.status === 200) {
      dispatch(
        setCount({
          ...response.data,
          filters: response.data.filters || {},
        } as ICount),
      );
      return response.data;
    }

    dispatch(snackbarErrorMessage('Counts not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  const loadFilterComponents = async () => {
    const response = await dataSourcesService.getFilterComponents();
    if (response?.status === 200) {
      dispatch(setFiltersComponents([...response.data]));
    }
  };

  useEffect(() => {
    dispatch(resetCountState());
    dispatch(pageContentLoad(false));
    const locationArray = location.pathname.split('/');
    const id = locationArray[locationArray.length - 1];

    if (!id) {
      dispatch(snackbarErrorMessage('Counts not found'));
      navigate(AppRoutes.HomePage);
    } else {
      const loadCounts = async () => {
        const counts = await getCountsById(id);
        if (!counts) {
          return;
        }

        // load data source
        const dataSource = await getDataSourceById(counts.dataSourceId!);
        if (!dataSource) {
          return;
        }

        dispatch(pageContentLoad(true));
      };

      loadCounts();
      loadFilterComponents();
    }
  }, []);

  return (
    <PageLayout container wide>
      <CountsComponent />
    </PageLayout>
  );
};

export default CountsUpdatePage;

import { AppendType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { AppendDataType } from '../append-data-type/append-data-type.component';

export interface AppendDataDemographicSelectorProps {
  appendData: IVersiumAppend | null;
  appendTypes: AppendType[];
  isIndividualPrice?: boolean;
  onTypeSelect: (typeId: string, checked: boolean) => void;
  selectedTypes: string[];
  setSelectTypes: (types: string[]) => void;
}

export const AppendDataDemographicSelector = ({
  appendData,
  appendTypes,
  isIndividualPrice = false,
  onTypeSelect,
  selectedTypes,
  setSelectTypes,
}: AppendDataDemographicSelectorProps) => {
  const demographicType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.DEMOGRAPHIC);
  }, [appendData]);

  const financialType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.FINANCIAL);
  }, [appendData]);

  const lifestyleType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.LIFESTYLE);
  }, [appendData]);

  const politicalType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.POLITICAL);
  }, [appendData]);

  const fullDemographicType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.FULL_DEMOGRAPHIC);
  }, [appendData]);

  const isFullDemographicBought = useMemo(() => {
    return appendTypes.includes(AppendType.FULL_DEMOGRAPHIC);
  }, [appendTypes]);

  const isFullDemographicSelected = useMemo(() => {
    if (!fullDemographicType) return false;

    return selectedTypes.includes(fullDemographicType._id);
  }, [fullDemographicType, selectedTypes]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
      }}
    >
      {demographicType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.DEMOGRAPHIC)}
          disabled={isFullDemographicBought || isFullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(demographicType._id)}
          type={demographicType}
        />
      )}

      {financialType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.FINANCIAL)}
          disabled={isFullDemographicBought || isFullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(financialType._id)}
          type={financialType}
        />
      )}

      {lifestyleType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.LIFESTYLE)}
          disabled={isFullDemographicBought || isFullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(lifestyleType._id)}
          type={lifestyleType}
        />
      )}

      {politicalType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.POLITICAL)}
          disabled={isFullDemographicBought || isFullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(politicalType._id)}
          type={politicalType}
        />
      )}

      {fullDemographicType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.FULL_DEMOGRAPHIC)}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) =>
            checked === true ? setSelectTypes([typeId]) : onTypeSelect(typeId, false)
          }
          selected={selectedTypes.includes(fullDemographicType._id)}
          type={fullDemographicType}
        />
      )}
    </Box>
  );
};

import { IView } from '@a-type/interfaces';
import { Dialog, StepNavigation, StepNavigationItem } from '@a-type/ui/components';
import { useDispatch, useQuery } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/app-routes';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useDeleteViewMutation, useGetViewQuery } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { ChevronLeftOutlined, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ViewBasic } from './view-basic.component';
import { ViewFilters } from './view-filters.component';
import { ViewPricing } from './view-pricing.component';
import { ViewSteps } from './view-steps.enum';

export const ViewPage = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: viewData, isLoading: isViewLoading } = useGetViewQuery(id!, {
    skip: !id || id === 'create',
  });
  const [deleteView, { isLoading: isDeletingView }] = useDeleteViewMutation();
  const [view, setView] = useState<IView | null>(null);
  const [activeStep, setActiveStep] = useState<string>(query.get('step') ?? ViewSteps.Base);
  const [steps, setSteps] = useState<StepNavigationItem[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [updateConfirmation, setUpdateConfirmation] = useState<{
    nextStep: string;
    show: boolean;
  }>({ nextStep: '', show: false });
  const viewBasicRef = useRef<any>(null);
  const theme = useTheme();

  useEffect(() => {
    dispatch(pageContentLoad(!isViewLoading));
  }, [isViewLoading]);

  useEffect(() => {
    if (viewData) {
      setView(viewData);
    }
  }, [viewData]);

  useEffect(() => {
    if (id === 'create') {
      setView(null);
    }

    setSteps([
      { enabled: true, id: ViewSteps.Base, label: 'Basic Information' },
      { enabled: id !== 'create', id: ViewSteps.Filters, label: 'Filters' },
      { enabled: id !== 'create', id: ViewSteps.Pricing, label: 'Pricing' },
    ]);
  }, [id]);

  const handleDelete = async () => {
    if (!view) return;

    const result = await deleteView(view._id);

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Failed to delete view.'));
    } else {
      dispatch(snackbarSuccessMessage('View deleted successfully.'));
      navigate(AppRoutes.AdminViewsPage);
    }
  };

  const handleStepChange = (newStep: string) => {
    if (activeStep === ViewSteps.Base && viewBasicRef.current) {
      const hasUnsavedChanges = viewBasicRef.current.hasUnsavedChanges();
      if (hasUnsavedChanges) {
        setUpdateConfirmation({ nextStep: newStep, show: true });
        return;
      }
    }
    setActiveStep(newStep);
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 3,
          py: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.gray['03']}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IconButton
              onClick={() => {
                navigate(`${AppRoutes.AdminViewsPage}`);
              }}
            >
              <ChevronLeftOutlined />
            </IconButton>
            <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>
              {id === 'create' ? 'Create View' : view?.name}
            </Typography>
          </Box>

          {view && (
            <Button
              color="error"
              disabled={isDeletingView}
              onClick={() => setShowDeleteDialog(true)}
              startIcon={<Delete />}
              variant="text"
            >
              Delete
            </Button>
          )}
        </Box>

        <StepNavigation
          activeStep={activeStep}
          defaultStep={ViewSteps.Base}
          items={steps}
          setActiveStep={handleStepChange}
        />

        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {activeStep === ViewSteps.Base && (
            <ViewBasic
              ref={viewBasicRef}
              setActiveStep={handleStepChange}
              setUpdateConfirmation={setUpdateConfirmation}
              view={view}
            />
          )}

          {view && activeStep === ViewSteps.Filters && (
            <ViewFilters setActiveStep={handleStepChange} view={view} />
          )}

          {view && activeStep === ViewSteps.Pricing && (
            <ViewPricing setActiveStep={handleStepChange} view={view} />
          )}
        </Box>
      </Box>

      {updateConfirmation.show && (
        <Dialog
          cancelText="Don't update"
          fullScreen={false}
          okText="Update"
          onCancel={() => {
            setActiveStep(updateConfirmation.nextStep);
            setUpdateConfirmation({ nextStep: '', show: false });
          }}
          onClose={() => setUpdateConfirmation({ nextStep: '', show: false })}
          onOk={async () => {
            const success = await viewBasicRef.current?.handleUpdate();
            if (success) {
              setActiveStep(updateConfirmation.nextStep);
            }
            setUpdateConfirmation({ nextStep: '', show: false });
          }}
          open={updateConfirmation.show}
          title="Continue without updating?"
        >
          Your changes will be lost. Please, click the &quot;Update&quot; button to save your
          changes first.
        </Dialog>
      )}

      {showDeleteDialog && (
        <Dialog
          cancelText="Cancel"
          okText="Yes, Delete"
          onCancel={() => setShowDeleteDialog(false)}
          onClose={() => setShowDeleteDialog(false)}
          onOk={handleDelete}
          open={showDeleteDialog}
          size="sm"
          title="Delete View"
        >
          Are you sure you want to delete this view?
        </Dialog>
      )}
    </AdminPageLayout>
  );
};

import { FieldsHelper } from '@a-type/helpers';
import { IDocument, IFieldMetadata, IList } from '@a-type/interfaces';
import { Bathtub, Bed, Home, Yard } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { AppendDataStatus } from '../../enums/append-data-status.enum';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { TaxAssessorsTabs } from '../../enums/tax-assessors-document-tabs.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { TaxAssessorsDocumentContact } from './tax-assessors-document-contact.component';
import { TaxAssessorsDocumentDemographic } from './tax-assessors-document-demographic.component';
import { TaxAssessorsDocumentField } from './tax-assessors-document-field.component';
import { TaxAssessorsDocumentFieldWithIcon } from './tax-assessors-document-field-with-icon.component';
import { TaxAssessorsDocumentStreetView } from './tax-assessors-document-street-view.component';
import { TaxAssessorsDocumentValue } from './tax-assessors-document-value.component';

interface ITaxAssessorsDocumentInfoProps {
  contactAppendStatus: AppendDataStatus;
  demographicAppendStatus: AppendDataStatus;
  document: IDocument;
  list: IList;
  setAppendType: (type: 'contact' | 'demographic') => void;
  setShowAppendData: (show: boolean) => void;
  setTab: (tab: TaxAssessorsTabs) => void;
}

const TaxAssessorsInfoFields = [
  TaxAssessorsDocumentFields.YearBuilt,
  TaxAssessorsDocumentFields.ParcelNumberRaw,
  TaxAssessorsDocumentFields.PropertyUseGroup,
  TaxAssessorsDocumentFields.OwnerTypeDescription1,
  TaxAssessorsDocumentFields.StatusOwnerOccupiedFlag,
  TaxAssessorsDocumentFields.LastOwnershipTransferDate,
  TaxAssessorsDocumentFields.SitusCounty,
];

export const TaxAssessorsDocumentInfo: FC<ITaxAssessorsDocumentInfoProps> = ({
  contactAppendStatus,
  demographicAppendStatus,
  document,
  list,
  setAppendType,
  setShowAppendData,
  setTab,
}: ITaxAssessorsDocumentInfoProps) => {
  const [highlighted, setHighlighted] = useState<ITaxAssessorsDocumentField[]>([]);
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);

  const getPropertyInfo = (
    field: TaxAssessorsDocumentFields,
    metadata: {
      [key: string]: IFieldMetadata;
    },
    icon?: JSX.Element,
  ) => {
    if (!metadata[field]) return undefined;

    const value = FieldsHelper.accessValue(field, document.data);
    return {
      icon,
      key: field,
      label: metadata[field]?.displayName ?? field,
      value: value ? FieldsHelper.getValue(value, metadata[field], false, true) : '-',
    };
  };

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setHighlighted(
      [
        getPropertyInfo(
          TaxAssessorsDocumentFields.BedroomsCount,
          metadata,
          <Bed
            sx={{
              color: 'blue.06',
            }}
          />,
        ),
        getPropertyInfo(
          TaxAssessorsDocumentFields.AreaGross,
          metadata,
          <Home
            sx={{
              color: 'blue.06',
            }}
          />,
        ),
        getPropertyInfo(
          TaxAssessorsDocumentFields.BathCount,
          metadata,
          <Bathtub
            sx={{
              color: 'blue.06',
            }}
          />,
        ),
        getPropertyInfo(
          TaxAssessorsDocumentFields.AreaLotAcres,
          metadata,
          <Yard
            sx={{
              color: 'blue.06',
            }}
          />,
        ),
      ].filter((field) => field !== undefined) as ITaxAssessorsDocumentField[],
    );

    setProperties(
      TaxAssessorsInfoFields.map((field) => getPropertyInfo(field, metadata)).filter(
        (field) => field !== undefined,
      ),
    );
  }, [document, list]);

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        columnGap: 2,
        display: 'flex',
        flexWrap: {
          lg: 'wrap',
          md: 'wrap',
          sm: 'wrap',
          xl: 'wrap',
          xs: 'wrap',
          xxl: 'nowrap',
        },
        rowGap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {document && <TaxAssessorsDocumentStreetView documentId={document._id} />}

        <Box
          sx={{
            border: '1px solid',
            borderColor: 'gray.04',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
            }}
          >
            {highlighted.map((field) => (
              <TaxAssessorsDocumentFieldWithIcon field={field} key={field.key} />
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          border: '1px solid',
          borderColor: 'gray.04',
          borderRadius: '16px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: 2,
          minWidth: 300,
          p: 2,
        }}
      >
        {properties.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <TaxAssessorsDocumentValue document={document} list={list} />

      <TaxAssessorsDocumentContact
        appendStatus={contactAppendStatus}
        document={document}
        list={list}
        setTab={setTab}
        showAppend={() => {
          setAppendType('contact');
          setShowAppendData(true);
          setTab(TaxAssessorsTabs.CONTACTS);
        }}
      />

      <TaxAssessorsDocumentDemographic
        appendStatus={demographicAppendStatus}
        document={document}
        list={list}
        setTab={setTab}
        showAppend={() => {
          setAppendType('demographic');
          setShowAppendData(true);
          setTab(TaxAssessorsTabs.DEMOGRAPHICS);
        }}
      />
    </Box>
  );
};

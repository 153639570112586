import { AdminPageLayout } from '@a-type/ui/layout';

import DataSourcesListComponent from './data-sources-list.component';

const AdminDataSourcesPageComponent = () => {
  return (
    <AdminPageLayout container>
      <DataSourcesListComponent />
    </AdminPageLayout>
  );
};

export default AdminDataSourcesPageComponent;

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';

import { AddPaymentMethodDialog } from './add-payment-method-dialog.component';

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = stripeApiKey ? loadStripe(stripeApiKey) : null;

interface AddPaymentMethodProps {
  variant?: 'contained' | 'text';
}

export const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({
  variant = 'text',
}: AddPaymentMethodProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <>
            <Button
              color="primary"
              onClick={() => setOpen(true)}
              startIcon={variant === 'text' ? <AddIcon /> : undefined}
              variant={variant}
            >
              Add Payment Method
            </Button>

            {open && (
              <AddPaymentMethodDialog
                elements={elements}
                onClose={() => setOpen(false)}
                open={open}
                stripe={stripe}
              />
            )}
          </>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

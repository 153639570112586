import { AccountSidebar } from '@a-type/ui/layout';
import { Box } from '@mui/material';

interface AccountPageLayoutProps {
  children: React.ReactNode;
  container?: boolean;
}

export const AccountPageLayout: React.FC<AccountPageLayoutProps> = ({
  children,
  container = false,
}: AccountPageLayoutProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
      }}
    >
      <AccountSidebar />

      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
          overflow: 'auto',
          p: 4,
          width: '100%',
        }}
      >
        {container ? (
          <Box
            sx={{
              background: 'white',
              borderRadius: '24px',
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              height: 'auto',
              minHeight: '100%',
              p: 3,
              width: '100%',
            }}
          >
            {children}
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default AccountPageLayout;
